<template>
	<b-modal
		size="xmd"
		ref="modal-specimen-parents"
		id="modal-specimen-parents"
		:title="`Registrar ascendencia de ${specimen.alias || 'Sin alias'} / (${specimen.plate || 'Sin placa'})`"
		hide-footer
		@hidden="$emit('hidden')"
	>
		<b-tabs v-model="tabIndex" nav-class="mrp-tabs mb-0" content-class="mrp-content mb-1">
			<b-tab :disabled="Boolean(specimen.padrillo_id)">
				<template #title>
					<svg width="26" height="26" fill="none" viewBox="0 0 31 31">
						<path
							fill="#858585"
							d="m22.997 11.145-.134.142.106.164a12.61 12.61 0 0 1 1.834 8.744 12.534 12.534 0 0 1-4.409 7.747 12.29 12.29 0 0 1-8.398 2.794 12.313 12.313 0 0 1-8.1-3.583l-.002-.002C-.315 23.046-.936 16.17 2.336 11.235 5.232 6.872 12.491 3.263 19.88 8.093l.173.112.143-.147 3.446-3.546.412-.424h-.591c-.277 0-.543.001-.803.003-.502.003-.981.005-1.462-.003-.584-.01-1.05-.198-1.372-.499-.32-.3-.515-.73-.528-1.262-.014-.535.155-1.002.455-1.338.298-.335.738-.554 1.295-.573C23.613.322 26.176.266 28.74.25c.594-.005 1.083.196 1.424.532.342.335.552.822.56 1.42.038 2.591.034 5.182 0 7.773-.012 1.146-.875 1.985-1.86 1.978-.986-.008-1.754-.763-1.829-1.905-.036-.56-.027-1.114-.018-1.697.004-.27.009-.548.009-.835v-.628l-.432.456-3.596 3.8Zm-9.874-1.54-.174-.006c-4.382-.325-8.472 3.161-8.93 7.623-.264 2.571.43 4.887 1.868 6.61 1.437 1.723 3.604 2.835 6.254 3.023 4.637.33 8.501-2.923 8.99-7.732.538-5.364-3.652-9.386-8.008-9.519Z"
						/>
					</svg>
					<span>Padrillo </span>
				</template>
				<validation-observer ref="formPadrillo">
					<b-row cols="12" class="py-2 px-1 gap-y-3" tag="section">
						<b-col cols="12" class="col-lg-4">
							<SelectableImage v-model="padrillo.avatar" :disabled="foundSpecimen" />
						</b-col>
						<b-col cols="12" class="col-lg-8">
							<b-row cols="12">
								<b-col md="6">
									<b-form-group
										label="Buscar padrillo:"
										label-for="search-ejemplar"
										class="txs-selectable"
									>
										<SelectSpecimens
											:selectedSpecimens="setOfPadrilloOmit"
											@selected="selected($event, 'padrillo')"
											categories="1"
											:flush-searched="clearPadrilloSearch"
										/>
									</b-form-group>
									<b-form-group label="Alias del padre:" label-for="tsa-parent-alias">
										<ValidationProvider
											:rules="padrillo.inside == 'NO' ? 'required' : ''"
											v-slot="{ errors }"
											class="w-100"
										>
											<b-form-input
												:disabled="foundSpecimen"
												id="tsa-parent-alias"
												v-model="padrillo.alias"
												type="text"
												placeholder="Ingresa el alias del padre"
												:invalid-feedback="errors[0]"
												:state="errors[0] ? false : null"
											/>
										</ValidationProvider>
									</b-form-group>
									<b-form-group label="Placa del padre:" label-for="tsa-parent-plate">
										<ValidationProvider
											:rules="padrillo.inside == 'SI' ? 'required' : ''"
											v-slot="{ errors }"
											class="w-100"
										>
											<b-form-input
												:disabled="foundSpecimen"
												id="tsa-parent-plate"
												v-model="padrillo.plate"
												type="text"
												placeholder="Ingresa la placa del padre"
												:invalid-feedback="errors[0]"
												:state="errors[0] ? false : null"
											/>
										</ValidationProvider>
									</b-form-group>
									<div class="form-group">
										<label for="datepicker-placeholder"> Fecha de nacimiento </label>
										<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
											<b-form-datepicker
												:disabled="Boolean(foundSpecimen && padrillo.dob)"
												id="datepicker-placeholder"
												placeholder="Fecha de nacimiento:"
												locale="es"
												v-model="padrillo.dob"
												:max="specimen.date"
												:invalid-feedback="errors[0]"
												:state="errors[0] ? false : null"
											/>
										</ValidationProvider>
									</div>
								</b-col>
								<b-col md="6">
									<fieldset :disabled="foundSpecimen" class="form-group">
										<legend class="d-block" style="margin-bottom: 0.2857rem !important">
											Pertenece al galpon:
										</legend>
										<div class="ts-r-button__flex">
											<label class="ts-r-button">
												<input
													name="mp-inside"
													type="radio"
													value="SI"
													v-model="padrillo.inside"
												/>
												SI
											</label>
											<label class="ts-r-button">
												<input
													name="mp-inside"
													type="radio"
													value="NO"
													v-model="padrillo.inside"
												/>
												NO
											</label>
										</div>
									</fieldset>
									<div class="form-group tx-textarea">
										<label for="tsa-mom-obs d-block">Observación:</label>
										<b-form-textarea
											id="tsa-mom-obs"
											v-model="padrillo.observation"
											placeholder="Escribe algo..."
											class="ts-textarea"
											rows="6"
										/>
									</div>
								</b-col>
								<b-col cols="12" class="d-flex gap-x-2">
									<b-button @click="saveParent('padrillo')" variant="primary" type="submit">
										Guardar
									</b-button>
									<b-button @click="resetForm('padrillo')" variant="outline-primary" type="button">
										Resetear
									</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</validation-observer>
			</b-tab>
			<b-tab :disabled="Boolean(specimen.madrilla_id)">
				<template #title>
					<svg width="18" height="30" fill="none" viewBox="0 0 23 35">
						<path
							fill="#858585"
							d="m9.335 27.134.254.004v-3.99l-.19-.046c-1.867-.455-3.621-1.208-5.07-2.525C.793 17.371-.535 13.368.701 8.732 1.922 4.148 4.944 1.267 9.556.436c6.168-1.114 11.869 2.886 12.998 9.176 1.115 6.193-2.62 11.952-8.608 13.367-.316.074-.58.18-.748.393-.17.215-.202.488-.193.787v.001c.02.56.013 1.116.007 1.72-.004.322-.007.657-.007 1.014v.25h.25c.374 0 .74-.002 1.102-.004.71-.003 1.4-.006 2.09.004h.003c.31.001.614.097.873.275.258.179.46.432.58.73l.006.011c.303.657.223 1.307-.25 1.756-.37.334-.83.545-1.318.605-.672.07-1.343.057-2.08.042a49.86 49.86 0 0 0-1.018-.013h-.25v.25c0 .25.002.487.003.716.003.435.006.84-.003 1.247-.017.644-.2 1.14-.486 1.47-.284.327-.686.514-1.194.517-.53 0-.942-.19-1.226-.524-.289-.34-.468-.852-.475-1.532v-2.169h-.25c-.36 0-.713.004-1.06.008-.678.008-1.338.015-2.01-.008-.488-.021-.87-.202-1.133-.482-.263-.282-.42-.681-.425-1.17-.005-.506.155-.923.426-1.218.271-.295.669-.487 1.173-.517.5-.027.995-.023 1.499-.018.241.002.484.004.731.003l.772.011Zm2.179-7.317h.001a7.813 7.813 0 0 0 5.555-2.371 8.214 8.214 0 0 0 2.32-5.686l-.25-.001h.25a8.217 8.217 0 0 0-1.318-4.484 7.918 7.918 0 0 0-3.529-2.979 7.691 7.691 0 0 0-4.55-.466 7.813 7.813 0 0 0-4.035 2.207A8.135 8.135 0 0 0 3.8 10.166a8.26 8.26 0 0 0 .443 4.66 8.03 8.03 0 0 0 2.895 3.626 7.734 7.734 0 0 0 4.375 1.365Z"
						/>
					</svg>
					<span>Madrilla</span>
				</template>
				<validation-observer ref="formMadrilla">
					<b-row cols="12" class="py-2 px-1 gap-y-3" tag="section">
						<b-col cols="12" class="col-lg-4">
							<SelectableImage v-model="madrilla.avatar" :disabled="foundSpecimen" />
						</b-col>
						<b-col cols="12" class="col-lg-8">
							<b-row cols="12">
								<b-col md="6">
									<b-form-group
										label="Buscar madrilla:"
										label-for="search-ejemplar"
										class="txs-selectable"
									>
										<SelectSpecimens
											:selectedSpecimens="setOfPadrilloOmit"
											@selected="selected($event, 'madrilla')"
											categories="2"
											:flush-searched="clearMadrillaSearch"
										/>
									</b-form-group>
									<b-form-group label="Alias de la madre:" label-for="tsa-mom-alias">
										<ValidationProvider
											:rules="madrilla.inside == 'NO' ? 'required' : ''"
											v-slot="{ errors }"
											class="w-100"
										>
											<b-form-input
												:disabled="foundSpecimen"
												id="tsa-mom-alias"
												v-model="madrilla.alias"
												type="text"
												placeholder="Ingresa el alias de la madre"
												:invalid-feedback="errors[0]"
												:state="errors[0] ? false : null"
											/>
										</ValidationProvider>
									</b-form-group>
									<b-form-group label="Placa de la madre:" label-for="tsa-mom-plate">
										<ValidationProvider
											:rules="madrilla.inside == 'SI' ? 'required' : ''"
											v-slot="{ errors }"
											class="w-100"
										>
											<b-form-input
												:disabled="foundSpecimen"
												id="tsa-mom-plate"
												v-model="madrilla.plate"
												type="text"
												placeholder="Ingresa la placa de la madre"
												:invalid-feedback="errors[0]"
												:state="errors[0] ? false : null"
											/>
										</ValidationProvider>
									</b-form-group>
									<div class="form-group">
										<label for="datepicker-placeholder"> Fecha de nacimiento </label>

										<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
											<b-form-datepicker
												:disabled="Boolean(foundSpecimen && madrilla.dob)"
												id="datepicker-placeholder"
												placeholder="Fecha de nacimiento:"
												locale="es"
												v-model="madrilla.dob"
												:max="specimen.date"
												:invalid-feedback="errors[0]"
												:state="errors[0] ? false : null"
											/>
										</ValidationProvider>
									</div>
								</b-col>
								<b-col md="6">
									<fieldset class="form-group">
										<legend>Pertenece al galpon:</legend>
										<div class="ts-r-button__flex">
											<label class="ts-r-button">
												<input
													name="md-inside"
													type="radio"
													value="SI"
													v-model="madrilla.inside"
													:disabled="foundSpecimen"
												/>
												SI
											</label>
											<label class="ts-r-button">
												<input
													name="md-inside"
													type="radio"
													value="NO"
													v-model="madrilla.inside"
													:disabled="foundSpecimen"
												/>
												NO
											</label>
										</div>
									</fieldset>
									<div class="form-group tx-textarea">
										<label for="tsa-mom-obs">Observación:</label>
										<b-form-textarea
											id="tsa-mom-obs"
											v-model="madrilla.observation"
											placeholder="Escribe algo..."
											rows="3"
											max-rows="2"
											class="ts-textarea"
										/>
									</div>
								</b-col>
								<b-col cols="12" class="d-flex gap-x-2">
									<b-button @click="saveParent('madrilla')" variant="primary" type="submit">
										Guardar
									</b-button>
									<b-button @click="resetForm('madrilla')" variant="outline-primary" type="button">
										Resetear
									</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</validation-observer>
			</b-tab>
		</b-tabs>
	</b-modal>
</template>

<script>
import pedigreeService from "@/services/pedigree.service";
import SelectableImage from "@/views/amg/specimens/components/SelectableImage.vue";
import SelectSpecimens from "../../components/SelectSpecimens.vue";
import modalMixin from "@/mixins/modal";
export default {
	name: "ModalRegisterParents",
	components: {
		SelectableImage,
		SelectSpecimens,
	},
	mixins: [modalMixin],
	props: {
		specimen: {
			type: Object,
			default: () => ({
				id: null,
				alias: "",
				plate: "",
				madrilla_id: null,
				padrillo_id: null,
				date: null,
			}),
		},
		omits: [Array, Object],
	},
	mounted() {
		this.toggleModal("modal-specimen-parents");
	},
	data: () => ({
		tabIndex: 0,
		isOpenModal: false,
		clearPadrilloSearch: false,
		clearMadrillaSearch: false,
		foundSpecimen: false,
		selectedSpecimens: [],
		madrilla: {
			alias: null,
			plate: null,
			dob: null,
			observation: null,
			inside: "SI",
			avatar: null,
			id: null,
		},
		padrillo: {
			alias: null,
			plate: null,
			dob: null,
			observation: null,
			inside: "SI",
			avatar: null,
			id: null,
		},
	}),
	computed: {
		setOfPadrilloOmit() {
			return this.omits.filter((omit) => {
				if (this.tabIndex == 0) {
					return omit.type === "padrillo";
				}
				return omit.type === "madrilla";
			});
		},
	},
	methods: {
		selected(specimen, type) {
			this.foundSpecimen = true;
			this.$data[type].alias = specimen.alias;
			this.$data[type].plate = specimen.plate;
			this.$data[type].dob = specimen.dob;
			this.$data[type].observation = specimen.observations;
			this.$data[type].inside = specimen.inside;
			this.$data[type].avatar = specimen.photo;
			this.$data[type].id = specimen.specimen_id;
		},
		refreshPadrillo() {
			this.padrillo.alias = null;
			this.padrillo.plate = null;
			this.padrillo.dob = null;
			this.padrillo.observation = null;
			this.padrillo.inside = "SI";
			this.padrillo.avatar = null;
			this.padrillo.id = null;
			this.clearPadrilloSearch = true;
		},
		refreshMadrilla() {
			this.madrilla.alias = null;
			this.madrilla.plate = null;
			this.madrilla.dob = null;
			this.madrilla.observation = null;
			this.madrilla.inside = "SI";
			this.madrilla.avatar = null;
			this.madrilla.id = null;
			this.clearMadrillaSearch = true;
		},
		async saveParent(type) {
			//formMadrilla
			if (!(await this.$refs.formPadrillo.validate()) && this.tabIndex == 0) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}

			if (!(await this.$refs.formMadrilla.validate()) && this.tabIndex == 1) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}

			this.isPreloading();

			try {
				const spe = new FormData();

				spe.append("alias", this.$data[type].alias);
				spe.append("plate", this.$data[type].plate);
				spe.append("dob", this.$data[type].dob);
				spe.append("observation", this.$data[type].observation);
				spe.append("inside", this.$data[type].inside);
				spe.append("avatar", this.$data[type].avatar);
				spe.append("id", this.$data[type].id);
				spe.append("category_id", this.tabIndex == 0 ? 1 : 2);
				spe.append("user_id", this.currentUser.id);
				spe.append("specimen_id", this.specimen.id);

				const {message} = await pedigreeService.saveSpecimenParent(spe);

				this.showToast("success", "top-right", "Pedigree", "SuccessIcon", message);

				this.$emit("parent-saved");

				this.$emit("updateSpecimen", {
					data: {
						...this.specimen,
						[`${type}_id`]: 1000000,
					},
				});

				if (this.specimen.madrilla_id && this.specimen.padrillo_id) {
					this.toggleModal("modal-specimen-parents");

					// this.$emit('hidden');
				}

				this.resetForm(type);

				// this.toggleModal("modal-specimen-parents");
			} catch (error) {
				const message = error.response?.data?.message || error.message;
				this.showToast(
					"warning",
					"top-right",
					`Error al guardar ${this.padrillo.plate ? "Padrillo" : "Madrilla"}`,
					"WarningIcon",
					message
				);
			} finally {
				this.isPreloading(false);
			}
		},
		resetForm(type) {
			this.foundSpecimen = false;
			Object.assign(this.$data[type], {
				alias: null,
				plate: null,
				dob: null,
				observation: null,
				inside: "SI",
				avatar: null,
				id: null,
			});
			// console.log(this.$refs.formMadrilla	);
			this.$refs.formMadrilla.reset();
			this.$refs.formPadrillo.reset();
		},
	},
	watch: {
		tabIndex(value) {
			this.foundSpecimen = false;
			if (value === 0) {
				this.refreshPadrillo();
			}
			if (value === 1) {
				this.refreshMadrilla();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.ts-r-button {
	caret-color: var(--primary);
	display: inline-flex;
	column-gap: 0.4rem;
	align-items: center;
	font-size: 1rem;
	padding: 0.5rem 1rem;
	border: 1px solid #d8d6de;
	border-radius: 0.357rem;

	&:not(:last-child) {
		margin-right: 1rem;
	}

	.dark-layout & {
		border-color: #404656;
	}
}
</style>

<style lang="scss">
.mrp-tabs {
	.nav-item {
		display: flex;
	}

	.nav-item a[role="tab"] {
		background-color: var(--dark-hard);
		border-radius: 0.358rem;
		border-radius: 0.358rem 0.358rem 0 0;
		padding-left: 1.75rem;
		padding-right: 1.75rem;

		&::after {
			content: none;
		}
	}

	.nav-item a[aria-selected="true"] {
		background-color: var(--primary) !important;
		color: white !important;

		svg path {
			fill: white;
		}
	}
}

.mrp-content {
	background: var(--darken);
	border: 1px solid #e7e2e2;
	border-radius: 0px 0.358rem 0.358rem 0.358rem;

	.dark-layout & {
		border-color: #3c4150;
	}
}

#modal-specimen-parents .modal-header {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.ts-textarea {
	height: 80% !important;
	@media screen and (min-width: 70em) {
		height: 100% !important;
	}
}
.tx-textarea {
	height: 135px !important;
	@media screen and (min-width: 70em) {
		height: 60% !important;
	}
}
.txs-selectable .vs__dropdown-toggle {
	border-color: var(--primary) !important;
}
</style>
