import axios from "@/axios";

const url = "/api/confrontation/";

class ConfrontationsResultService {


	async getSeasons() {
		const { data } = await axios.get(`/api/tournament/seasons`);
		return data;
	}
	
	async getSeasonConfrontations(category_id, specimen_id, start_date, end_date){
		const { data } =  await axios.get(`${url}results/get-results-by-specimen-childs`,{
			params:{
				category_id,
				specimen_id,
				start_date,
				end_date, 
			}
		})
		return data;
	}
	async getResultsBySpecimenChilds(category_id, specimen_id, start_date, end_date, result_id){
		const { data } =  await axios.get(`${url}results/get-results-by-specimen-childs-specimens`,{
			params:{
				category_id,
				specimen_id,
				start_date,
				end_date, 
				result_id
			}
		})
		return data;
	}
	async getScoreRankingChildSpecimen(specimen_id, page){
		const { data } =  await axios.get(`${url}get-score-ranking-childs-specimen/${specimen_id}`, {
			params:{
				page:page
			}
		})
		return data;
	}	
}
export default new ConfrontationsResultService();
