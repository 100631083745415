<template>
	<div v-if="data" class="container-modal">
		<div class="container-header">
			<div class="tournament">
				<div class="circle" :style="{ background: data.tournament_color1 }"></div>
				<div>
					<h3 class="m-0 mb-1 title-tournament">Torneo | {{ data.tournament_name }}</h3>
					<div @click="linkTournament(item.tournament_id)" class="view-tournament">
						<span class="mb-0">Ver torneo</span>
						<feather-icon icon="ExternalLinkIcon" size="20" class="cursor-pointer text-primary ml-1" />
					</div>
					
					<!-- <h6 class="mb-0 mt-1">Miércoles, 11 de Mayo 11:00</h6> -->
					<h6 class="mb-0 title-stage">
						Etapa: <span>{{ data.tournament_stage }}</span>
					</h6>
					<h6 class="mb-0 title-stage">
						Coliseo: <span>{{ data.coliseum_name }}</span>
					</h6>
					<h6 class="mb-0 title-stage">
						Ciudad: <span>{{ data.city }}</span>
					</h6>
					<h6 class="mb-0 title-stage">
						Tiempo: <span>{{ data.video_duration }}</span>
					</h6>
				</div>
			</div>
			<div class="video-container" v-if="data.video_thumb && data.video_status == 2">
				<div @click="viewSpecimen(data.video_url)" class="container-image">
					<p class="">Ver video</p>
					<img :src="data.video_thumb" alt="" />
					<p class="mb-0 duration">{{ data.video_duration }}</p>
				</div>
			</div>
			<div v-if="data.thumb && data.video_status != 2">
				<div class="container-image">
					<p class="video-text-processing">Procesando video</p>
				</div>
			</div>
		</div>

		<div class="container-result">
			<div class="container-padrillo">
				<div class="padrillo">
					<p class="alias">{{ data.specimen.alias }}</p>
					<div @click="doCopy(data.specimen.plate)" class="plate cursor-pointer">
						<feather-icon class="icon" icon="ClipboardIcon" />
						<p class="text-plate">{{ data.specimen.plate }}</p>
					</div>
				</div>
				<feather-icon
					@click="linkSpecimen(item.specimen.id)"
					icon="ExternalLinkIcon"
					size="20"
					class="cursor-pointer text-primary ml-1"
				/>
			</div>
			<div
				class="result"
				:class="[
					data.result == 'TRIUNFO' ? 'result-1' : '',
					data.result == 'DERROTA' ? 'result-2' : '',
					data.result == 'EMPATE' ? 'result-3' : '',
					data.result == 'SIN JUGAR' ? 'result-4' : '',
				]"
			>
				<p>
					{{
						data.result == "TRIUNFO"
							? "V"
							: data.result == "EMPATE"
							? "E"
							: data.result == "DERROTA"
							? "D"
							: "S"
					}}
				</p>
			</div>
		</div>

		<div class="">
			<div v-if="data.preparador" class="item-user-battle">
				<div class="user-text">
					<feather-icon :size="width < 600 ? '15' : '18'" icon="UserIcon" />
					<div class="name-user">
						<p class="mb-0">Preparador:</p>
						<span>{{ data.preparador.name }}</span>
					</div>
				</div>
				<div class="user-phone">
					<feather-icon :size="width < 600 ? '15' : '18'" icon="CopyIcon" />
					<p @click="doCopy(data.preparador.telefono)" class="mb-0">
						{{ data.preparador.telefono }}
					</p>
				</div>
			</div>

			<div v-if="data.careador" class="item-user-battle">
				<div class="user-text">
					<feather-icon :size="width < 600 ? '15' : '18'" icon="UserIcon" />
					<div class="name-user">
						<p class="mb-0">Careador:</p>
						<span>{{ data.careador.name }}</span>
					</div>
				</div>
				<div class="user-phone">
					<feather-icon :size="width < 600 ? '15' : '18'" icon="CopyIcon" />
					<p @click="doCopy(data.careador.telefono)" class="mb-0">
						{{ data.careador.telefono }}
					</p>
				</div>
			</div>

			<div v-if="data.amarrador" class="item-user-battle">
				<div class="user-text">
					<feather-icon :size="width < 600 ? '15' : '18'" icon="UserIcon" />
					<div class="name-user">
						<p class="mb-0">Amarrador:</p>
						<span>{{ data.amarrador.name }}</span>
					</div>
				</div>
				<div class="user-phone">
					<feather-icon :size="width < 600 ? '15' : '18'" icon="CopyIcon" />
					<p @click="doCopy(data.amarrador.telefono)" class="mb-0">
						{{ data.amarrador.telefono }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useClipboard } from "@vueuse/core";
import { mapState, mapActions } from "vuex";

export default {
	name: "ViewDetailsBattle",
	props: ["item"],
	data() {
		return {
			width: this.$store.state["app"].windowWidth,
			data: null,
		};
	},
	computed: {
		...mapState("header_book", [
			"imagesVideoteca",
			"openModal",
			"uploadImages",
			"upload",
			"toggleOpenGallery",
			"indexImage",
			"urlBackendNode",
		]),
	},
	mounted() {
		this.data = this.item;
    console.log('data', this.data);
		this.setVideoteca(true);
		this.setImagesVideoteca([
			{
				id_specimens: this.data.specimen.id,
				name: this.data.specimen.alias ? this.data.specimen.alias : "",
				preload: this.data.specimen.thumb,
				type: "video",
				label: `${this.data.specimen.alias ? this.data.specimen.alias : ''} ${this.data.specimen.plate}`,
				url: this.data.video_url,
			},
		]);
	},
	methods: {
		...mapActions("header_book", [
			"setImagesVideoteca",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setVideoteca",
		]),
		doCopy(message) {
			const { copy } = useClipboard();

			copy(message);
		},
		viewSpecimen(item) {
			let index = 0;
			this.toggleModal();
			this.setTextImage({
				title: "",
				url: `/ejemplares/detalle/`,
			});
			this.setIndexImage(index);
		},
		linkSpecimen(id) {
			window.open(`/ejemplares/detalle/${id}`, "_blank");
		},
		linkTournament(id) {
			window.open(`/torneos/detalle/${id}`, "_blank");
		},
	},
};
</script>

<style lang="scss" scoped>
.container-modal {
	padding: 1rem 2rem;
	.container-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding-bottom: 1rem;
		.tournament {
			display: flex;
			align-items: flex-start;
			width: 100%;
			.circle {
				margin-top: 0.5rem;
				height: 10px;
				width: 10px;
				border-radius: 2px;
				margin-right: 0.5rem;
			}
			h3 {
				font-weight: bold;
			}
		}
		.video-container {
			cursor: pointer;
			display: flex;
			align-items: center;
			p {
				margin-left: 0.5rem;
				margin-bottom: 0;
				font-weight: bold;
				transition: 0.2s all ease-in-out;
			}
			&:hover {
				color: #9c97da;
			}
		}
	}
}
.container-padrillo {
	display: flex;
	align-items: center;
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
		.alias {
			margin-bottom: 0;
		}
		.plate {
			background: #fff;
			border-radius: 8px;
			display: flex;
			align-items: center;
			transition: 0.2s all ease-in-out;
			padding: 0.05rem 0.2rem !important;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			.icon {
				color: #9c97da;
				font-weight: bold;
			}
			.text-plate {
				color: #9c97da;
				font-weight: bold;
				margin-bottom: 0;
			}
			&:hover {
				transform: scale(1.05);
			}
		}
	}
}
.result {
	height: 30px;
	width: 30px;
	background: #a5aab0;
	border-radius: 50%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
	transition: 0.2s all ease-in-out;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	p {
		transition: 0.2s all ease-in-out;
		font-size: 16px !important;
		font-weight: bold;
		margin-bottom: 0;
	}
}
.result-1 {
	background: #51b131;
}
.result-2 {
	background: #e74c3c;
}
.result-3 {
	background: #a5aab0;
}
.result-4 {
	background: #fff;
	p {
		color: #343d55;
	}
}
.container-result {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 50%;
	margin: 2rem auto 2rem auto;
}
.item-user-battle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	margin-bottom: 0.2rem;
	&:last-child {
		margin-bottom: 0;
	}
	.user-text {
		display: flex;
		align-items: flex-start;
		p {
			font-weight: bold;
			font-size: 15px;
		}
		.name-user {
			margin-left: 0.3rem;
			p {
				font-size: 14px;
			}
			span {
				font-size: 13px;
			}
		}
	}
	.user-phone {
		display: flex;
		align-items: center;
		color: #9c97da;
		cursor: pointer;
		transition: 0.2s all ease-in-out;
		p {
			font-weight: bold;
			margin-left: 0.5rem;
			font-size: 13px;
		}
		&:hover {
			transform: scale(1.02);
		}
	}
}
.container-image {
	text-align: center;
	position: relative;
	.duration {
		position: absolute;
		bottom: 5px;
		right: 5px;
		background: #e74c3c;
		padding: 0 0.3rem !important;
		border-radius: 4px;
		color: #fff;
		height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
	}
	p {
		margin-bottom: 0.2rem;
		font-size: 10px;
		margin-left: 0 !important;
		font-weight: bold;
	}
	img {
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		padding: 0.2rem;
		width: 90px;
		height: 90px;
		object-fit: cover;
	}
}
.title-tournament {
	font-size: 16px;
}
.title-stage {
	font-weight: bold;
	margin-top: 0.3rem;
	span {
		font-size: 12px;
		font-weight: lighter;
	}
}
.view-tournament {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}
.video-text-processing {
	line-height: 1.2;
}
</style>
