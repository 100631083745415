<template>
	<b-modal hide-footer centered size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<div>
				<VueApexCharts type="donut" height="350" :options="donutOpts" :series="donutData" />
			</div>
			<hr />
			<b-table-simple small sticky-header="10rem">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Criador</b-th>
						<b-th>Porcentaje</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(line, i) in lines" :key="i">
						<b-td>{{ line.breeder }}</b-td>
						<b-td>{{ line.percentage }}%</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		donutData: [44, 55, 13, 33],
		donutOpts: {
			labels: ["Apple", "Mango", "Orange", "Watermelon"],
			colors: [
				"#B71C1C",
				"#827717",
				"#880E4F",
				"#4A148C",
				"#33691E",
				"#311B92",
				"#1A237E",
				"#1B5E20",
				"#0D47A1",
				"#01579B",
				"#004D40",
				"#006064",
			],
			legend: { position: "bottom" },
		},
		lines: [],
	}),
	computed: {
		title() {
			return `Linea del ejemplar / ${this.info.alias || this.info.plate}`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.lines = []
			;(this.donutOpts.colors = [
				"#B71C1C",
				"#827717",
				"#880E4F",
				"#4A148C",
				"#33691E",
				"#311B92",
				"#1A237E",
				"#1B5E20",
				"#0D47A1",
				"#01579B",
				"#004D40",
				"#006064",
			]),
				this.$emit("closing")
		},
		loadChart() {
			this.isLoading = true
			this.donutData = []
			this.donutOpts.labels = []
			this.lines = []
			this.info.breeder_line.forEach((line) => {
				this.donutData.push(line.percentage)
				this.donutOpts.labels.push(line.breeder_name)
				this.lines.push({
					breeder: line.breeder_name,
					percentage: line.percentage,
				})
			})
			// cuando el total no es 100
			const totalLinesCount = this.info.breeder_line.reduce((acc, val) => val.percentage + acc, 0)
			if (totalLinesCount != 100) {
				let missing = 100 - totalLinesCount
				this.donutData.push(missing)
				this.donutOpts.labels.push("DESCONOCIDO")
				this.donutOpts.colors[this.donutData.length - 1] = "#616161"
				this.lines.push({
					breeder: "DESCONOCIDO",
					percentage: missing,
				})
			}
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.loadChart()
			}
		},
	},
}
</script>
