<template>
	<div>
		<BlockTabs
			v-if="season.value && status == 1"
			:season="season"
			:trigger="trigger + blockTrigger"
			@loaded="onBlock"
		/>
		<b-overlay :show="isLoading">
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th>Etapa de preparacion</b-th>
						<b-th>Ejemplar</b-th>
						<b-th>Entrenamiento</b-th>
						<b-th>
							<div class="d-flex align-items-center justify-content-center">
								Topes
								<p class="mb-0 badge badge-danger ml-25" v-if="status == 1 && pendingsTotal > 0">
									{{ pendingsTotal }}
								</p>
							</div>
						</b-th>
						<!-- <b-th>Cualidades</b-th>
						<b-th>Defectos</b-th> -->
						<b-th>Dias</b-th>
						<b-th>Suplemento</b-th>
						<b-th>Kg / Lb</b-th>
						<b-th>Desparacitacion</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr :variant="rowInDanger(row)" v-for="row in rows" :key="row.training_id">
						<b-td>
							{{ row.status_name }}
						</b-td>
						<b-td>
							<SpecimenPlate :specimen="row.specimen" />
							<small class="d-block mt-05 text-left" v-if="row.specimen.status">
								<b-badge :variant="colorizeSpecimenStatus(row.specimen.status_id)">
									Estado:
									<b>{{ row.specimen.status }}</b>
								</b-badge>
							</small>
							<small class="d-block mt-05 text-left">
								Preparador:
								<b>{{ row.created.user }}</b>
							</small>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center align-items-center" style="gap: 1rem">
								<div v-if="row.routine_last">
									<div class="d-flex flex-column align-items-center" style="width: 100%">
										<div
											class="text-primary"
											style="overflow: hidden; position: relative; height: 20px; width: 100%"
										>
											{{ Number(row.routine_last.average).toFixed(2) }}
										</div>
									</div>
									<div class="w-100 d-flex justify-content-end mt-50" style="">
										<div class="text-nowrap">
											<b-badge
												variant="primary"
												v-b-tooltip.hover.top="`Fecha del ultimo entrenamiento`"
											>
												{{ row.routine_last.date | myGlobalDayShort }}
											</b-badge>
											<b-button
												class="btn-icon rounded-circle clickable ml-05"
												variant="outline-primary"
												size="sm"
												@click="
													;(modalTrackingTraining.show = !modalTrackingTraining.show),
														(modalTrackingTraining.info = row)
												"
											>
												{{ row.routine_last.total }}
											</b-button>
										</div>
									</div>
								</div>
								<span v-else>---</span>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center align-items-center" style="gap: 1rem">
								<div v-if="row.tope_last" class="position-relative">
									<b-badge
										variant="danger"
										class="position-absolute"
										style="right: -0.8rem; top: -0.8rem"
										v-if="row.topes_pending && row.topes_pending > 0"
									>
										{{ row.topes_pending || 0 }}
									</b-badge>
									<div class="d-flex flex-column align-items-end mt-1" style="width: 100%">
										<div
											class="text-primary"
											style="overflow: hidden; position: relative; height: 20px; width: 100%"
										>
											{{ Number(row.tope_last.average).toFixed(2) }}
											<feather-icon class="cursor-pointer star-score" size="16" icon="StarIcon" />
										</div>
									</div>
									<div class="w-100 d-flex justify-content-end mt-50">
										<div class="d-flex align-items-center">
											<b-badge variant="primary" v-b-tooltip.hover.top="`Fecha del ultimo tope`">
												{{ row.tope_last.date | myGlobalDayShort }}
											</b-badge>
											<b-button
												class="btn-icon rounded-circle clickable ml-05"
												variant="outline-primary"
												size="sm"
												@click="
													;(modalTrackingTope.show = !modalTrackingTope.show),
														(modalTrackingTope.info = { ...row, _status: status })
												"
											>
												{{ row.tope_last.total }}
											</b-button>
										</div>
									</div>
								</div>
								<span class="text-nowrap" v-else>---</span>
							</div>
						</b-td>
						<!-- <b-td>
							<div v-if="row.tope_last && row.tope_last.qualities && row.tope_last.qualities.length > 0">
								<div>
									<div
										class="d-flex justify-content-between gap-x-1 mb-05 w-100 cursor-pointer"
										v-for="val in row.tope_last.qualities"
										:key="val.id"
										@click="
											;(modalTrackingCharacteristic.show = !modalTrackingCharacteristic.show),
												(modalTrackingCharacteristic.info = { ...row, _type: 'q', _id: val.id })
										"
									>
										<small class="text-success text-nowrap">{{ val.name }}</small>
										<b-badge variant="success">{{ val.count }}</b-badge>
									</div>
								</div>
							</div>
							<span v-else>---</span>
						</b-td>
						<b-td>
							<div v-if="row.tope_last && row.tope_last.defects && row.tope_last.defects.length > 0">
								<div>
									<div
										class="d-flex justify-content-between gap-x-1 mb-05 w-100 cursor-pointer"
										v-for="val in row.tope_last.defects"
										:key="val.id"
										@click="
											;(modalTrackingCharacteristic.show = !modalTrackingCharacteristic.show),
												(modalTrackingCharacteristic.info = { ...row, _type: 'd', _id: val.id })
										"
									>
										<small class="text-danger text-nowrap">{{ val.name }}</small>
										<b-badge variant="danger">{{ val.count }}</b-badge>
									</div>
								</div>
							</div>
							<span v-else>---</span>
						</b-td> -->
						<b-td>
							<template v-if="status == 1">
								<span class="text-nowrap">{{ row.created.date | daysSince }}</span>
								<br />
								<small v-b-tooltip:hover.bottom="`Inicio`">
									{{ row.created.date | myGlobalDayShort }}
								</small>
							</template>
							<template v-else>
								<span class="text-nowrap">
									{{ dateDiffFormat(row.created.date, row.updated.date) }}
								</span>
								<br />
								<small class="text-nowrap" v-b-tooltip:hover.bottom="`INICIO - FIN`">
									{{ row.created.date | myGlobalDayShort }} -
									{{ row.updated.date | myGlobalDayShort }}
								</small>
							</template>
						</b-td>

						<b-td>
							<div class="d-flex justify-content-center align-items-center" style="gap: 1rem">
								<div v-if="row.vitamin_last" class="d-flex justify-content-center align-items-center">
									<b-button
										class="btn-icon rounded-circle clickable ml-05"
										variant="outline-primary font-medium-3"
										size="sm"
										v-if="row.vitamin_last"
										@click="
											;(modalTrackingVitamins.show = !modalTrackingVitamins.show),
												(modalTrackingVitamins.info = row)
										"
									>
										{{ row.vitamin_last.total }}
									</b-button>
								</div>
								<span v-else>---</span>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center align-items-center flex-column" style="gap: 1rem">
								<div v-if="row.weight_last" class="d-flex flex-column align-items-center">
									<p class="">
										<span class="font-weight-bolder">{{ row.weight_last.last_kg_weight }}</span>
										Kg
										<br />
										<span class="font-weight-bolder">{{ row.weight_last.last_pounds_weight }}</span>
										Lb
									</p>
									<div class="d-flex">
										<b-button
											class="btn-icon rounded-circle clickable ml-05"
											variant="primary"
											size="sm"
											v-if="row.weight_last"
											@click="
												;(modalTrackingWeight.show = !modalTrackingWeight.show),
													(modalTrackingWeight.info = { ...row, ...season })
											"
										>
											<feather-icon class="cursor-pointer" size="12" icon="CheckIcon" />
										</b-button>
									</div>
								</div>
								<span :class="[daysToAssign(row.created.date) ? 'text-primary' : 'text-danger']" v-else>
									<span v-if="status == 1">{{ daysToAssignStr(row.created.date) }}</span>
									<span v-else>---</span>
								</span>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center align-items-center flex-column" style="gap: 1rem">
								<div v-if="row.deworn_last">
									<div class="mt-1 d-flex justify-content-center">
										<b-button
											class="btn-icon rounded-circle clickable ml-05"
											variant="primary"
											size="sm"
											v-if="row.deworn_last"
											@click="
												;(modalTrackingDeworn.show = !modalTrackingDeworn.show),
													(modalTrackingDeworn.info = row)
											"
										>
											<feather-icon class="cursor-pointer" size="12" icon="CheckIcon" />
										</b-button>
									</div>
								</div>
								<span :class="[daysToAssign(row.created.date) ? 'text-primary' : 'text-danger']" v-else>
									<span v-if="status == 1">{{ daysToAssignStr(row.created.date) }}</span>
									<span v-else>---</span>
								</span>
								<!-- <b-button
									class="btn-icon"
									style="padding: 2px"
									variant="outline-success"
									size="sm"
									v-if="!blocked && !row.deworn_last"
									@click="insertDeworn(row)"
								>
									<feather-icon class="cursor-pointer" size="16" icon="PlusIcon" />
								</b-button> -->
							</div>
						</b-td>
						<b-td>
							<div class="d-flex align-content-center justify-items-center">
								<b-button
									variant="flat-secondary"
									class="btn-icon mx-50"
									v-b-tooltip.hover.top="`Ver tracking`"
									@click="
										;(showActionsModal = !showActionsModal),
											(curSpecimen = {
												...row,
												_a: 'track',
												_status: status,
												_season: season,
											})
									"
								>
									<feather-icon size="20" icon="ListIcon" />
								</b-button>

								<b-button
									variant="flat-success"
									class="btn-icon mx-50"
									v-b-tooltip.hover.top="`Ver linea de tiempo`"
									@click="setLineSpecimen(row)"
								>
									<feather-icon size="20" icon="ActivityIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-th colspan="10">No hay registros para mostrar</b-th>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<WeightModal
			:show="showWeightModal"
			:info="selectedSpecimens"
			@closing="showWeightModal = false"
			@refresh="getRows"
			@cleanCheck="cleanSpecimenCheck"
		/>
		<VitaminsModal
			:show="showVitaminsModal"
			:info="selectedSpecimens"
			@closing="showVitaminsModal = false"
			@refresh="getRows"
			@cleanCheck="cleanSpecimenCheck"
		/>
		<TrainingModal
			:show="showTrainingModal"
			:info="curSpecimen"
			@closing="showTrainingModal = false"
			@refresh="getRows"
		/>
		<TopeModal :show="showTopeModal" :info="curSpecimen" @closing="showTopeModal = false" @refresh="getRows" />
		<ActionsModal
			:show="showActionsModal"
			:info="curSpecimen"
			@closing="showActionsModal = false"
			@refresh="getRows(), (blockTrigger = blockTrigger + 1)"
		/>
		<TrackingWeight
			:show="modalTrackingWeight.show"
			:info="modalTrackingWeight.info"
			@closing="modalTrackingWeight.show = false"
		/>
		<TrackingVitamin
			:show="modalTrackingVitamins.show"
			:info="modalTrackingVitamins.info"
			@closing="modalTrackingVitamins.show = false"
		/>
		<TrackingDeworn
			:show="modalTrackingDeworn.show"
			:info="modalTrackingDeworn.info"
			@closing="modalTrackingDeworn.show = false"
		/>
		<TrackingTraining
			:show="modalTrackingTraining.show"
			:info="modalTrackingTraining.info"
			@closing="modalTrackingTraining.show = false"
			@refresh="getRows"
		/>
		<TrackingTope
			:show="modalTrackingTope.show"
			:info="modalTrackingTope.info"
			@closing="modalTrackingTope.show = false"
			@refresh="getRows"
		/>
		<CharacteristicTracking
			:show="modalTrackingCharacteristic.show"
			:info="modalTrackingCharacteristic.info"
			@closing="modalTrackingCharacteristic.show = false"
			@refresh="getRows"
		/>

		<LineLife
			:show="showLineSpecimen.show"
			@closing="showLineSpecimen.show = false"
			:specimen="{
				id: showLineSpecimen.specimen ? showLineSpecimen.specimen.id : null,
				image: showLineSpecimen.specimen ? showLineSpecimen.specimen.image : null,
				plate: showLineSpecimen.specimen ? showLineSpecimen.specimen.plate : null,
				alias: showLineSpecimen.specimen ? showLineSpecimen.specimen.alias : null,
			}"
			:training_id="showLineSpecimen.training_id"
		/>
	</div>
</template>

<script>
import moment from "moment"

import preparacionService from "@/services/preparacion/default.service.js"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import WeightModal from "./WeightModal.vue"
import VitaminsModal from "./VitaminsModal.vue"
import TrainingModal from "./TrainingModal.vue"
import TopeModal from "./TopeModal.vue"
import ActionsModal from "./ActionsModal.vue"
import TrackingDeworn from "./DewornTracking.vue"
import TrackingWeight from "./WeightTracking.vue"
import TrackingVitamin from "./VitaminsTracking.vue"
import TrackingTraining from "./TrainingTracking.vue"
import TrackingTope from "./TopeTracking.vue"
import GlobalChangeDeworn from "./GlobalChangeDeworn.vue"
import CharacteristicTracking from "./CharacteristicTracking.vue"
import BlockTabs from "./BlockTabs.vue"
import LineLife from "../../specimens/dashboard/modals/LineLife.vue"

export default {
	components: {
		SpecimenPlate,
		TrainingModal,
		VitaminsModal,
		WeightModal,
		TopeModal,
		ActionsModal,
		TrackingDeworn,
		TrackingWeight,
		TrackingVitamin,
		TrackingTraining,
		TrackingTope,
		GlobalChangeDeworn,
		CharacteristicTracking,
		BlockTabs,
		LineLife,
	},
	props: {
		trigger: Number,
		search: String,
		status: Number,
		season: Object, // {value: number,text: string,star: string,end: string,}
		blocked: Boolean,
		filters: Object, // {quality: number[],defect: number[],averages: number[2],specimen_id: number}
	},
	data: () => ({
		isLoading: false,
		rows: [],
		curBlock: null,
		pagination: {
			total: 0,
			curPage: 1,
			perPage: 50,
			from: 0,
			to: 0,
		},
		counters: { lmv: 0, mjs: 0 },
		showTrainingModal: false,
		showTopeModal: false,
		showVitaminsModal: false,
		showWeightModal: false,
		showActionsModal: false,
		curSpecimen: null,
		selectedSpecimens: [],
		isCanceling: false,
		//
		modalTrackingDeworn: {
			show: false,
			info: {},
		},
		modalTrackingWeight: {
			show: false,
			info: {},
		},
		modalTrackingVitamins: {
			show: false,
			info: {},
		},
		modalTrackingTraining: {
			show: false,
			info: {},
		},
		modalTrackingTope: {
			show: false,
			info: {},
		},
		modalTrackingCharacteristic: {
			show: false,
			info: {},
		},
		pendingsTotal: 0,
		massiveCheck: false,
		blockTrigger: 0,
		showLineSpecimen: {
			show: false,
			specimen: null,
		},
	}),
	computed: {
		massiveDisabled() {
			return !this.rows.some((row) => row._check)
		},
		specimenIdCurrent() {
			return this.$router.history.current.params ? this.$router.history.current.params.specimen_id : null
		},
	},
	methods: {
		async getRows() {
			this.massiveCheck = false
			this.isLoading = true
			const { data } = await preparacionService.getTrainings({
				status_id: null,
				search: this.search,
				start_date: this.season.start,
				end_date: this.season.end,
				page: this.pagination.curPage,
				perpage: this.pagination.perPage,
				block: null,
				defects: this.filters.defects && JSON.stringify(this.filters.defects),
				qualities: this.filters.qualities && JSON.stringify(this.filters.qualities),
				average_min: this.filters.activeAverages ? this.filters.averages[0] : null,
				average_max: this.filters.activeAverages ? this.filters.averages[1] : null,
				specimen_id: this.filters.specimen_id || this.specimenIdCurrent,
				average_training_min: this.filters.activeTrainings ? this.filters.trainings[0] : null,
				average_training_max: this.filters.activeTrainings ? this.filters.trainings[1] : null,
				type_filter_date: this.status != 1 ? this.filters.dateType : null,
				filter_start_date: this.filters.dateStart,
				filter_end_date: this.filters.dateEnd,
			})
			this.pagination.total = data.total
			this.pagination.from = data.from
			this.pagination.to = data.to
			this.rows = data.data.map((row) => {
				return {
					...row,
					_check: false,
					showLine: false,
				}
			})
			this.initQualityPendings()

			this.isLoading = false
			this.$emit("refresh")
		},
		async insertDeworn(row, massive = false) {
			this.isLoading = true
			let html, trainings
			if (massive) {
				trainings = this.rows.map((row) => row.training_id)
				html = `
					<p class="mb-0">Se agregará desparacitacion para <span class="font-weight-bolder">${this.rows.length}</span> ejemplares.</p>
				`
			} else {
				trainings = [row.training_id]
				html = `
					<p class="mb-0">Se agregará desparacitacion para <span class="font-weight-bolder">${
						row.specimen.alias || row.specimen.plate
					}</span>.</p>
				`
			}
			const { isConfirmed } = await this.showConfirmSwal({ html })
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			await preparacionService.insertDeworn({ trainings })
			this.isLoading = false
			this.getRows()
		},
		colorizePoints(points) {
			if (points == 2.5) return "bg-info-light"
			else if (points == null) return "bg-secondary-light"
			else if (points > 2.5) return "bg-success-light"
			else if (points < 2.5) return "bg-danger-light"
		},
		toogleAllCheck(e) {
			this.rows.forEach((row) => {
				row._check = e
			})
		},
		sendRows(row, action) {
			let selecteds
			if (row) {
				selecteds = [row]
			} else {
				selecteds = this.rows.filter((row) => row._check)
			}
			this.selectedSpecimens = selecteds
			if (selecteds.length == 0) {
				this.showToast(
					"danger",
					"top-right",
					"Preparacion",
					"AlertCircleIcon",
					`No hay ejemplares seleccionados`
				)
				return
			}
			switch (action) {
				case "weight":
					this.showWeightModal = !this.showWeightModal
					break
				case "vitamin":
					this.showVitaminsModal = !this.showVitaminsModal
					break
			}
		},
		cleanSpecimenCheck(specimen_id) {
			let index = this.rows.findIndex((element) => specimen_id == element.specimen.id)
			this.rows[index]._check = false
		},
		dateDiffFormat(init, end) {
			init = moment(init).format("YYYY-MM-DD")
			end = moment(end).format("YYYY-MM-DD")
			let start = moment(init)
			let today = moment(end)
			let daysDiff = today.diff(start, "days")
			return `${daysDiff} dia(s)`
		},
		onBlock(e) {
			this.curBlock = e
			this.getRows()
		},
		daysToAssign(date) {
			if (moment(date).isSame(new Date(), "day")) {
				return `7 dias para asignar`
			}
			let today = moment()
			let start = moment(new Date(date)).format("YYYY-MM-DD")
			let daysDiff = today.diff(start, "days")
			return daysDiff < 7
			// if (daysDiff >= 7) {
			// 	return false;
			// }
			// return `quedan: ${7 - daysDiff} dia(s)`;
		},
		daysToAssignStr(date) {
			if (moment(date).isSame(new Date(), "day")) {
				return `Pendiente (7d)`
			}
			let today = moment()
			let start = moment(new Date(date)).format("YYYY-MM-DD")
			let daysDiff = today.diff(start, "days")
			if (daysDiff >= 7) {
				return `Pendiente: (-${daysDiff - 7} d)`
			}
			return `Pendiente:  (${7 - daysDiff} d)`
		},
		rowInDanger(row) {
			// disable if is not in process tab
			if (this.status == 4) return
			// check if has no weight and was created more than 7 days ago
			if (!row.weight_last && !this.daysToAssign(row.created.date)) {
				return "danger"
			}
			if (!row.deworn_last && !this.daysToAssign(row.created.date)) {
				return "danger"
			}
			return null
		},
		colorizeSpecimenStatus(status) {
			switch (status) {
				case 1:
					return "success"
				case 3:
					return "danger"
				case 13:
					return "warning"

				default:
					return "secondary"
			}
		},
		async initQualityPendings() {
			const { data } = await preparacionService.getCountTopesPending({
				block: this.status == 1 ? this.curBlock : null,
				start_date: this.season.start,
				end_date: this.season.end,
			})
			if (data.length > 0) {
				this.pendingsTotal = data[0].quantity
			}
		},
		setLineSpecimen(row) {
			this.showLineSpecimen = {
				show: true,
				specimen: row.specimen,
				training_id: row.training_id,
			}
		},
	},
	created() {
		this.getRows()
	},
	watch: {
		trigger(val) {
			this.getRows()
		},
		"pagination.curPage"(val) {
			this.getRows()
		},
	},
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.bg-info-light {
	background: lighten($color: $blue, $amount: 25%);
	color: $dark;
}

.bg-success-light {
	background: lighten($color: $green, $amount: 25%);
	color: $dark;
}

.bg-danger-light {
	background: lighten($color: $red, $amount: 25%);
	color: $dark;
}
</style>

<style lang="scss" scoped>
.vue-star-rating {
	display: flex !important;
	align-items: center !important;
	justify-content: center;
	.vue-star-rating-rating-text {
		display: none;
	}
}
.star-score {
	color: var(--primary);
	margin-top: -5px;
}
</style>
