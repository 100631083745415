<template>
	<div class="tree-legend" :class="{ open: isOpenLegend }">
		<div class="tree-legend__section treel-roosters">
			<span
				v-for="(specimen, i) in ['Ejemplar Activo', 'Ejemplar Inactivo']"
				:key="i"
				:class="computedCss(specimen)"
				class="cts-rooster"
			>
				<img :src="roosterAvatar" alt="" />
				<span>{{ specimen }}</span>
			</span>
		</div>
		<div class="tree-legend__section mained">
			<span
				v-for="(legend, i) in legends"
				:key="i"
				v-if="!(isInvitado && ['Eliminar', 'Agregar', 'Editar','Actualizar Padrillo/Madrilla'].includes(legend.title))"
				class="cts-legend"
			>
				<svg
					:width="legend.icon.width"
					:height="legend.icon.height"
					:viewBox="`0 0 ${legend.icon.width} ${legend.icon.height}`"
				>
					<path :d="legend.icon.path" :transform="legend.icon.transform || ''" fill="currentColor" />
				</svg>
				<span>{{ legend.title }}</span>
			</span>
		</div>
		<div class="tree-legend__section treel-middle">
			<span v-for="(gender, i) in genders" :key="i" class="cts-legend">
				<svg
					:width="gender.icon.width"
					:height="gender.icon.height"
					:viewBox="`0 0 ${gender.icon.width} ${gender.icon.height}`"
					v-html="gender.icon.path"
				/>
				<span>{{ gender.title }}</span>
			</span>
		</div>
		<div class="tree-legend__section treel-middle">
			<span class="cts-legend">
				<svg width="14" height="17" fill="none" viewBox="0 0 14 17" class="spec-affected s-not">
					<path
						fill="currentColor"
						d="M0 4.799c3.385 2.494 6.918 2.88 10.705 1.094v1.801c-.484.037-.975.03-1.436.124-1.324.292-2.373 1.386-2.485 2.954-.03.386.238.693.588.714.35.022.632-.248.67-.634.118-1.24.907-1.94 2.187-1.947h1.004c.513 0 .744-.219.744-.73.007-1.13 0-2.26.007-3.39 0-.49.335-.898.781-.978.513-.095.997.146 1.175.613.053.145.067.306.067.459.008 1.13-.007 2.26.008 3.391a4.88 4.88 0 0 1-.722 2.633c-.454.737-.84 1.48-.9 2.363H1.963c-.044-.175-.059-.365-.133-.525-.238-.518-.446-1.05-.759-1.524C.41 10.225.007 9.175.015 7.979.015 6.943 0 5.9 0 4.799Z"
					/>
					<path
						fill="currentColor"
						d="M6.182 0c1.13 0 2.254-.007 3.384 0 1.37.007 2.44.853 2.753 2.159.067.299.074.496-.298.642-.617.24-.982.743-1.212 1.356a.945.945 0 0 1-.357.452 8.346 8.346 0 0 1-9.143-.394 6.501 6.501 0 0 1-1.026-.933c-.305-.336-.328-.759-.216-1.203C.394.839 1.443.015 2.767 0h3.415Zm1.004 17H2.351c-.833 0-1.37-.489-1.384-1.24-.007-.773.536-1.305 1.369-1.305 3.213-.008 6.42 0 9.633 0 .848 0 1.369.496 1.369 1.29 0 .781-.506 1.255-1.354 1.255H7.186Z"
					/>
				</svg>
				<span>Afectado</span>
			</span>
			<span class="cts-legend">
				<svg width="14" height="17" fill="none" viewBox="0 0 14 17" class="spec-owner s-not">
					<path
						fill="currentColor"
						d="M0 4.799c3.385 2.494 6.918 2.88 10.705 1.094v1.801c-.484.037-.975.03-1.436.124-1.324.292-2.373 1.386-2.485 2.954-.03.386.238.693.588.714.35.022.632-.248.67-.634.118-1.24.907-1.94 2.187-1.947h1.004c.513 0 .744-.219.744-.73.007-1.13 0-2.26.007-3.39 0-.49.335-.898.781-.978.513-.095.997.146 1.175.613.053.145.067.306.067.459.008 1.13-.007 2.26.008 3.391a4.88 4.88 0 0 1-.722 2.633c-.454.737-.84 1.48-.9 2.363H1.963c-.044-.175-.059-.365-.133-.525-.238-.518-.446-1.05-.759-1.524C.41 10.225.007 9.175.015 7.979.015 6.943 0 5.9 0 4.799Z"
					/>
					<path
						fill="currentColor"
						d="M6.182 0c1.13 0 2.254-.007 3.384 0 1.37.007 2.44.853 2.753 2.159.067.299.074.496-.298.642-.617.24-.982.743-1.212 1.356a.945.945 0 0 1-.357.452 8.346 8.346 0 0 1-9.143-.394 6.501 6.501 0 0 1-1.026-.933c-.305-.336-.328-.759-.216-1.203C.394.839 1.443.015 2.767 0h3.415Zm1.004 17H2.351c-.833 0-1.37-.489-1.384-1.24-.007-.773.536-1.305 1.369-1.305 3.213-.008 6.42 0 9.633 0 .848 0 1.369.496 1.369 1.29 0 .781-.506 1.255-1.354 1.255H7.186Z"
					/>
				</svg>
				<span>Corrido</span>
			</span>
		</div>
		<button class="tree-legend__toogler text-white" @click="openLegend">
			<span>LEYENDA</span>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
				<path
					fill="currentColor"
					d="m12 15.586-4.293-4.293-1.414 1.414L12 18.414l5.707-5.707-1.414-1.414L12 15.586Z"
				/>
				<path
					fill="currentColor"
					d="m17.707 7.707-1.414-1.414L12 10.586 7.707 6.293 6.293 7.707 12 13.414l5.707-5.707Z"
				/>
			</svg>
		</button>
	</div>
</template>

<script>
import { config, legends, genders } from "./d3-tree/config";
export default {
	name: "TreeLegendCard",
	mounted() {
		if (matchMedia("(min-width: 70em)").matches) {
			this.isOpenLegend = true;
		}
	},
	data: () => ({
		isOpenLegend: false,
		transformPending: true,
		config,
		legends,
		genders,
		roosterAvatar: require("@/assets/images/default-avatar.png"),
	}),
	computed: {
		computedCss: () => (e) => String(e).replace(" ", "-").toLowerCase(),
	},
	methods: {
		openLegend() {
			this.isOpenLegend = !this.isOpenLegend;
		},
	},
};
</script>

<style lang="scss" scoped>
.tree-legend {
	background-color: var(--dark-hard);
	box-shadow: 0 0 0 1px #0000001a, 0 3px 16px #00000014, 0 2px 6px 1px #00000017;
	border-radius: 1rem;
	padding: 1.5rem 1.8rem;
	color: var(--primary);
	display: flex;
	position: relative;
	transform: translateY(calc(100% + 1.5rem));
	transition: transform 500ms ease-in-out;
	pointer-events: auto;
	flex-direction: column;
	gap: 1rem;
	&.open {
		transform: translateY(0);
	}
	&__section {
		display: flex;
		flex-wrap: wrap;
		max-width: 535px;
		gap: 1rem;
		align-items: center;
	}
	&__section.treel-middle {
		flex-direction: column;
	}
	&__section.treel-last {
		align-self: flex-end;
	}
	&__section.treel-roosters {
		align-items: center;
	}

	&__actions {
		display: flex;
		gap: 1rem;
	}

	&__toogler {
		font: normal 400 20px/1 Rubik;
		color: var(--darken);
		position: absolute;
		bottom: calc(100% + 1rem);
		left: 0.6rem;
		background-color: var(--primary);
		border: none;
		outline: none;
		padding: 0.5em 1em;
		display: inline-flex;
		align-items: center;
		border-radius: 0.42rem;
		column-gap: 0.4em;
		box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
		svg {
			transform: rotate(180deg);
			transition: transform 500ms ease-in-out;
		}
		.open & svg {
			transform: rotate(0deg);
		}
	}
}
.cts-legend {
	display: inline-flex;
	align-items: center;
	padding: 0.7rem 1rem;
	border-radius: 99999px;
	background-color: var(--dark-alpha);
	min-width: 12ch;
	svg {
		margin-right: 0.5rem;
	}
	svg:not(.s-not) {
		color: var(--primary);
	}
	span {
		font: normal 400 15px/1 Rubik, sans-serif;
	}
	.tree-legend__section.mained & {
		min-width: 15ch;
	}
}
.cts-rooster {
	padding-right: 1rem;
	img {
		border-radius: 9999px;
		aspect-ratio: 1/1;
	}
	&.ejemplar-inactivo img {
		filter: grayscale(1);
	}
	span {
		font: normal 400 17px /1 Rubik, sans-serif;
		color: #6d6d6d;
		margin-left: 1rem;
	}
	.dark-layout & {
		span {
			color: #b6b6b6;
		}
	}
}
@media screen and (min-width: 1200px) {
	.tree-legend {
		flex-direction: row;
	}
}
@media screen and (min-width: 1450px) {
	.tree-legend {
		&__section {
			max-width: 535px;
			&:not(:last-child) {
				padding-right: 1rem;
			}
		}
	}
}
@media screen and (max-width: 780px) {
	.cts-legend {
		width: 100%;
	}
	.tree-legend {
		&__section.treel-last {
			align-self: initial;
		}
	}
}
</style>
