<template>
	<b-modal
		hide-footer
		centered
		size="lg"
		:title="`Tracking de fertilidad de ${info.alias || info.plate}`"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-table-simple small sticky-header="10rem">
				<b-thead class="text-center">
					<b-tr>
						<b-th class="bg-transparent border-0" colspan="1"></b-th>
						<b-th class="box-primary text-white" colspan="2">Huevos</b-th>
					</b-tr>
					<b-tr>
						<b-th>Temporada</b-th>
						<b-th>Ingresados</b-th>
						<b-th>Infertiles</b-th>
						<b-th>Promedio</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, i) in rows" :key="i">
						<b-td>{{ row.season }}</b-td>
						<b-td>{{ row.ingresados }}</b-td>
						<b-td>{{ row.infertiles }}</b-td>
						<b-td>{{ row.average_fertilidad }}%</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import specimensService from "@/services/specimens.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await specimensService.trackingFertility({ specimen_id: this.info.id })
			this.rows = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
