<template>
	<b-modal
		size="xmd"
		id="modal-specimen-tree-update"
		:title="modalTitle"
		ref="modal-specimen-tree-update"
		hide-footer
		@hidden="$emit('hidden')"
		no-close-on-backdrop
	>
		<ValidationObserver ref="form">
			<b-form @submit.prevent="updateSpecimen" class="py-1">
				<b-row cols="12" tag="section">
					<b-col lg="3">
						<SelectableImage v-model="currentSpecimen.photo" />
					</b-col>
					<b-col lg="9">
						<b-row cols="12" class="py-1">
							<b-col cols="12">
								<h3 class="text-primary mb-1" style="font-weight: 600">Detalles del ejemplar</h3>
							</b-col>
							<b-col cols="12" class="col-lg">
								<b-form-group label="Origen:">
									<v-select
										:options="origins"
										label="name"
										v-model="currentSpecimen.origin_id"
										:reduce="(e) => e.id"
										:disabled="[1, 2].includes(currentSpecimen.origin_id)"
									/>
								</b-form-group>

								<ValidationProvider :rules=" currentSpecimen.inside == 'SI' ? 'required' : ''" v-slot="{ errors }">
									<b-form-group label="Placa:" label-for="tsa-specimen-plate">
										<b-form-input
											id="tsa-specimen-plate"
											v-model="currentSpecimen.plate"
											type="text"
											placeholder="----"
											@input="
												(val) => (currentSpecimen.plate = currentSpecimen.plate.toUpperCase())
											"
											:state="errors[0] ? false : null"
										/>
										<span style="color: red">{{ errors[0] }}</span>
									</b-form-group>
								</ValidationProvider>

								<b-form-group label="Peso KG" label-for="tsa-specimen-weight">
									<div class="d-flex align-items-center" :style="{ position: 'relative' }">
										<div class="absolute-input">
											<p v-if="currentSpecimen.existWeight" class="mt-25 mb-0">
												{{ currentSpecimen.weight }}
											</p>
											<FeatherIcon
												class="mx-1 cursor-pointer"
												icon="PlusIcon"
												size="18"
												@click="currentSpecimenEdit.weight = true"
											/>
											<FeatherIcon
												@click="openModalWeightTracking"
												class="cursor-pointer"
												icon="ListIcon"
												size="18"
											/>
										</div>
									</div>
								</b-form-group>
							</b-col>
							<b-col cols="12" class="col-lg">
								<b-form-group label="Categoria:">
									<v-select
										:options="filterCategories"
										label="description"
										v-model="currentSpecimen.category_id"
										:reduce="(e) => e.id"
										disabled
									/>
								</b-form-group>
								<b-form-group label="Placa adicional:" label-for="tsa-specimen-add-plate">
									<b-form-input
										id="tsa-specimen-add-plate"
										v-model="currentSpecimen.additional_plate"
										type="text"
										placeholder="----"
									/>
								</b-form-group>
							</b-col>
							<b-col cols="12" class="col-lg">
								<b-form-group label="Genero" label-for="tsa-specimen-gender">
									<b-form-input
										id="tsa-specimen-gender"
										v-model="currentSpecimen.gender"
										type="text"
										placeholder="----"
										disabled
									/>
								</b-form-group>
								<div class="form-group">
									<!--  -->
									<label for="tsa-datepicker-placeholder"> Nacimiento </label>
									<b-form-datepicker
										:disabled="currentSpecimen.chick_id"
										id="tsa-datepicker-placeholder"
										placeholder="----"
										locale="es"
										:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
										v-model="currentSpecimen.dob"
									/>
								</div>
								<b-form-group label="Color:">
									<v-select
										:options="colors"
										label="color"
										v-model="currentSpecimen.color_id"
										:reduce="(e) => e.id"
									/>
								</b-form-group>
							</b-col>
							<b-col cols="12" class="col-lg-5">
								<b-row cols="12">
									<b-col col>
										<b-form-group label="Estado:" label-for="tsa-specimen-status">
											<v-select
												id="tsa-specimen-status"
												:options="status"
												label="description"
												disabled
												v-model="currentSpecimen.status_id"
												:reduce="(e) => e.id"
											/>
										</b-form-group>
									</b-col>
									<b-col col>
										<b-form-group label="Alias:" label-for="tsa-specimen-alias">
											<b-form-input
												id="tsa-specimen-alias"
												v-model="currentSpecimen.alias"
												type="text"
												placeholder="----"
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row cols="12">
									<b-col class="w-full">
										<div class="form-group">
											<label for="tsa-mom-obs">Observación:</label>
											<b-form-textarea
												id="tsa-mom-obs"
												v-model="currentSpecimen.observations"
												placeholder="Escribe algo..."
												rows="3"
												max-rows="6"
											/>
										</div>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="12" class="d-flex gap-x-2 pt-1">
								<b-button variant="primary" type="submit">Guardar</b-button>
								<b-button variant="outline-primary" type="button" @click="resetForm">
									Resetear
								</b-button>
							</b-col>
						</b-row>
						<span class="text-primary cross-center gap-x-1 sp-cool-water" v-if="inside == 'SI'">
							<svg width="18" height="18" viewBox="0 0 16 16">
								<path
									d="M15.505 6.908c-2.154-2.15-4.303-4.305-6.457-6.455-.612-.607-1.48-.601-2.098 0-.346.335-.686.68-1.026 1.021C4.1 3.3 2.274 5.121.454 6.954c-.528.532-.602 1.298-.188 1.888.34.482.822.67 1.44.64v4.676c0 1.111.737 1.843 1.848 1.843H5.85c.046 0 .091 0 .13-.006H10a.72.72 0 0 0 .114.006h2.268c.062 0 .119 0 .18-.006h.126v-.011c1.003-.114 1.598-.805 1.598-1.889V9.49c.085-.006.12-.012.16-.012.668-.005 1.173-.3 1.428-.919.26-.618.107-1.174-.369-1.65Zm-4.422 3.397-.618.193v1.98a7.768 7.768 0 0 1-1.287.402c-.42.085-.805.13-1.168.13-.618 0-1.156-.13-1.621-.385A2.694 2.694 0 0 1 5.3 11.48c-.26-.504-.385-1.123-.385-1.854 0-.726.125-1.35.38-1.866A2.685 2.685 0 0 1 6.4 6.579c.482-.272 1.06-.409 1.74-.409.375 0 .76.04 1.152.125.397.085.8.21 1.207.38l-.102 1.776h-.952l-.227-.908a4.486 4.486 0 0 0-.374-.09 2.798 2.798 0 0 0-.556-.057c-.34 0-.635.079-.879.238-.243.159-.43.397-.56.726-.126.323-.194.732-.194 1.23 0 .681.125 1.214.38 1.606.255.391.647.59 1.168.59.136 0 .284-.017.442-.046.102-.017.204-.045.312-.073v-1.175l-.623-.147v-.828l2.744-.045v.833h.005Z"
									fill="currentColor"
								/>
							</svg>
							<span class="font-weight-bold">Pertenece al galpon</span>
						</span>
					</b-col>
				</b-row>
			</b-form>
		</ValidationObserver>

		<AddSpecimenWeight
			v-if="currentSpecimenEdit.weight"
			@hidden="currentSpecimenEdit.weight = false"
			:specimen-obj="currentSpecimen"
			@update-specimen-weight="updateSpecimenWeight"
		></AddSpecimenWeight>

		<specimen-weight-tracking-modal
			v-if="specimenWeightListController"
			@hidden="specimenWeightListController = false"
			:specimen-obj="currentSpecimen"
			@update-specimen-weight="updateSpecimenOfTrackging"
		/>
	</b-modal>
</template>

<script>
import SelectableImage from "@/views/amg/specimens/components/SelectableImage.vue";
import { mapState, mapActions } from "vuex";
import pedigreeService from "@/services/pedigree.service";
import modalMixin from "@/mixins/modal";
import specimenService from "@/services/specimens.service";
import AddSpecimenWeight from "../../specimens-weight/AddSpecimenWeight.vue";
import SpecimenWeightTrackingModal from "../../specimens-weight/SpecimenWeightTrackingModal.vue";
export default {
	name: "ModalUpdateSpecimen",
	components: {
		SelectableImage,
		AddSpecimenWeight,
		SpecimenWeightTrackingModal,
	},
	props: {
		specimenId: [Number, String],
	},
	mixins: [modalMixin],
	async mounted() {
		this.isPreloading();
		const response = await pedigreeService.fetchSpecimenForUpdate(this.specimenId);
		this.prepareForUpdate(response);
		this.toggleModal("modal-specimen-tree-update");
		this.status = await specimenService.getAllStatusSpecimens();

		await this.A_GET_CATEGORIES_SPECIMENS({
			specimen_id: this.specimenId,
		});
		this.isPreloading(false);
	},
	data: () => ({
		isOpenModal: false,
		currentGender: null,
		inside: "NO",
		status: [],
		original_weight: null,
		currentSpecimen: {
			origin_id: null,
			category_id: null,
			status_id: null,
			color_id: null,
			gender: null,
			alias: null,
			plate: null,
			aditional_plate: null,
			dob: null,
			weight: null,
			pound_pound_weight: null,
			observations: null,
			photo: null,
			id: null,
			chick_id: null,
			gender_id: null,
		},
		currentSpecimenCopy: {},
		currentSpecimenEdit: {
			weight: false,
		},
		specimenWeightListController: false,
	}),
	computed: {
		...mapState({
			categories: (state) => state.createEditSpecimen.S_CATEGORIES_SPECIMENS,
			colors: (state) => state.createEditSpecimen.S_COLORS_SPECIMENS,
			origins: (state) => state.createEditSpecimen.S_ORIGINS_SPECIMENS,
			genders: (state) => state.createEditSpecimen.S_GENDERS_SPECIMENS,
		}),
		modalTitle() {
			return `Actualizar ${this.currentSpecimen.alias || "Sin alias"} / (${
				this.currentSpecimen.plate || "Sin placa"
			})`;
		},
		computePound() {
			if (this.currentSpecimen.weight) {
				return Number(this.currentSpecimen.weight * 2.205).toFixed(2);
			}
			return null;
		},
		filterCategories() {
			return this.categories;
		},
	},
	methods: {
		...mapActions("createEditSpecimen", ["A_GET_CATEGORIES_SPECIMENS", "A_GET_COLORS_SPECIMENS"]),
		/** @param { Object<string, string|number> } specimen */
		prepareForUpdate(specimen) {
			this.currentSpecimen = {
				origin_id: specimen.origin_id,
				category_id: specimen.category_id,
				status_id: specimen.status_id,
				color_id: specimen.color_id,
				gender: specimen.gender,
				alias: specimen.alias,
				plate: specimen.plate,
				additional_plate: specimen.additional_plate,
				dob: specimen.dob,
				weight: specimen.weight,
				observations: specimen.observations,
				photo: specimen.photo,
				id: this.specimenId,
				existWeight: false,
				chick_id: specimen.chick_id,
				gender_id: specimen.gender_id,
				inside: specimen.inside
			};
			this.original_weight = specimen.weight;
			this.inside = specimen.inside;
			this.currentGender = specimen.gender_id;

			this.currentSpecimenCopy = { ...this.currentSpecimen };

			if (this.currentSpecimen.weight) {
				this.currentSpecimen.existWeight = true;
			}

			this.currentSpecimen.weight = `${specimen.weight} (Kg) - ${(specimen.weight * 2.20462).toFixed(2)} (Lb)`;

			this.A_GET_COLORS_SPECIMENS(specimen.gender_id);
		},
		async updateSpecimen() {
			if (!(await this.$refs.form.validate())) return;

			const confirm = await this.showConfirmSwal({
				text: "Actualizaras el specimen",
			});

			let arraySpecimenFields = [];
			for (const key in this.currentSpecimen) {
				if (
					["color_id", "origin_id", "alias", "plate", "additional_plate", "observations", "dob"].includes(key)
				) {
					let text = null;
					if (key == "category_id") {
						text = this.categories.find((it) => it.id == this.currentSpecimen[key]).description;
					}
					console.log("ADD", key, this.currentSpecimen[key], this.currentSpecimenCopy[key]);
					if (this.currentSpecimen[key] != this.currentSpecimenCopy[key]) {
						arraySpecimenFields.push({
							specimen_id: this.specimenId,
							key: ["origin_id", "category_id", "color_id"].includes(key) ? key.slice(0, -3) : key,
							value_text: text || this.currentSpecimen[key],
							value_id: ['dob','plate'].includes(key)  ? null : this.currentSpecimen[key],
						});
					}
				}
			}

			const dataset = new FormData();
			dataset.append("photo", this.currentSpecimen.photo);
			dataset.append("specimen_id", this.specimenId);
			dataset.append("fields", JSON.stringify(arraySpecimenFields));

			if (!confirm.isConfirmed) return;

			this.isPreloading();

			try {
				const response = await pedigreeService.updateSimpleSpecimen(dataset);
				this.showToast("success", "top-right", "Felicidades", "CheckIcon", response.message);
				this.toggleModal("modal-specimen-tree-update");
				this.$emit("updated");
				this.isPreloading();
			} catch (error) {}
		},
		resetForm() {
			Object.assign(this.currentSpecimen, {
				origin_id: null,
				category_id: null,
				status_id: null,
				color_id: null,
				gender: null,
				alias: null,
				plate: null,
				additional_plate: null,
				dob: null,
				weight: null,
				observations: null,
				photo: null,
			});
		},
		specimenFactory() {
			const dataset = new FormData();
			for (const key in this.currentSpecimen) {
				dataset.append(String(key), this.currentSpecimen[key]);
			}

			dataset.append("user_id", this.currentUser.user_id);
			dataset.append("modify", this.original_weight !== this.currentSpecimen.weight);
			dataset.append("pound_weight", this.computePound);

			return dataset;
		},
		updateSpecimenWeight(specimen, weight) {
			// console.log('update', specimen, weight);
			const weight_kg = Number(weight.kg_weight).toFixed(3);
			const weight_po = Number(weight.pounds_weight).toFixed(3);
			this.currentSpecimen.weight = `${weight_kg} (Kg) - ${weight_po} (Lb)`;
			this.currentSpecimen.existWeight = true;
			// this.specimenValue.weight_counter++;
		},
		openModalWeightTracking() {
			console.log("df");
			// En caso se tenga al menos un tracking mostrar el modal
			if (this.currentSpecimen.weight) {
				this.specimenWeightListController = true;
				return;
			}
			this.showToast("info", "top-right", "Dashboard", "InfoIcon", "No existe historial de pesos.");
		},
		async updateSpecimenOfTrackging(weight_po, weight_kg) {
			// this.specimenValue.weight = `${weight_kg} (Kg) - ${weight_po} (Lb)`;
			// this.specimenValue.weight_counter++;
			this.$emit("update-status");
			const response = await pedigreeService.fetchSpecimenForUpdate(this.specimenId);
		this.prepareForUpdate(response);
		},
	},
};
</script>

<style lang="scss">
#modal-specimen-tree-update {
	.modal-dialog {
		min-width: 75vw;
	}
	.modal-header {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.modal-body {
		padding: 1rem 2rem;
	}
}
.sp-cool-water {
	position: absolute;
	right: 1.8rem;
	bottom: 0.5rem;
	font-size: 1.1em;
}
.absolute-input {
	// background: red;
	display: flex;
	margin-top: 0.5rem !important;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 340px;
	height: 100%;
	z-index: 100;
}
</style>
