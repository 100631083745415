<template>
	<b-tabs
		v-if="category_id == 1 || category_id == 2"
		content-class="mt-2"
		lazy
		nav-wrapper-class="card pt-1 pr-1 pl-1"
	>
		<b-tab title="Resultado por posturas">
			<ViewReportsTotalPostura
				v-if="plate"
				:platePadrillo="category_id == 1 ? plate : null"
				:plateMadrilla="category_id == 2 ? plate : null"
				:dashboard="true"
				:specimen_id="specimen_id"
			/>
		</b-tab>
		<b-tab title="Promedio por ejemplar">
			<ViewReportsTotalPostura
				v-if="plate && category_id"
				:category_id="category_id"
				:platePadrillo="category_id == 1 ? plate : null"
				:plateMadrilla="category_id == 2 ? plate : null"
				:dashboard="true"
				:specimen_id="specimen_id"
			/>
		</b-tab>
	</b-tabs>
</template>

<script>
import { getSonsBySpecimen } from "@/services/reports.service";
import ViewReportsTotalPostura from "../../reports/posturas/components/ViewReportsTotalPostura.vue";

export default {
	name: "SpecimensReportsSons",
	components: {
		ViewReportsTotalPostura,
	},
	data() {
		return {
			plate: null,
			category_id: null,
			specimen_id: null
		};
	},
	computed: {
		idSpecimen() {
			return this.$route.params.specimen_id;
		},
	},
	async mounted() {
		const {
			data: [specimen],
		} = await getSonsBySpecimen({
			idSpecimen: parseInt(this.idSpecimen),
		});
		console.log("DATA: ", specimen);
		this.plate = specimen.plate;
		this.category_id = specimen.category_id;
		this.specimen_id = specimen.id
	},
};
</script>
