<template>
	<div class="pb-2">
		<div v-if="!dashboard" class="mb-2 container-header">
			<div class="container-body-header">
				<div class="d-flex align-items-center">
					<p class="page-btn" :class="[pageActive == 1 ? 'disabled' : null]" @click="changePage(pageIn - 1)">
						<feather-icon icon="ChevronLeftIcon" size="20" />
					</p>
					<div class="container-btns">
						<p
							@click="changePage(num)"
							class="page-btn"
							:class="[pageActive == num ? 'active' : null]"
							v-for="(num, index) in pages"
							:key="index"
						>
							{{ num }}
						</p>
					</div>
					<p
						class="page-btn"
						:class="[pageActive == totalPages ? 'disabled' : null]"
						@click="changePage(pageIn + 1)"
					>
						<feather-icon icon="ChevronRightIcon" size="20" />
					</p>
				</div>
				<div class="container-search-header mt-2 mt-md-0">
					<input
						type="text"
						class="form-control"
						:placeholder="`Buscar por ${
							category_id == 1 ? 'padrillo' : (category_id == 2 ? 'madrilla' : 'ejemplar')
						} (alias / placa)`"
						v-model="campo"
						v-on:keyup.enter="keyEnter"
						:disabled="loadingData"
					/>
					<button @click="keyEnter" class="btn btn-primary btn-buscar-placa">Buscar placa</button>
				</div>
			</div>
		</div>

		<div class="m-auto card content-table">
			<div
				class="container-table-reports header-table header-sticky"
				:class="[dashboard ? 'grid-dashboard' : '', category_id ? 'specimen-tab' : '']"
			>
				<div class="header-table-icon p-1">
					<p>#</p>
				</div>
				<div v-if="viewPadrillo" class="header-table-icon p-1">
					<p>Padrillo</p>
				</div>
				<div v-if="viewMadrilla" class="header-table-icon p-1">
					<p>Madrilla</p>
				</div>

				<div class="header-table-icon header-hijos pt-1 pb-1">
					<div class="container-hijos-title">
						<p>Hijos vivos</p>
					</div>
					<div class="container-hijos-header">
						<div class="label">
							<p>Total</p>
						</div>
						<div class="label">
							<p>M</p>
						</div>
						<div class="label">
							<p>H</p>
						</div>
					</div>
				</div>

				<div class="header-table-icon pt-1 pb-1">
					<p>Total</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/007-gallo-galo_ippt4p.png"
						alt=""
					/>
				</div>
				<div class="header-table-icon pt-1 pb-1">
					<p>Jugadas</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/001-tweezer_gi7x2h.png"
						alt=""
					/>
				</div>
				<div class="header-table-icon pt-1 pb-1">
					<p>Victorias</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/001-exito_sptglr.png"
						alt=""
					/>
				</div>
				<div class="header-table-icon pt-1 pb-1">
					<p>Empate</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/005-sello_b1ar7t.png"
						alt=""
					/>
				</div>
				<div class="header-table-icon pt-1 pb-1">
					<p>Derrotas</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/002-perder_o39iua.png"
						alt=""
					/>
				</div>
				<div class="header-table-icon pt-1 pb-1">
					<p>Efectividad</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/004-polla_mwaaze.png"
						alt=""
					/>
				</div>
				<div class="header-table-icon pt-1 pb-1">
					<p>Puntaje</p>
					<img
						src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/001-calculator_maigky.png"
						alt=""
					/>
				</div>
			</div>

			<template v-if="data.length > 0 && loadingData == false">
				<div
					class="container-table-reports body-table"
					v-for="(item, index) in data"
					:key="index"
					:class="[dashboard ? 'grid-dashboard' : '', category_id ? 'specimen-tab' : '']"
				>
					<div class="d-flex align-items-center justify-content-center body-index pt-1 pb-1">
						<p class="number-report">{{ index + 1 }}</p>
					</div>

					<div v-if="viewPadrillo" class="d-flex align-items-center justify-content-center body-index p-1">
						<div class="container-padrillo">
							<SpecimenPlate
								:specimen="{
									plate: item.padrillo.plate,
									alias: item.padrillo.alias,
									id: item.padrillo.id,
									thumb: item.padrillo.thumb,
									image: item.padrillo.url,
								}"
							/>
						</div>
					</div>

					<div
						v-if="viewMadrilla"
						class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1"
					>
						<div class="container-padrillo">
							<SpecimenPlate
								:specimen="{
									plate: item.madrilla.plate,
									alias: item.madrilla.alias,
									id: item.madrilla.id,
									thumb: item.madrilla.thumb,
									image: item.madrilla.url,
								}"
							/>
						</div>
					</div>

					<div
						@click="getSons(item.padrillo, item.madrilla, 0)"
						class="d-flex flex-column align-items-center justify-content-center body-cell childs-specimen pt-1 pb-1"
					>
						<p class="number-report sons-dark">
							{{ item.hijos }}
						</p>
					</div>

					<div
						@click="getSons(item.padrillo, item.madrilla, 1)"
						class="d-flex flex-column align-items-center justify-content-center body-cell childs-specimen pt-1 pb-1"
					>
						<p class="number-report sons-dark sons-dark">
							{{ item.machos }}
						</p>
					</div>

					<div
						@click="getSons(item.padrillo, item.madrilla, 2)"
						class="d-flex flex-column align-items-center justify-content-center body-cell childs-specimen pt-1 pb-1"
					>
						<p class="number-report sons-dark sons-dark">
							{{ item.hembras }}
						</p>
					</div>

					<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
						<p @click="setViemEjemplares(item)" class="number-report">
							{{ item.gallos_peleas }}
						</p>
					</div>

					<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
						<p @click="selectPostura(item, null)" class="number-report">
							{{ item.peleas }}
						</p>
					</div>

					<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
						<p @click="selectPostura(item, 1)" class="number-report">
							{{ item.triunfo }}
						</p>
					</div>

					<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
						<p @click="selectPostura(item, 2)" class="number-report">
							{{ item.empate }}
						</p>
					</div>

					<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
						<p @click="selectPostura(item, 3)" class="number-report">
							{{ item.derrota }}
						</p>
					</div>

					<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
						<p class="font-weight-bolder">
							{{ `${item.efectividad}%` }}
						</p>
					</div>

					<div class="d-flex align-items-center justify-content-center body-cell pt-1 pb-1">
						<p class="font-weight-bolder">
							{{ `${item.puntaje == 0 ? item.puntaje : `+${item.puntaje}`}` }}
						</p>
					</div>

					<!-- <div class="d-flex align-items-center justify-content-center body-cell pt-1 pb-1">
						<feather-icon
							class="cursor-pointer icon-eye"
							icon="EyeIcon"
							color="#28A745"
							:size="width < 600 ? '16' : '20'"
							@click="selectPostura(item, 2)"
						/>
						<smart-icon
							@click="toggleFavorite(item.padrillo.id, item.madrilla.id, index)"
							v-if="item.favorite"
							color="#ff9f43"
							class="cursor-pointer"
							icon="Star1Icon"
							:size="width < 600 ? '18' : '24'"
						/>
						<feather-icon
							@click="toggleFavorite(item.padrillo.id, item.madrilla.id, index)"
							v-else
							class="icon text-warning cursor-pointer"
							icon="StarIcon"
							:size="width < 600 ? '16' : '20'"
						/>
					</div> -->
				</div>

				<div
					v-if="!(specimen_id && category_id)"
					class="container-table-reports body-table"
					:class="[dashboard ? 'grid-dashboard' : '']"
				>
					<div></div>
					<div v-if="(plateMadrilla || !dashboard) && (category_id == 1 || !category_id)"></div>
					<div v-if="(platePadrillo || !dashboard) && (category_id == 2 || !category_id)"></div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.hijos }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.machos }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.hembras }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.gallos_peleas }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.peleas }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.triunfo }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.empate }}</p>
					</div>
					<div class="p-1">
						<p class="number-report">{{ data[0].total.derrotas }}</p>
					</div>

					<div></div>
					<div class="p-1">
						<p class="number-report">
							{{ `+${data[0].total.puntaje}` }}
						</p>
					</div>
					<div></div>
				</div>
			</template>

			<template v-else-if="loadingData">
				<div class="data-null">
					<div class="loadingio-spinner-reload-r3yh8h7kig">
						<div class="ldio-m8h18h0i76f">
							<div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
					<p>Cargando...</p>
				</div>
			</template>

			<template v-else>
				<div class="data-null p-1">
					<p class="number-report">Sin datos encontrados</p>
				</div>
			</template>
		</div>

		<b-modal
			v-if="posturaSelect"
			:title="`Hijos de (${posturaSelect.padrillo ? posturaSelect.padrillo.plate : ''}) - (${posturaSelect.madrilla ? posturaSelect.madrilla.plate : ''})`"
			v-model="modalDetailsPostura"
			size="xl"
			body-class="p-0"
			hide-footer
			scrollable
		>
			<ViewDetailsReportPostura
				:idModalSelect="idModalSelect"
				:category_id="category_id"
				:item="posturaSelect"
				:posturaTable="false"
			/>
		</b-modal>

		<b-modal
			v-if="plateSelect"
			v-model="modalSearchPadrillo"
			hide-footer
			:title="`Buscar camada ${
				this.plateSelect.type == 'padrillo'
					? `del ${this.plateSelect.type} con placa ${this.plateSelect.plate} - ${this.plateSelect.alias}`
					: `de la ${this.plateSelect.type} con placa ${this.plateSelect.plate} - ${this.plateSelect.alias}`
			}`"
			body-class="p-0"
			size="xl"
			scrollable
		>
			<SearchPosturasByPlate :plate="plateSelect" />
		</b-modal>

		<b-modal
			:title="titleModalListSonsByPostura"
			v-model="modalListSonsByPostura"
			body-class="p-1"
			hide-footer
			scrollable
		>
			<b-tabs content-class="mt-1" v-model="TabSonsPosturaIndex">
				<b-tab title="Total hijos">
					<TabSonsPosturas
						v-if="TabSonsPosturaIndex == 0"
						:TabSonsPosturaIndex="0"
						:TabSonsPosturaMadrilla="TabSonsPosturaMadrilla"
						:TabSonsPosturaPadrillo="TabSonsPosturaPadrillo"
					/>
				</b-tab>
				<b-tab title="Hijos machos">
					<TabSonsPosturas
						v-if="TabSonsPosturaIndex == 1"
						:TabSonsPosturaIndex="1"
						:TabSonsPosturaMadrilla="TabSonsPosturaMadrilla"
						:TabSonsPosturaPadrillo="TabSonsPosturaPadrillo"
					/>
				</b-tab>
				<b-tab title="Hijos hembras">
					<TabSonsPosturas
						v-if="TabSonsPosturaIndex == 2"
						:TabSonsPosturaIndex="2"
						:TabSonsPosturaMadrilla="TabSonsPosturaMadrilla"
						:TabSonsPosturaPadrillo="TabSonsPosturaPadrillo"
					/>
				</b-tab>
			</b-tabs>
		</b-modal>

		<SlidersImageVideo
			v-if="dataSelectVideo.open"
			:open="dataSelectVideo.open"
			:url="dataSelectVideo.url"
			:text="dataSelectVideo.text"
			:text_url="dataSelectVideo.text_url"
			:type="dataSelectVideo.type"
			@refreshSlider="refreshSlider"
		/>

		<b-modal v-model="modalViewEjemplares.show" :title="modalViewEjemplares.title" hide-footer>
			<b-table-simple
				sticky-header
				class="table mb-0"
				:style="{
					minHeight:
						modalViewEjemplares.data.length < 3
							? 'auto'
							: modalViewEjemplares.data.length < 5
							? '45vh'
							: '70vh',
				}"
			>
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-1"> # </b-th>
						<b-th class="p-1"> Ejemplar </b-th>
						<b-th class="p-1"> Estado </b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in modalViewEjemplares.data" :key="index">
						<b-td class="text-center">
							{{ index + 1 }}
						</b-td>

						<b-td>
							<SpecimenPlate
								:specimen="{
									id: item.id,
									plate: item.plate,
									alias: item.alias,
									thumb: item.thumb,
									image: item.image,
								}"
							/>
						</b-td>

						<b-td class="text-center">
							{{ item.status }}
						</b-td>
					</b-tr>
					<b-tr v-if="data.length == 0">
						<b-td colspan="10" class="text-center">
							<p class="mb-0 font-weight-bold">Sin cintillos encontrados</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</div>
</template>

<script>
import { getReportsPosturas, createFavorite } from "@/services/reports.service";
import { useClipboard } from "@vueuse/core";
import { avatarText } from "@core/utils/filter";

import ViewDetailsReportPostura from "./ViewDetailsReportPostura.vue";
import SearchPosturasByPlate from "./SearchPosturasByPlate.vue";
import ViewReportsTotalPadrillo from "./ViewReportsTotalPadrillo.vue";
import ViewReportsTotalMadrilla from "./ViewReportsTotalMadrilla.vue";
import TabSonsPosturas from "./TabSonsPosturas.vue";
import ViewReportsTotalPosturaVue from "./ViewReportsTotalPostura.vue";
import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	name: "ViewReportsTotalPostura",
	setup() {
		return {
			avatarText,
		};
	},
	props: {
		dashboard: {
			type: Boolean,
			default: false,
		},
		platePadrillo: {
			type: String,
			default: null,
		},
		plateMadrilla: {
			type: String,
			default: null,
		},
		category_id: {
			type: Number,
			default: null,
		},
		specimen_id: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			data: [],
			width: this.$store.state["app"].windowWidth,
			pageIn: 1,
			perPageIn: 50,
			campo: null,
			pages: [],
			pageActive: 1,
			totalPages: null,
			modalDetailsPostura: false,
			posturaSelect: null,
			modalSearchPadrillo: false,
			plateSelect: null,
			loadingData: false,
			sidebarPadrillo: false,
			sidebarMadrilla: false,
			idModalSelect: 1,
			tabIndex: 0,
			modalListSonsByPostura: false,
			titleModalListSonsByPostura: null,
			arraySonsByPostura: [],
			TabSonsPosturaIndex: null,
			TabSonsPosturaMadrilla: null,
			TabSonsPosturaPadrillo: null,
			dataSelectVideo: {
				url: null,
				type: null,
				text: null,
				text_url: null,
				open: false,
			},
			modalViewEjemplares: {
				show: false,
				title: "Total de ejemplares",
				data: [],
			},
		};
	},
	components: {
		ViewDetailsReportPostura,
		SearchPosturasByPlate,
		ViewReportsTotalPadrillo,
		ViewReportsTotalMadrilla,
		TabSonsPosturas,
		ViewReportsTotalPosturaVue,
		SlidersImageVideo,
		SpecimenPlate,
	},
	computed: {
		getSum: () => (id, data) => {
			return data.map((item) => parseInt(item[`${id}`])).reduce((sum, a) => sum + parseInt(a), 0);
		},
		getModalDetailsPostura: () => (data) => {
			if (data == 1) {
				return "Total hijos de ";
			} else if (data == 2) {
				return "Jugadas de ";
			} else if (data == 3) {
				return "Victorias de ";
			} else if (data == 4) {
				return "Empates de ";
			} else if (data == 5) {
				return "Derrotas de ";
			}
		},
		viewPadrillo() {
			return (
				this.category_id == 1 ||
				(!this.dashboard && this.category_id == null) ||
				(this.plateMadrilla && (this.category_id == 1 || this.category_id == null))
			);
		},
		viewMadrilla() {
			return (
				this.category_id == 2 ||
				(!this.dashboard && this.category_id == null) ||
				(this.platePadrillo && (this.category_id == 2 || this.category_id == null))
			);
		},
	},
	async mounted() {
		// if (this.platePadrillo) {
		// 	this.campo = this.platePadrillo;
		// 	console.log("1", this.campo);
		// } else if (this.plateMadrilla) {
		// 	this.campo = this.plateMadrilla;
		// 	console.log("2", this.campo);
		// }
		await this.init();
		// console.log("DD:", this.data);
		// console.log("DD 123:");
	},
	methods: {
		async init() {
			// this.isPreloading();
			this.loadingData = true;

			const {
				data: {
					data: { data, current_page, last_page },
					message,
					status,
				},
			} = await getReportsPosturas({
				page: this.pageIn,
				perpage: this.perPageIn,
				search: this.campo,
				category_id: this.category_id,
				specimen_id: this.specimen_id,
			});
			this.data = data;
			this.pages = this.createPages(last_page);
			this.pageActive = current_page;
			this.totalPages = last_page;
			this.loadingData = false;
			// this.isPreloading(false);
		},
		createPages(num) {
			let pages = [];
			for (let i = 1; i <= num; i++) {
				pages.push(i);
			}
			return pages;
		},
		changePage(page) {
			if (page >= 1 && page <= this.totalPages) {
				this.pageIn = page;
				this.init();
			}
		},
		keyEnter() {
			this.pageIn = 1;
			this.init();
		},
		selectPostura(item, id) {
			this.modalDetailsPostura = true;
			this.posturaSelect = item;
			this.idModalSelect = id;
			//   console.log("item: ", item);
		},
		async toggleFavorite(id_padrillo, id_madrilla, i) {
			// console.log("RE: ", index);
			const resp = await createFavorite({
				id_padrillo,
				id_madrilla,
			});
			this.data = this.data.map((item, index) => {
				if (index == i) {
					return {
						...item,
						favorite: item.favorite ? null : "Existe",
					};
				} else {
					return item;
				}
			});
		},
		async getSons(padrillo, madrilla, gender) {
			this.TabSonsPosturaIndex = gender;
			this.TabSonsPosturaMadrilla = madrilla;
			this.TabSonsPosturaPadrillo = padrillo;
			// const { data } = await getSonsByPostura({
			//   idPadrillo: padrillo.id,
			//   idMadrilla: madrilla.id,
			//   gender
			// });
			this.modalListSonsByPostura = true;
			this.titleModalListSonsByPostura = `Hijos vivos de ${padrillo.alias ? padrillo.alias : ""} (${
				padrillo.plate
			}) - ${madrilla.alias ? madrilla.alias : ""} (${madrilla.plate})`;
			// console.log("RESP: ", data);
			// this.arraySonsByPostura = data;
		},
		refreshSlider() {
			this.dataSelectVideo.open = false;
		},
		setViemEjemplares(item) {
			this.modalViewEjemplares.show = true;
			this.modalViewEjemplares.data = item.gallos_peleas_details.filter((it) => it);
			// console.log("gallos", this.modalViewEjemplares.data);
		},
	},
};
</script>

<style lang="scss" scoped>
.container-header {
	background: #fff;
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: 0.1s all ease-in-out;
	@media screen and (min-width: 700px) {
		padding: 1rem;
	}
	.page-btn {
		height: 32px;
		width: 32px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;
		border-radius: 32px;
		cursor: pointer;
		background: #f3f2f7;
	}
	.container-btns {
		margin: 0 0.4rem;
		width: auto;
		background: #f3f2f7;
		height: 32px;
		border-radius: 16px;
		display: flex;
		align-items: center;
		transition: 0.1s all ease-in-out;
		.active {
			background: #7367f0;
			color: #fff;
		}
	}
	.disabled {
		color: #d5d5d5;
	}
}
.dark-layout {
	.container-header {
		background: #283046;
		.page-btn {
			background: #242b3d;
		}
		.container-btns {
			background: #242b3d;
			.active {
				background: #7367f0;
				color: #fff;
			}
		}
	}
}
.content-table {
	min-width: 100%;
	background: #fff;

	border-radius: 10px;
	overflow-x: scroll;
	// overflow-y: visible;
	@media screen and (min-width: 1400px) {
		overflow-x: visible;
		// overflow-y: visible;
	}
	.container-table-reports {
		min-width: 1200px !important;
		// padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 1fr 7fr 7fr 1.5fr 1.5fr 1.5fr 2fr 2fr 2fr 2fr 2fr 2.4fr 2fr;
		text-align: center;
		@media screen and (min-width: 600px) {
			// padding: 1rem 2rem;
		}

		p {
			margin: 0;
			font-size: 12px;
			transition: 0.1s all ease-in-out;
			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}
		.icon {
			//color: red;
			margin: 0 0.2rem;
			cursor: pointer;
		}
	}
	.specimen-tab {
		grid-template-columns: 1fr 7fr 1.5fr 1.5fr 1.5fr 2fr 2fr 2fr 2fr 2fr 2.4fr 2fr !important;
	}
	.grid-dashboard {
		grid-template-columns: 2fr 8fr 1.5fr 1.5fr 1.5fr 4fr 4fr 4fr 4fr 4fr 4fr 4fr !important;
	}
	.header-table {
		transition: 0.1s all ease-in-out;
		background: #f3f2f7;
		text-align: center;
		p {
			font-weight: 600;
		}
	}
	.body-table {
		border-bottom: 0.5px solid #e6e6e6;
		transition: 0.1s all ease-in-out;
		// padding: ;
		&:last-child {
			border-bottom: none;
		}
		.body-index {
			//   min-width: 30.88px;
		}
		.body-cell {
			//   min-width: 123.52px;
			p {
				word-wrap: break-word;
			}
			.badge {
				width: 80%;
				@media screen and (min-width: 600px) {
					width: 50% !important;
				}
			}
		}
	}
}
.content-table::-webkit-scrollbar {
	// width: 2px !important;
	background: #d5d1fb !important;
	height: 4px;
}
.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 2px !important;
}
.dark-layout {
	.content-table {
		background: #283046;
		.header-table {
			background: #343d55;
		}
		.body-table {
			border-bottom-color: #3b4253;
		}
	}
}
.btn-agregar-organizer {
	width: 180px;
}
.circle {
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 4px;
}
.container-body-header {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
		justify-content: space-between;
	}
}
.font-other-name {
	font-size: 12px;
	font-weight: bold;
}
.header-table-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
	}
	img {
		height: 24px;
		width: 24px;
		object-fit: cover;
	}
}
.btn-buscar-placa {
	width: 220px !important;
	margin-left: 1rem;
}
.container-padrillo {
	display: flex;
	align-items: center;
	// justify-content: space-between;
	width: 100%;
	padding: 0 1rem;
	.img-specimen {
		height: 30px;
		width: 30px;
		object-fit: cover;
		margin-right: 0.5rem;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	}
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-right: 0.5rem;
		.alias {
		}
		.plate {
			background: #fff;
			border-radius: 8px;
			display: flex;
			align-items: center;
			transition: 0.2s all ease-in-out;
			padding: 0.05rem 0.2rem !important;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			.icon {
				color: #9c97da;
				font-weight: bold;
			}
			.text-plate {
				color: #9c97da;
				font-weight: bold;
			}
			&:hover {
				transform: scale(1.05);
			}
		}
	}
}

.dark-layout {
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
		.alias {
			color: #fff;
		}
		.plate {
			.icon {
			}
			.text-plate {
			}
		}
	}
}

.number-report {
	font-weight: bold;
	transition: 0.2s all ease-in-out;
	cursor: pointer;
	&:hover {
		color: #9c97da;
	}
}
.icon-eye {
	margin-right: 0.5rem;
}
.table-1 {
	background: #ddfcdd;
}
.table-2 {
	background: #bbf4fe;
}
.table-3 {
	background: #fecccb;
}

.dark-layout {
	.table-1 {
		background: #9bb09b;
	}
	.table-2 {
		background: #83abb2;
	}
	.table-3 {
		background: #b28f8e;
	}
	.number-report {
		color: #fff !important;
	}
}

.modal-df {
	padding: 1rem !important;
}
.data-null {
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		font-size: 18px;
		font-weight: bold;
		margin: 2rem 0;
		padding-left: 1rem;
	}
}

@keyframes ldio-m8h18h0i76f {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.ldio-m8h18h0i76f > div {
	animation: ldio-m8h18h0i76f 1s infinite linear;
	transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div {
	position: absolute;
}
.ldio-m8h18h0i76f > div div:nth-child(1),
.ldio-m8h18h0i76f > div div:nth-child(2) {
	width: 84px;
	height: 84px;
	border: 14px solid;
	border-radius: 50%;
	border-color: transparent #7367f0 #7367f0 #7367f0;
	box-sizing: border-box;
	position: absolute;
	transform: rotate(45deg);
	transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div:nth-child(1) {
	transform: rotate(45deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(2) {
	transform: rotate(0deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(3) {
	width: 0;
	height: 0;
	border: 12px solid;
	border-color: transparent transparent transparent #7367f0;
	transform: translate(50px, 2px);
}
.loadingio-spinner-reload-r3yh8h7kig {
	width: 50px;
	height: 50px;
	display: inline-block;
	overflow: hidden;
	background: #fff;
}
.ldio-m8h18h0i76f {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.5);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-m8h18h0i76f div {
	box-sizing: content-box;
}
/* generated by https://loading.io/ */

.content-table {
	.header-sticky {
		@media screen and (min-width: 1400px) {
			top: calc(62.4px + 1.3rem);
			// top: 200px;
			// top: 50%;
			position: sticky;
			z-index: 999;
			// border-bottom: 1px solid #aba3f5;
			box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
		}
	}
	.header-sticky-mobile {
		position: fixed;
	}
}
.card-by-select-category {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 21px !important;
	.container-btn-padrillo {
		margin-left: 12px;
		p {
			transition: 0.2s all ease-in-out;
			padding: 2px 8px;
			margin-bottom: 0;

			cursor: pointer;
			color: #9c97da;
			font-weight: bold;
		}
		.line {
			height: 2px;
			width: 0%;
			background: #fff;
			transition: 0.2s all ease-in-out;
		}
	}
	.active {
		p {
			color: #7367f0;
		}
		.line {
			height: 2px;
			width: 100%;
			background: #7367f0;
		}
	}
}
.sidebar-container {
	position: fixed;
	top: 0;
	left: 0;
	height: 50%;
	background: #fff;
	z-index: 1031;
	width: 900px;
	padding: 1rem;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transform: translateX(-100%);
	transition: 0.3s all ease-in-out;
	opacity: 0;
}

.madrilla {
	top: 50%;
}
.active-sidebar {
	opacity: 1;
	transform: translateX(0);
}
.search-plate {
	display: flex;
	align-items: center;
	margin-left: 1rem;
	.form-control {
		height: 2rem;
	}
	.icon {
		margin-left: 0.5rem;
	}
}

@mixin magic-border($width, $color, $duration, $direction) {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: calc(100% + #{$width * 2});
		height: calc(100% + #{$width * 2});
		top: calc(#{$width}/ -1);
		left: calc(#{$width}/ -1);
		background: linear-gradient(to right, $color 0%, $color 100%),
			linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%),
			linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
		background-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
		background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
		background-repeat: no-repeat, no-repeat;
		transition: transform $duration ease-in-out, background-position $duration ease-in-out,
			background-size $duration ease-in-out;
		transform: scaleX(0) rotate(180deg * $direction);
		transition-delay: $duration * 2, $duration, 0s;
	}
	&:hover {
		&:before {
			background-size: 200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
			background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
			transform: scaleX(1) rotate(180deg * $direction);
			transition-delay: 0s, $duration, $duration * 2;
		}
	}
}

@mixin magic-border-mask($width, $duration, $background, $direction) {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: calc(100% + #{$width * 2});
		height: calc(100% + #{$width * 2});
		top: calc(#{$width}/ -1);
		left: calc(#{$width}/ -1);
		mask: linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to top, #000 50%, transparent 50%),
			linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to right, #000 0%, #000 100%),
			linear-gradient(to left, #000 0%, #000 100%);
		mask-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
		mask-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
		mask-repeat: no-repeat, no-repeat;
		transition: transform $duration ease-in-out, mask-position $duration ease-in-out,
			mask-size $duration ease-in-out;
		transform: scaleX(0) rotate(180deg * $direction);
		transition-delay: $duration * 2, $duration, 0s;
		background: $background;
	}
	&:hover {
		&:before {
			mask-size: 200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
			mask-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
			transform: scaleX(1) rotate(180deg * $direction);
			transition-delay: 0s, $duration, $duration * 2;
		}
	}
}

body {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	height: 100vh;
	.wrap {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 450px;
		a {
			text-decoration: none;
			color: #222;
			padding: 20px;
			background: #efefef;
			text-transform: uppercase;
			text-align: center;
			@include magic-border(2px, blue, 0.3s, 0);
			font-family: "Oswald";
			margin: 20px;
			flex-grow: 1;
			cursor: pointer;
			&:nth-of-type(2) {
				@include magic-border(4px, #222, 0.2s, 1);
			}
			&:nth-of-type(3) {
				@include magic-border-mask(10px, 0.4s, linear-gradient(to bottom, #40e0d0, #ff8c00, #ff0080), 0);
				align-self: flex-end;
			}
		}
	}
}
.card-body {
	padding: 0 1.5rem 1.5rem 1.5rem !important;
}
.container-search-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
	@media screen and (min-width: 800px) {
		input {
			width: 360px;
		}
		.btn {
			max-width: 200px !important;
		}
	}
	.btn {
		width: 100% !important;
		margin-left: 0;
		margin-top: 0.5rem;
		@media screen and (min-width: 600px) {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
}
.padrillo-flex {
	width: 100%;
	justify-content: space-between;
}
.header-hijos {
	grid-column: span 3;
	.container-hijos-title {
	}
	.container-hijos-header {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.childs-specimen {
	background: #d6f8fe !important;
}
.grid-table-sons-by-posturas {
	display: grid;
	grid-template-columns: 1fr 4fr 3fr 2fr !important;
	.container-padrillo {
		padding: 0 !important;
	}
}
.dark-layout {
	.padrillo {
		display: flex;
		align-items: flex-start !important;
		justify-content: flex-start !important;
	}
}
.dark-layout {
	.sons-dark {
		color: #6e6b7b !important;
	}
}
</style>
