<template>
  <!-- v-click-outside="outside" -->
  <div class="pb-2">
    <div v-if="!dashboard" class="mb-2 pb-2 container-header">
      <div class="container-body-header">
        <div class="d-flex align-items-center">
          <p
            class="page-btn"
            :class="[pageActive == 1 ? 'disabled' : null]"
            @click="changePage(pageIn - 1)"
          >
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </p>
          <div class="container-btns py-0 px-1">
            <p
              @click="changePage(num)"
              class="page-btn"
              :class="[pageActive == num ? 'active' : null]"
              v-for="(num, index) in pages"
              :key="index"
            >
              {{ num }}
            </p>
          </div>
          <p
            class="page-btn"
            :class="[pageActive == totalPages ? 'disabled' : null]"
            @click="changePage(pageIn + 1)"
          >
            <feather-icon icon="ChevronRightIcon" size="20" />
          </p>
        </div>
        <div class="container-search-header mt-2 mt-md-0">
          <input
            type="text"
            class="form-control"
            placeholder="Buscar padrillo"
            v-model="campo"
            v-on:keyup.enter="keyEnter"
          />
          <button @click="keyEnter" class="btn btn-primary btn-buscar-placa">
            Buscar placa
          </button>
        </div>
      </div>
    </div>



    <div class="m-auto card content-table">
      <div class="container-table-reports header-table header-sticky">
        <div class="header-table-icon p-1">
          <p>#</p>
        </div>
        <div class="header-table-icon p-1">
          <p>Padrillo</p>
        </div>

        <div class="header-table-icon header-hijos">
          <div class="container-hijos-title">
            <p>Hijos vivos</p>
          </div>
          <div class="container-hijos-header">
            <div class="label">
              <p>Total</p>
            </div>
            <div class="label">
              <p>M</p>
            </div>
            <div class="label">
              <p>H</p>
            </div>
          </div>
        </div>

        <div class="header-table-icon p-1">
          <p>Total</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/007-gallo-galo_ippt4p.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Jugadas</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/001-tweezer_gi7x2h.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Victorias</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/001-exito_sptglr.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Empate</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/005-sello_b1ar7t.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Derrotas</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/002-perder_o39iua.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Efectividad</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/004-polla_mwaaze.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Puntaje</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/001-calculator_maigky.png"
            alt=""
          />
        </div>
        <div class="header-table-icon p-1">
          <p>Acciones</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/002-double-click_jbyacr.png"
            alt=""
          />
        </div>
      </div>
      

      <template v-if="data.length > 0">
        <div
          class="container-table-reports body-table"
          v-for="(item, index) in data"
          :key="index"
          :class="[
            item.total_effect >= 50 && item.results.total_effect != 'Sin jugar'
              ? 'table-1'
              : '',
            item.total_effect >= 25 &&
            item.total_effect < 50 &&
            item.results.total_effect != 'Sin jugar'
              ? 'table-2'
              : '',
            item.total_effect < 25 && item.results.total_effect != 'Sin jugar'
              ? 'table-3'
              : '',
            plateStickySearch == item.padrillo.plate ? 'sticky-plate-specimen' : ''
          ]"
        >
          <div
            class="d-flex align-items-center justify-content-center body-index p-1"
          >
            <p class="number-report">{{ index + 1 }}</p>
          </div>

          <div
            class="d-flex align-items-center justify-content-center body-index p-1"
          >
            <div class="container-padrillo">
              <SpecimenPlate
								:specimen="{
									plate: item.padrillo.plate,
									alias: item.padrillo.alias,
									id: item.padrillo.id,
									thumb: item.padrillo.thumb,
									image: item.padrillo.image,
								}"
							/>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell childs-specimen p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="getSons(item.padrillo, 0)" class="number-report sons-dark">
                {{ item.h_total }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="dataCompare && item.h_total - dataCompare.h_total > 0"
                  v-b-tooltip.hover
                  :title="`+${item.h_total - dataCompare.h_total}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="dataCompare && item.h_total - dataCompare.h_total == 0"
                  v-b-tooltip.hover
                  :title="item.h_total - dataCompare.h_total"
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare && item.h_total - dataCompare.h_total < 0
                  "
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell childs-specimen p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="getSons(item.padrillo, 1)" class="number-report sons-dark">
                {{ item.h_machos }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="dataCompare && item.h_machos - dataCompare.h_machos > 0"
                  v-b-tooltip.hover
                  :title="`+${item.h_machos - dataCompare.h_machos}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare && item.h_machos - dataCompare.h_machos == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare && item.h_machos - dataCompare.h_machos < 0
                  "
                  v-b-tooltip.hover
                  :title="item.h_machos - dataCompare.h_machos"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell childs-specimen p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="getSons(item.padrillo, 2)" class="number-report sons-dark">
                {{ item.h_hembras }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="
                    dataCompare && item.h_hembras - dataCompare.h_hembras > 0
                  "
                  v-b-tooltip.hover
                  :title="`+${item.h_hembras - dataCompare.h_hembras}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare && item.h_hembras - dataCompare.h_hembras == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare && item.h_hembras - dataCompare.h_hembras < 0
                  "
                  v-b-tooltip.hover
                  :title="item.h_hembras - dataCompare.h_hembras"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="selectPostura(item, 1)" class="number-report">
                {{ item.count }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="dataCompare && item.count - dataCompare.count > 0"
                  v-b-tooltip.hover
                  :title="`+${item.count - dataCompare.count}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="dataCompare && item.count - dataCompare.count == 0"
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="dataCompare && item.count - dataCompare.count < 0"
                  v-b-tooltip.hover
                  :title="item.count - dataCompare.count"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="selectPostura(item, 2)" class="number-report">
                {{ item.results.plays ? item.results.plays : '' }}
              </p>
              <template v-if="plateStickySearch && dataCompare">
                <feather-icon
                  v-if="
                    (dataCompare && item.results.plays) &&
                      item.results.plays - dataCompare.results.plays > 0
                  "
                  v-b-tooltip.hover
                  :title="`+${item.results.plays && (item.results.plays - dataCompare.results.plays)}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    (dataCompare && item.results.plays) &&
                      item.results.plays - dataCompare.results.plays == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    (dataCompare) &&
                      item.results.plays - dataCompare.results.plays < 0
                  "
                  v-b-tooltip.hover
                  :title="item.results.plays - dataCompare.results.plays"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="selectPostura(item, 3)" class="number-report">
                {{ item.results.victories }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.victories - dataCompare.results.victories > 0
                  "
                  v-b-tooltip.hover
                  :title="
                    `+${item.results.victories - dataCompare.results.victories}`
                  "
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.victories - dataCompare.results.victories ==
                        0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare &&
                      item.results.victories - dataCompare.results.victories < 0
                  "
                  v-b-tooltip.hover
                  :title="
                    item.results.victories - dataCompare.results.victories
                  "
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="selectPostura(item, 4)" class="number-report">
                {{ item.results.draws }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.draws - dataCompare.results.draws > 0
                  "
                  v-b-tooltip.hover
                  :title="`+${item.results.draws - dataCompare.results.draws}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.draws - dataCompare.results.draws == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare &&
                      item.results.draws - dataCompare.results.draws < 0
                  "
                  v-b-tooltip.hover
                  :title="item.results.draws - dataCompare.results.draws"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p @click="selectPostura(item, 5)" class="number-report">
                {{ item.results.defeats }}
              </p>
              <template v-if="plateStickySearch">
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.defeats - dataCompare.results.defeats > 0
                  "
                  v-b-tooltip.hover
                  :title="
                    `+${item.results.defeats - dataCompare.results.defeats}`
                  "
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.defeats - dataCompare.results.defeats == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare &&
                      item.results.defeats - dataCompare.results.defeats < 0
                  "
                  v-b-tooltip.hover
                  :title="item.results.defeats - dataCompare.results.defeats"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p class="number-report">
                {{ item.results.total_effect }}
              </p>
              <template
                v-if="plateStickySearch && item.results.total_effect != 'Sin jugar'"
              >
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.total_effect - dataCompare.total_effect > 0
                  "
                  v-b-tooltip.hover
                  :title="`+${item.total_effect - dataCompare.total_effect}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.total_effect - dataCompare.total_effect == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare &&
                      item.total_effect - dataCompare.total_effect < 0
                  "
                  v-b-tooltip.hover
                  :title="item.total_effect - dataCompare.total_effect"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex align-items-center justify-content-center body-cell p-1"
          >
            <div class="d-flex align-items-center">
              <p class="number-report">
                {{
                  `${
                    item.results.score == 0
                      ? item.results.score
                      : `+${item.results.score}`
                  }`
                }}
              </p>
              <template
                v-if="plateStickySearch && item.results.total_effect != 'Sin jugar'"
              >
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.score - dataCompare.results.score > 0
                  "
                  v-b-tooltip.hover
                  :title="`+${item.results.score - dataCompare.results.score}`"
                  icon="ChevronsUpIcon"
                  size="16"
                  class="success-icon"
                />
                <feather-icon
                  v-if="
                    dataCompare &&
                      item.results.score - dataCompare.results.score == 0
                  "
                  icon="MinusIcon"
                  size="14"
                  color="#0991f4"
                  class="info-icon"
                />
                <feather-icon
                  v-else-if="
                    dataCompare &&
                      item.results.score - dataCompare.results.score < 0
                  "
                  v-b-tooltip.hover
                  :title="item.results.score - dataCompare.results.score"
                  icon="ChevronsDownIcon"
                  size="16"
                  class="danger-icon"
                />
              </template>
            </div>
          </div>

          <div
            class="d-flex align-items-center justify-content-center body-cell p-1"
          >
            <feather-icon
              @click="selectPostura(item, 2)"
              class="cursor-pointer icon-eye"
              icon="EyeIcon"
              color="#28A745"
              :size="width < 600 ? '16' : '20'"
            />
            <smart-icon
              @click="toggleFavorite(item.padrillo.id, index)"
              v-if="item.favorite"
              color="#ff9f43"
              class="cursor-pointer"
              icon="Star1Icon"
              :size="width < 600 ? '18' : '24'"
            />
            <feather-icon
              @click="toggleFavorite(item.padrillo.id, index)"
              v-else
              class="icon text-warning cursor-pointer"
              icon="StarIcon"
              :size="width < 600 ? '16' : '20'"
            />
          </div>
        </div>
      </template>
      <template v-else-if="loadingData">
        <div class="data-null">
          <div class="loadingio-spinner-reload-r3yh8h7kig">
            <div class="ldio-m8h18h0i76f">
              <div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <p>Cargando...</p>
        </div>
      </template>
      <template v-else>
        <div class="data-null">
          <p>Sin datos encontrados</p>
        </div>
      </template>
    </div>

    <b-modal
      v-if="posturaSelect"
      :title="
        `Reporte del padrillo ${
          posturaSelect.padrillo.alias ? posturaSelect.padrillo.alias : ''
        } (${posturaSelect.padrillo.plate ? posturaSelect.padrillo.plate : ''})`
      "
      v-model="modalDetailsPostura"
      :size="idModalSelect == 1 ? 'lg' : 'xl'"
      body-class="p-0"
      hide-footer
      scrollable
    >
      <ViewDetailsReportPostura
        :idModalSelect="idModalSelect"
        :item="posturaSelect"
        :posturaTable="false"
      />
    </b-modal>

    <b-modal
      :title="titleModalListSonsByPostura"
      v-model="modalListSonsByPostura"
      body-class="p-1"
      hide-footer
      scrollable
      size="lg"
    >
      <b-tabs content-class="mt-1" v-model="TabSonsPadrillosVue">
        <b-tab title="Total hijos">
          <TabSonsPadrillos
            v-if="TabSonsPadrillosVue == 0"
            :TabSonsPadrillosVue="0"
            :TabSonsPadrilloId="TabSonsPadrilloId"
          />
        </b-tab>
        <b-tab title="Hijos machos">
          <TabSonsPadrillos
            v-if="TabSonsPadrillosVue == 1"
            :TabSonsPadrillosVue="1"
            :TabSonsPadrilloId="TabSonsPadrilloId"
          />
        </b-tab>
        <b-tab title="Hijos hembras">
          <TabSonsPadrillos
            v-if="TabSonsPadrillosVue == 2"
            :TabSonsPadrillosVue="2"
            :TabSonsPadrilloId="TabSonsPadrilloId"
          />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  getReportsPosturasPadrillo,
  createFavoriteSpecimen
} from "@/services/reports.service.js";
import ViewDetailsReportPostura from "./ViewDetailsReportPostura.vue";
import { avatarText } from "@core/utils/filter";
import TabSonsPadrillos from "./TabSonsPadrillos.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
  name: "ViewReportsTotalPadrillo",
  setup() {
    return {
      avatarText
    };
  },
  props: {
    dashboard: {
      type: Boolean,
      default: false
    },
    platePadrillo: {
      type: String,
      default: null
    },
    plateSticky: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      data: [],
      width: this.$store.state["app"].windowWidth,
      pageIn: 1,
      perPageIn: 50,
      campo: null,
      pages: [],
      pageActive: 1,
      totalPages: null,
      modalDetailsPostura: false,
      posturaSelect: null,
      modalSearchPadrillo: false,
      plateSelect: null,
      loadingData: false,
      sidebarPadrillo: false,
      sidebarMadrilla: false,
      idModalSelect: 1,
      modalListSonsByPostura: false,
      titleModalListSonsByPostura: null,
      arraySonsByPostura: [],
      TabSonsPadrillosVue: null,
      TabSonsPadrilloId: null,
      dataCompare: []
    };
  },
  components: {
    ViewDetailsReportPostura,
    TabSonsPadrillos,
    SpecimenPlate
  },
  async mounted() {
    this.plateStickySearch = this.plateSticky;
    this.campo = this.platePadrillo;
    await this.init();
    this.loadingData = false;
    // console.log("DATa: ", this.data, this.plateStickySearch);
  },
  methods: {
    async init() {
      this.loadingData = true;
      const {
        data: { data, last_page, current_page }
      } = await getReportsPosturasPadrillo({
        pageIn: this.pageIn,
        perPAgeIn: this.perPageIn,
        orderbyIn: "id",
        ordenIn: "desc",
        campoIn: this.campo
      });

      this.data = data.map(item => {
        return {
          ...item,
          total_effect: parseFloat(item.total_effect)
        };
      });
      this.data = data.map(item => {
        let dat = item.data;
        let data = dat
          .map(it => {
            return {
              ...it,
              resultInt:
                it.result == "TRIUNFO" ? 1 : it.result == "EMPATE" ? 2 : 3
            };
          })
          .sort((a, b) => {
            if (a.resultInt > b.resultInt) {
              return 1;
            }else {
              return -1
            }
          });
        return {
          ...item,
          data: data
        };
      });
      // console.log("RESP: ", this.data);

      if (this.plateStickySearch) {
        this.data.forEach((item, key) => {
          if (item.padrillo.plate == this.plateStickySearch) {
            this.dataCompare = item;
          }
        });
        if(this.dataCompare.length == 0) {
          this.plateStickySearch = null;
        }
      }

      this.pages = this.createPages(last_page);
      this.pageActive = current_page;
      this.totalPages = last_page;
      this.loadingData = false;
      console.log('data', this.data);
    },
    createPages(num) {
      let pages = [];
      for (let i = 1; i <= num; i++) {
        pages.push(i);
      }
      return pages;
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.pageIn = page;
        this.init();
      }
    },
    keyEnter() {
      this.pageIn = 1;
      this.init();
    },
    selectPostura(item, id) {
      this.modalDetailsPostura = true;
      this.posturaSelect = item;
      this.idModalSelect = id;
      //   console.log("item: ", item);
    },
    selectPadrillo(item, type, alias) {
      this.modalSearchPadrillo = true;
      this.plateSelect = Object.assign(
        {},
        {
          plate: item,
          type,
          alias
        }
      );
      // console.log("Item: ", item);
    },
    //selectPadrillo
    doCopy(message) {
      const { copy } = useClipboard();

      copy(message);
    },
    outside: function(e) {
      this.$emit("closeSidebarPadrillo");
    },
    async toggleFavorite(id, i) {
      const resp = await createFavoriteSpecimen({
        id
      });
      //   console.log('resp: ', this.data);
      this.data = this.data.map((item, index) => {
        if (index == i) {
          return {
            ...item,
            favorite: item.favorite ? null : "Existe"
          };
        } else {
          return item;
        }
      });
    },
    async getSons(padrillo, gender) {
      this.TabSonsPadrillosVue = gender;
      this.TabSonsPadrilloId = padrillo;
      // const { data } = await getSonsByPadrillo({
      //   idPadrillo: padrillo.id,
      //   gender
      // });
      this.modalListSonsByPostura = true;
      this.titleModalListSonsByPostura = `Hijos vivos de ${
        padrillo.alias ? padrillo.alias : ""
      } (${padrillo.plate})`;
      // console.log("RESP data: ", data);
      // this.arraySonsByPostura = data;
    },
    linkSpecimen(id) {
      window.open(`/ejemplares/detalle/${id}`, "_blank");
    }
  },
  directives: {
    "click-outside": {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener("click", handler);
      },

      unbind: function(el, binding) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-header {
  background: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: 0.1s all ease-in-out;
  @media screen and (min-width: 700px) {
    padding: 1rem;
  }
  .page-btn {
    height: 32px;
    width: 32px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
    border-radius: 32px;
    cursor: pointer;
    background: #f3f2f7;
  }
  .container-btns {
    margin: 0 0.4rem;
    width: auto;
    background: #f3f2f7;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    transition: 0.1s all ease-in-out;
    .active {
      background: #7367f0;
      color: #fff;
    }
  }
  .disabled {
    color: #d5d5d5;
  }
}
.dark-layout {
  .container-header {
    background: #283046;
    .page-btn {
      background: #242b3d;
    }
    .container-btns {
      background: #242b3d;
      .active {
        background: #7367f0;
        color: #fff;
      }
    }
  }
}
.content-table {
  min-width: 100%;
  background: #fff;

  border-radius: 10px;
  overflow-x: scroll;
  // overflow-y: scroll;
  // height: 43.5vh;
  @media screen and (min-width: 1400px) {
    overflow-x: visible;
    // overflow-y: visible;
  }
  .container-table-reports {
    min-width: 1200px !important;
    // padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 5fr 1.2fr 1.2fr 1.2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
    text-align: center;
    @media screen and (min-width: 600px) {
      // padding: 1rem 2rem;
    }

    p {
      margin: 0;
      font-size: 12px;
      transition: 0.1s all ease-in-out;
      @media screen and (min-width: 600px) {
        font-size: 14px;
      }
    }
    .icon {
      //color: red;
      margin: 0 0.2rem;
      cursor: pointer;
    }
  }
  .header-table {
    transition: 0.1s all ease-in-out;
    background: #f3f2f7;
    text-align: center;
    p {
      font-weight: 600;
    }
  }
  .body-table {
    border-bottom: 0.5px solid #e6e6e6;
    transition: 0.1s all ease-in-out;
    background: #fff;
    &:last-child {
      border-bottom: none;
    }
    .body-index {
      //   min-width: 30.88px;
    }
    .body-cell {
      //   min-width: 123.52px;
      p {
        word-wrap: break-word;
      }
      .badge {
        width: 80%;
        @media screen and (min-width: 600px) {
          width: 50% !important;
        }
      }
    }
  }
}

.content-table::-webkit-scrollbar {
  width: 4px !important;
  background: #d5d1fb !important;
}
.content-table::-webkit-scrollbar-thumb {
  background: #7367f0 !important;
  border-radius: 6px !important;
}

.dark-layout {
  .content-table {
    background: #283046;
    .header-table {
      background: #343d55;
    }
    .body-table {
      border-bottom-color: #3b4253;
    }
  }
}
.btn-agregar-organizer {
  width: 180px;
}
.circle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 4px;
}
.container-body-header {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
.font-other-name {
  font-size: 12px;
  font-weight: bold;
}
.header-table-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
  }
  img {
    height: 24px;
    width: 24px;
    object-fit: cover;
  }
}
.btn-buscar-placa {
  width: 220px !important;
  margin-left: 1rem;
}
.container-padrillo {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  // padding: 0 1rem;
  .padrillo {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // justify-content: center;
    margin-right: 0.5rem;
    .alias {
    }
    .plate {
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      transition: 0.2s all ease-in-out;
      padding: 0.05rem 0.2rem !important;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      .icon {
        color: #9c97da;
        font-weight: bold;
      }
      .text-plate {
        color: #9c97da;
        font-weight: bold;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
.number-report {
  // font-weight: bold;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  &:hover {
    color: #9c97da;
  }
}
.icon-eye {
  margin-right: 0.5rem;
}
.table-1 {
  background: #ddfcdd !important;
}
.table-2 {
  background: #bbf4fe !important;
}
.table-3 {
  background: #fecccb !important;
}
.dark-layout {
  .table-1 {
    background: #9bb09b !important;
  }
  .table-2 {
    background: #83abb2 !important;
  }
  .table-3 {
    background: #b28f8e !important;
  }
  .number-report {
    color: #fff !important;
  }
}
.modal-df {
  padding: 1rem !important;
}
.data-null {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 18px;
    font-weight: bold;
    margin: 2rem 0;
    padding-left: 1rem;
  }
}

@keyframes ldio-m8h18h0i76f {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-m8h18h0i76f > div {
  animation: ldio-m8h18h0i76f 1s infinite linear;
  transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div {
  position: absolute;
}
.ldio-m8h18h0i76f > div div:nth-child(1),
.ldio-m8h18h0i76f > div div:nth-child(2) {
  width: 84px;
  height: 84px;
  border: 14px solid;
  border-radius: 50%;
  border-color: transparent #7367f0 #7367f0 #7367f0;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(45deg);
  transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div:nth-child(1) {
  transform: rotate(45deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(2) {
  transform: rotate(0deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(3) {
  width: 0;
  height: 0;
  border: 12px solid;
  border-color: transparent transparent transparent #7367f0;
  transform: translate(50px, 2px);
}
.loadingio-spinner-reload-r3yh8h7kig {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: #fff;
}
.ldio-m8h18h0i76f {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-m8h18h0i76f div {
  box-sizing: content-box;
}
.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-search {
    display: flex;
    align-items: center;
  }
  .title-sidebar {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
  }
  margin-bottom: 1rem;
  .icon {
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }
}
.content-table {
  .header-sticky {
    @media screen and (min-width: 1400px) {
      top: calc(62.4px + 1.3rem);
      // top: 200px;
      // top: 50%;
      position: sticky;
      z-index: 999;
      // border-bottom: 1px solid #aba3f5;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    }
  }
}
.content-table::-webkit-scrollbar {
  // width: 2px !important;
  background: #d5d1fb !important;
  height: 4px;
}
.content-table::-webkit-scrollbar-thumb {
  background: #7367f0 !important;
  border-radius: 2px !important;
}
.search-plate {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  .form-control {
    height: 2rem;
  }
  .icon {
    margin-left: 0.5rem;
  }
}
.page-btn {
  height: 32px;
  width: 32px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
  border-radius: 32px;
  cursor: pointer;
  background: #f3f2f7;
}
.container-btns {
  margin: 0 0.4rem;
  width: auto;
  background: #f3f2f7;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: 0.1s all ease-in-out;
  .active {
    background: #7367f0;
    color: #fff;
  }
}
.disabled {
  color: #d5d5d5;
}

.container-search-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
  @media screen and (min-width: 800px) {
    input {
      width: 360px;
    }
    .btn {
      max-width: 200px !important;
    }
  }
  .btn {
    width: 100% !important;
    margin-left: 0;
    margin-top: 0.5rem;
    @media screen and (min-width: 600px) {
      margin-top: 0;
      margin-left: 0.5rem;
    }
  }
}

.dark-layout {
  .table-1 {
    background: #9bb09b;
  }
  .table-2 {
    background: #83abb2;
  }
  .table-3 {
    background: #b28f8e;
  }
  .number-report {
    color: #fff !important;
  }
}
.dark-layout {
  .padrillo {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    .alias {
      color: #fff;
    }
    .plate {
      .icon {
      }
      .text-plate {
      }
    }
  }
}
.padrillo-flex {
  width: 100%;
  justify-content: space-between;
}
.header-hijos {
  grid-column: span 3;
  .container-hijos-title {
  }
  .container-hijos-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.childs-specimen {
  background: #d6f8fe !important;
}
.grid-table-sons-by-posturas {
  display: grid;
  grid-template-columns: 1fr 4fr 3fr 2fr !important;
  .container-padrillo {
    padding: 0 !important;
  }
}
.sticky-plate-specimen {
  position: sticky;
  top: calc(62.4px + 1.3rem);
  z-index: 1000;
  bottom: 0;
}
.dark-layout {
  .padrillo {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}
.dark-layout {
  .sons-dark {
    color: #6e6b7b !important;
  }
}
.info-icon {
  margin-left: 0.4rem;
}
.success-icon {
  animation: pulse1 1s infinite;
  cursor: pointer;
  margin-left: 0.2rem;
}
.danger-icon {
  cursor: pointer;
  animation: pulse2 1s infinite;
  margin-left: 0.2rem;
}

@keyframes pulse1 {
  0% {
    color: #80e12f;
  }
  50% {
    color: #73cb2a;
  }
  100% {
    color: #80e12f;
  }
}

@keyframes pulse2 {
  0% {
    color: #f50d0d;
  }
  50% {
    color: #dd0c0c;
  }
  100% {
    color: #f50d0d;
  }
}
.sticky-plate-specimen {
  position: sticky;
  top: calc(62.4px + 1.3rem);
  z-index: 1000;
  bottom: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border: 2px solid #7366ef !important;
}
</style>
