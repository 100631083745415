<template>
	<div
		class="item-line"
		:style="{ width: `${line.percentage + 1}%`, background: line.breeder_id ? colors[index] : '#f5f5f5' }"
	>
		<p class="mb-0 font-weight-bolder" :style="{ color: line.breeder_id ? 'white' : 'black' }">
			{{ `${line.breeder_id ? `${line.percentage}%` : "NO DEFINIDO"}` }}
		</p>
		<div
			v-if="line.breeder_id"
			class="text"
			:style="{ top: index % 2 == 0 ? '130%' : '-120%' }"
			:class="[index % 2 == 0 ? 'text-bottom' : 'text-top']"
		>
			<p class="mb-0">{{ line.breeder_name }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "CardLineBreeder",
	props: {
		line: {
			type: Object,
		},
		index: {
			type: Number,
		},
	},
	data() {
		return {
			colors: [
				"#FF0000",
				"#00FF00",
				"#0000FF",
				"#FFA500",
				"#800080",
				"#FFFF00",
				"#00FFFF",
				"#FF1493",
				"#008000",
				"#FF4500",
				"#800000",
				"#008080",
				"#FF69B4",
				"#808000",
				"#FF6347",
				"#800000",
				"#808080",
				"#FF8C00",
				"#8B008B",
				"#FFD700",
			],
		}
	},
}
</script>

<style scoped lang="scss">
.item-line {
	// margin: 0 -5px;
	height: 40px;
	/* width: 100px; */
	background: red;
	/* clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); */
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	// box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

	p {
		font-size: 12px;
	}
	.text {
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		background: black;
		width: 120px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			line-height: 1 !important;
			text-align: center;
			color: #fff;
			font-size: 10px !important;
		}
	}
	.text-bottom {
		padding-top: 10px;
		// background: green !important;
		clip-path: polygon(0 22%, 45% 22%, 50% 0, 55% 22%, 100% 22%, 100% 100%, 0 100%);
	}
	.text-top {
		clip-path: polygon(0 0, 100% 0, 100% 78%, 55% 78%, 50% 100%, 45% 78%, 0 78%);
		// clip-path: polygon(0 0, 100% 0, 100% 78%, 55% 78%, 48% 100%, 35% 78%, 0 78%);
		padding-bottom: 10px !important;
	}
}
</style>
