<template>
    <div>
		<div class="d-flex">
			<div class="dseCard__head dseCard__head--wfull">Hijos muertos</div>
		</div>
		<div class="dseCard__body dseCard__body--wfull position-relative pt-2 " >
			<VueApexCharts
				type="donut"
				style="margin-top: 8px"
				:height="widthScreen > 1200 ? 700 : 300"
				:options="deadsOpts"
				:series="deadsData"
			/>
			<FeatherIcon
				class="position-absolute cursor-pointer text-primary"
				style="top: 1rem; left: 1rem"
				icon="PieChartIcon"
				size="20"
				v-b-tooltip.hover.top="'Mas detalles'"
				@click="openModalChildDeads"
				v-if="specimen.origin != 1 || specimen.origin == null"
			/>
			<p class="total-deads">Total: <span>{{ sumDeads }}</span></p>
		</div>
	</div>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import ChildsDeadService from "@/services/childsDead.service"

export default {
	name: "ChildsDeadChart",
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
	},
    components:{
        VueApexCharts,
    },
    data(){
        return{
            deadsData: [1],
			deadsOpts: {
				labels: ["1"],
				colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: { position: "bottom" },
			},
			openModalSecure: false,
			sumDeads: 0,
		} 
    },
    computed: {
        widthScreen() {
			return this.$store.state.app.windowWidth
		},
    },
    mounted(){
        this.loadChildsDead();
    },
    methods:{
        async loadChildsDead() {
			let dataDeads = []
			let dataLabels = []
			const { data } = await ChildsDeadService.getChildsDead(1, this.specimen.id)
			this.sumDeads = data.reduce((a, b) => a + Number(b.amount), 0)
			data.forEach((index) => {
				if (Number(!index.amount)) {
					return
				}
				dataDeads.push(Number(index.amount))
				dataLabels.push(`${index.reason.toUpperCase()}: (${index.amount})`)
			})
			if (!dataDeads.length && !dataLabels.length) {
				this.deadsData = [1]
				this.deadsOpts = {
					labels: ["Sin resultados"],
					colors: ["rgb(153, 153, 153)"],
					legend: { position: "bottom", fontWeight: 600, labels: { colors: "rgb(153, 153, 153)" } },
					tooltip: {
						enabled: false,
					},
					dataLabels: {
						// valida si hay linea desconocida en el primer index del array de lineas para saber si mostrar etiquetas
						formatter: (val, opts) => {
							return ""
						},
					},
				}
				this.isPreloading(false)
				return
			}
			this.deadsData = dataDeads
			this.deadsOpts = {
				labels: dataLabels,
				colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: {
					position: "bottom",
				},
				dataLabels: {
					formatter: (val) => {
						return
					},
				},
				tooltip: {
					enabled: true,
					y: {
						title: {
							formatter: (seriesName) => seriesName.slice(0, -4),
						},
					},
				},
			}
			this.openModalSecure = true
			this.isPreloading(false)
		},
		openModalChildDeads() {
			if (!this.openModalSecure) {
				this.showToast(
					"warning",
					"top-right",
					"Dashboard",
					"SuccessIcon",
					`No hay Resultados para ${this.specimen.alias || this.specimen.plate}`
				)
				return
			}
			this.$emit("modal-actions", "show-deads-modal")
		},
    },
}

</script>

<style scoped lang="scss">
.total-deads{
	position: absolute;
	top: 1rem;
	right: 1rem;
	font-size: 16px;
	font-weight: 600;
	color: #9e9e9e;
	margin-bottom: 0;
	span{
		color: #7367f0;
		font-weight: 700;
	}
}
</style>