<template>
	<b-modal hide-footer centered size="xlg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-row class="mb-2">
				<b-col cols="12" md="8">
					<b-tabs v-model="tab" @input="getRows">
						<b-tab>
							<template #title>
								Desparacitacion
								<b-badge class="ml-1" variant="info" v-if="counters.deworn">
									{{ counters.deworn }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab>
							<template #title>
								Suplementos
								<b-badge class="ml-1" variant="info" v-if="counters.vitamins">
									{{ counters.vitamins }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab>
							<template #title>
								Tratamientos
								<b-badge class="ml-1" variant="info" v-if="counters.treatment">
									{{ counters.treatment }}
								</b-badge>
							</template>
						</b-tab>
					</b-tabs>
				</b-col>
				<b-col class="text-right" cols="12" md="4">
					<div class="d-flex justify-content-end">
						<b-button
							class="d-flex align-items-center justify-content-center"
							variant="primary"
							@click="insertDeworn"
							v-if="tab == 0"
						>
							<p class="mb-0 mr-25">Agregar</p>
							<feather-icon icon="PlusIcon" size="18" />
						</b-button>
						<b-button
							class="d-flex align-items-center justify-content-center"
							variant="primary"
							@click="modalVitamin.show = !modalVitamin.show"
							v-else-if="tab == 1"
						>
							<p class="mb-0 mr-25">Agregar</p>
							<feather-icon icon="PlusIcon" size="18" />
						</b-button>
						<!-- <b-button
							class="d-flex align-items-center justify-content-center"
							variant="primary"
							@click="modalVitamin.show = !modalVitamin.show"
							v-else-if="tab == 2"
						>
							<p class="mb-0 mr-25">Agregar t</p>
							<feather-icon icon="PlusIcon" size="18" />
						</b-button> -->
					</div>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="50vh">
				<b-thead class="text-center">
					<b-tr v-if="tab == 0">
						<b-th>Etapa</b-th>
						<b-th>Descripción</b-th>
						<b-th>Creado por</b-th>
					</b-tr>
					<b-tr v-if="tab == 1">
						<b-th>Etapa</b-th>
						<b-th>Descripción</b-th>
						<b-th>Suplemento</b-th>
						<b-th>Tipo</b-th>
						<b-th>Creado por</b-th>
						<b-th>Días</b-th>
					</b-tr>
					<b-tr v-if="tab == 2">
						<b-th>Etapa</b-th>
						<b-th>Descripción</b-th>
						<b-th>Creado por</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<template v-if="tab == 0">
						<b-tr v-for="(row, i) in rows" :key="i">
							<b-td>{{ row.type == "chick" ? "Pollo" : "Ejemplar" }}</b-td>
							<b-td>{{ row.comment }}</b-td>
							<b-td>
								<p class="mb-0">{{ row.user }}</p>
								<small>{{ row.created_at | myGlobalDayShort }}</small>
							</b-td>
						</b-tr>
					</template>
					<template v-if="tab == 1">
						<b-tr v-for="(row, i) in rows" :key="i">
							<b-td>{{ row.type == "chick" ? "Pollo" : "Ejemplar" }}</b-td>
							<b-td>{{ row.comment }}</b-td>
							<b-td>{{ row.name }}</b-td>
							<b-td>{{ row.type }}</b-td>
							<b-td>
								<p class="mb-0">{{ row.user }}</p>
								<small>{{ row.created_at | myGlobalDayShort }}</small>
							</b-td>
							<b-td>
								<p>{{ row.days && row.days.length > 0 ? row.days.join(",") : "---" }}</p>
							</b-td>
						</b-tr>
					</template>
					<template v-if="tab == 2">
						<b-tr v-for="(row, i) in rows" :key="i">
							<b-td>{{ row.type == "chick" ? "Pollo" : "Ejemplar" }}</b-td>
							<b-td>{{ row.name }}</b-td>
							<b-td>
								<span class="mb-0">{{ row.created_at.name }}</span>
								<br />
								<small>{{ row.created_at.date | myGlobalDayShort }}</small>
							</b-td>
						</b-tr>
					</template>

					<b-tr v-if="rows.length == 0">
						<b-td colspan="5">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<b-modal
			centered
			size="lg"
			title="Registrar suplementos"
			v-model="modalVitamin.show"
			@hidden="closeModalVitamin"
		>
			<b-overlay :show="modalVitamin.loading">
				<ValidationObserver ref="form">
					<b-table-simple small sticky-header="40vh">
						<b-thead class="text-center">
							<b-tr>
								<b-th class="pt-1">Suplemento</b-th>
								<b-th class="pt-1">Tipo</b-th>
								<b-th class="pt-1">Días</b-th>
								<b-th>
									<b-button
										class="btn-icon"
										variant="primary"
										size="sm"
										@click="modalVitamin.vitamins.push({ id: null, type: null, days: [] })"
									>
										<feather-icon class="clickable" icon="PlusIcon" />
									</b-button>
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<b-tr v-for="(row, i) in modalVitamin.vitamins" :key="i">
								<b-td style="min-width: 8rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'is-invalid' : ''"
											:options="modalVitamin.vitaminOpts"
											v-model="row.id"
										/>
									</ValidationProvider>
								</b-td>
								<b-td>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-radio-group v-model="row.type">
											<b-form-radio @change="period = true" :value="1">Frecuente</b-form-radio>
											<b-form-radio :value="2">Ocasional</b-form-radio>
										</b-form-radio-group>
										<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-td>
								<b-td>
									<template v-if="row.type == 1">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<v-select
												v-model="row.days"
												label="text"
												style="width: 10rem"
												multiple
												appendToBody
												:clearable="false"
												:options="days"
												:reduce="(opt) => opt.value"
												:calculatePosition="positionDropdown"
												:class="{ 'border-danger rounded': !!errors[0] }"
											/>

											<small v-if="errors[0]" class="text-danger text-small-1">
												{{ errors[0] }}
											</small>
										</ValidationProvider>
									</template>
									<p v-if="row.type == 2">---</p>
								</b-td>
								<b-td>
									<b-button
										class="btn-icon"
										variant="flat-danger"
										size="sm"
										@click="removeVitamin(i)"
										v-if="modalVitamin.vitamins.length > 1"
									>
										<feather-icon class="clickable" size="20" icon="TrashIcon" />
									</b-button>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</ValidationObserver>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="success" @click="insertVitamin">Guardar</b-button>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import specimenService from "@/services/specimens.service"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		period: false,
		tab: 0,
		rows: [],
		counters: { vitamin: 0, deworn: 0, treatment: 0 },
		modalVitamin: {
			show: false,
			loading: false,
			vitaminOpts: [],
			vitamins: [{ id: null, type: null, days: [] }],
		},
		firstLoad: true,
		days: [
			{ text: "Lunes", value: 1 },
			{ text: "Martes", value: 2 },
			{ text: "Miercoles", value: 3 },
			{ text: "Jueves", value: 4 },
			{ text: "Viernes", value: 5 },
			{ text: "Sabado", value: 6 },
			{ text: "Domingo", value: 7 },
		],
		aselector: null,
	}),

	computed: {
		title() {
			if (!this.info) return ""
			let text = `Historial clinico / `
			if (this.info.alias) text += `${this.info.alias} `
			if (this.info.plate) text += `(${this.info.plate})`
			return text
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.period = false
			this.tab = 0
			this.rows = []
			this.counters = { vitamins: 0, deworn: 0, treatment: 0 }
			this.firstLoad = true
			this.$emit("closing")
		},
		closeModalVitamin() {
			this.modalVitamin.show = false
			this.modalVitamin.loading = false
			this.modalVitamin.vitamins = [{ id: null, type: null }]
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async getVitaminOptions() {
			this.isLoading = true
			const { data } = await preparacionSettingService.getVitamins({ status: 1 })
			this.modalVitamin.vitaminOpts = data.map((opt) => ({
				value: opt.id,
				text: opt.name,
			}))
			this.isLoading = false
		},
		async getCounters() {
			const { data } = await specimenService.getClinicalCounters({ specimen_id: this.info.id })
			this.counters = data
			if (this.firstLoad && this.counters.deworn == 0 && this.counters.vitamins !== 0) {
				this.tab = 1
				this.firstLoad = false
			}
		},
		async getRows() {
			this.isLoading = true
			if (this.tab == 0) {
				const { data } = await specimenService.getClinicalDeworns({ specimen_id: this.info.id })
				this.rows = data
			} else if (this.tab == 1) {
				const { data } = await specimenService.getClinicalVitamins({ specimen_id: this.info.id })
				const diasSemana = {
					1: "Lu",
					2: "Ma",
					3: "Mi",
					4: "Ju",
					5: "Vi",
					6: "Sa",
					7: "Do",
				}
				data.forEach((element) => {
					if (element.days) {
						element.days = element.days.map((index) => diasSemana[index])
					}
				})
				this.rows = data
			} else if (this.tab == 2) {
				const data = await specimenService.getClinicalTreatments({ specimen_id: this.info.id })
				this.rows = data
			}
			await this.getCounters()
			await this.getVitaminOptions()
			this.isLoading = false
		},
		async insertDeworn() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se realizara una DESPARACITACION al ejemplar ${this.info.alias || this.info.plate}`,
			})
			if (!isConfirmed) return
			await specimenService.insertClinicalDeworn({ specimen_id: this.info.id })
			this.getRows()

			this.$emit("refresh")
		},
		removeVitamin(idx) {
			this.modalVitamin.vitamins.splice(idx, 1)
		},
		async insertVitamin() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se aplicara ${this.modalVitamin.vitamins.length} SUPLEMENTO(S) al ejemplar ${
					this.info.alias || this.info.plate
				}`,
			})
			if (!isConfirmed) return
			this.modalVitamin.loading = true
			await specimenService.insertClinicalVitamins({
				specimen_id: this.info.id,
				vitamins: this.modalVitamin.vitamins,
			})
			this.modalVitamin.loading = false
			this.closeModalVitamin()
			this.getRows()
			this.$emit("refresh")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
