<template>
	<div>
		<div class="d-flex">
			<div class="dseCard__head dseCard__head--wfull">Calificaciones de sus hijos en Topes</div>
		</div>
		<b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
			<div class="dseCard__body dseCard__body--wfull position-relative">
				<div class="d-flex justify-content-between align-items-center gap-x-2 mb-1">
					<div>
						Promedio general:
						<b>{{ totalAverage }}</b>
					</div>
					<div class="d-flex justify-content-end align-items-center gap-x-2">
						<span class=" " style="margin-left: 1.2rem">
							Mostrando {{ from }} a {{ to }} de {{ total }} registros
						</span>
						<b-button-group size="sm">
							<b-button
								class="btn-icon"
								variant="outline-secondary"
								:disabled="pagination.page <= 1"
								@click="changePagination(-1)"
							>
								<feather-icon icon="ChevronLeftIcon" />
							</b-button>
							<b-button
								class="btn-icon"
								variant="outline-secondary"
								:disabled="pagination.page >= pagination.maxPage"
								@click="changePagination(1)"
							>
								<feather-icon icon="ChevronRightIcon" />
							</b-button>
						</b-button-group>
					</div>
				</div>
				<VueApexCharts ref="tChart" type="bar" height="250" :options="padrilloOpts" :series="padrilloData" />
			</div>
		</b-overlay>
	</div>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import labelUtil from "@/views/amg/tournaments/v2/chartLabelUtil"
import ConfrontationsResultService from "@/services/confrontationsResults.service.js"

export default {
	name: "ChildsFightsEffectivenessChart",
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		VueApexCharts,
	},
	data() {
		return {
			padrilloOpts: {
				labels: ["No h	ay información para mostrar"],
				xaxis: {
					labels: {
						style: {
							color: ["#9aa0ac"],
						},
					},
				},
				yaxis: {
					title: {
						text: "Promedio de calificaciones",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
			},
			pagination: {
				page: 1,
				maxPage: null,
			},
			padrilloData: [],
			dataFights: {},
			showLoading: false,
			tournamentAvatars: null,
			from: null,
			to: null,
			total: null,
			totalAverage: 0,
		}
	},
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	mounted() {
		if (this.specimen.category == 1 || this.specimen.category == 2) {
			this.getFights()
		}
	},
	methods: {
		changePagination(diff) {
			this.pagination.page += diff
			this.getFights()
		},
		async getFights() {
			this.showLoading = true
			const { data: fullData } = await ConfrontationsResultService.getScoreRankingChildSpecimen(
				this.specimen.id,
				this.pagination.page
			)
			this.totalAverage = Number(fullData.average_total)
			const data = fullData.data
			this.to = data.to
			this.from = data.from
			this.total = data.total
			this.pagination.maxPage = data.last_page
			const results = data.data
			if (!results.length) {
				this.showLoading = false
				return
			}
			this.padrilloData = [
				{
					name: "Promedio de Calificación",
					data: results.map((m) => m.average.toFixed(2)),
				},
			]
			this.padrilloOpts = {
				labels: results.map((t) => t.specimen.alias ?? t.specimen.plate),
				colors: ["#6258cc"],
				legend: { position: "bottom" },
				plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				tooltip: {
					x: { formatter: (a) => a },
				},
				yaxis: {
					title: {
						text: "Promedio de calificaciones",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
				chart: {
					events: {
						dataPointSelection: (e, result_id, result_alter) =>
							this.openSpecimen(results[result_alter.dataPointIndex]),
					},	
				},
			}
			this.showLoading = false
		},

		openSpecimen(specimen) {
			window.open(`/ejemplares/detalle/${specimen.specimen.id}`, "_blank")
		},
	},
}
</script>
