var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-auto card content-table"},[_vm._m(0),(_vm.arraySonsByPostura.length > 0)?_vm._l((_vm.arraySonsByPostura),function(item,index){return _c('div',{key:index,staticClass:" grid-table-sons-by-posturas1 body-table"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center body-index p-1"},[_c('p',{staticClass:"number-report mb-0"},[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center body-index p-1"},[_c('div',{staticClass:"container-padrillo"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"size":"40","button":"","src":item.preload,"text":_vm.avatarText(item.alias),"disabled":!item.preload},on:{"click":function($event){_vm.selectFile(
                    item.url,
                    'image',
                    ((item.alias == null
                        ? ''
                        : ((item.alias) + " -")) + " (" + (item.plate && item.plate) + ")"),
                    ("/ejemplares/detalle/" + (item.id))
                  )}}}),_c('div',{staticClass:"d-flex align-items-center padrillo-flex"},[_c('div',{staticClass:"padrillo"},[(item.alias)?_c('p',{staticClass:"alias mb-0"},[_vm._v(" "+_vm._s(item.alias)+" ")]):_vm._e(),_c('div',{staticClass:"plate cursor-pointer",on:{"click":function($event){return _vm.doCopy(item.plate)}}},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"ClipboardIcon"}}),_c('p',{staticClass:"text-plate mb-0"},[_vm._v(" "+_vm._s(item.plate)+" ")])],1)]),_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"ExternalLinkIcon","size":"20"},on:{"click":function($event){return _vm.linkSpecimen(item.id)}}})],1)],1)]),_c('div',{staticClass:"d-flex align-items-center justify-content-center body-index p-1"},[_c('p',{staticClass:"number-report mb-0"},[_vm._v(_vm._s(item.status))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center body-index p-1"},[_c('p',{staticClass:"number-report mb-0"},[_vm._v(_vm._s(item.gender))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center body-index p-1"},[_c('div',{staticClass:"container-padrillo"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"size":"40","button":"","src":item.madrilla.thumb,"text":_vm.avatarText(item.madrilla.alias),"disabled":!item.madrilla.thumb},on:{"click":function($event){_vm.selectFile(
                    item.madrilla.image,
                    'image',
                    ((item.alias == null
                        ? ''
                        : ((item.madrilla.alias) + " -")) + " (" + (item.madrilla.plate && item.madrilla.plate) + ")"),
                    ("/ejemplares/detalle/" + (item.madrilla.id))
                  )}}}),_c('div',{staticClass:"d-flex align-items-center padrillo-flex"},[_c('div',{staticClass:"padrillo"},[_c('p',{staticClass:"alias mb-0"},[_vm._v(" "+_vm._s(item.madrilla.alias ? item.madrilla.alias : "----------")+" ")]),_c('div',{staticClass:"plate cursor-pointer",on:{"click":function($event){return _vm.doCopy(item.madrilla.plate)}}},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"ClipboardIcon"}}),_c('p',{staticClass:"text-plate mb-0"},[_vm._v(" "+_vm._s(item.madrilla.plate)+" ")])],1)]),_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"ExternalLinkIcon","size":"20"},on:{"click":function($event){return _vm.linkSpecimen(item.madrilla.id)}}})],1)],1)])])}):[_vm._m(1)],(_vm.dataSelectVideo.open)?_c('SlidersImageVideo',{attrs:{"open":_vm.dataSelectVideo.open,"url":_vm.dataSelectVideo.url,"text":_vm.dataSelectVideo.text,"text_url":_vm.dataSelectVideo.text_url,"type":_vm.dataSelectVideo.type},on:{"refreshSlider":_vm.refreshSlider}}):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" header-table grid-table-sons-by-posturas1"},[_c('div',{staticClass:"header-table-icon"},[_c('p',{staticClass:"mb-0 p-1"},[_vm._v("#")])]),_c('div',{staticClass:"header-table-icon"},[_c('p',{staticClass:"mb-0 p-1"},[_vm._v("Ejemplar hijo")])]),_c('div',{staticClass:"header-table-icon"},[_c('p',{staticClass:"mb-0 p-1"},[_vm._v("Estado")])]),_c('div',{staticClass:"header-table-icon"},[_c('p',{staticClass:"mb-0 p-1"},[_vm._v("Género")])]),_c('div',{staticClass:"header-table-icon"},[_c('p',{staticClass:"mb-0 p-1"},[_vm._v("Madrilla")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"  body-table"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center body-index p-1"},[_c('p',{staticClass:"number-report mb-0"},[_vm._v("Sin datos")])])])}]

export { render, staticRenderFns }