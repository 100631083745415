<template>
	<b-modal centered hide-footer size="xlg" v-model="isActive">
		<template #modal-header>
			<span>Calificaciones / {{ info.alias }} {{ info.plate && `(${info.plate})` }}</span>
			<b-badge class="ml-2" variant="primary">PROMEDIO: {{ rating }}</b-badge>
			<button @click="isActive = false" type="button" aria-label="Close" class="close">×</button>
		</template>
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Oponente</b-th>
						<!-- <b-th>Cualidades</b-th> -->
						<!-- <b-th>Defectos</b-th> -->
						<b-th>Promedio por tope</b-th>
						<b-th>Video</b-th>
						<b-th>Fecha de tope</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<SpecimenPlate v-if="row.specimen" :specimen="row.specimen" />
							<p v-else class="mb-0">Sin rival</p>
						</b-td>
						<!-- <b-td>
							<span
								class="d-inline-block mb-1 ml-1 text-nowrap text-success"
								v-for="quality in row.qualities"
								:key="`quality-${quality.id}`"
							>
								{{ quality.name }}
								<b-badge class="ml-05" variant="success">{{ quality.count }}</b-badge>
							</span>
						</b-td>
						<b-td>
							<span
								class="d-inline-block mb-1 ml-1 text-nowrap text-danger"
								v-for="defect in row.defects"
								:key="`defect-${defect.id}`"
							>
								{{ defect.name }}
								<b-badge class="ml-05" variant="danger">{{ defect.count }}</b-badge>
							</span>
						</b-td> -->
						<b-td>
							<div
								class="clickable d-flex align-items-center justify-content-center"
								@click="openRatingTracking(row)"
							>
								<span style="font-weight: bold; font-size: 16px" class="text-primary mt-25 mr-50">
									{{ Number(row.score).toFixed(2) }}
								</span>
								<feather-icon
									class="cursor-pointer star-score text-warning"
									size="16"
									icon="StarIcon"
								/>
							</div>
						</b-td>
						<b-td>
							<b-button
								class="p-0 m-0"
								@click="showTopeVideo(row)"
								v-b-tooltip.hover
								:title="row.video && row.video.status == 1 ? 'Actualizando video' : 'Video listo'"
								v-if="row.video"
							>
								<b-avatar
									:src="row.video.status == 2 ? row.video.thumb : null"
									rounded
									size="80"
									icon="film"
									badge
									badge-variant="danger"
								>
									<template #badge>{{ row.video.duration }}</template>
								</b-avatar>
							</b-button>
							<p v-else class="mb-0">Sin video</p>
						</b-td>
						<b-td>
							<p class="mb-0" v-if="row.created_at">{{ row.created_at.date | myGlobalDayShort }}</p>
							<p class="mb-0" v-else>---</p>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="4">No se encontraron resultados</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<b-modal centered hide-footer size="lg" v-model="ratingsModal.show" :title="ratingsModal.title">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Calificacion</b-th>
						<b-th>Calificado por</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in ratingsModal.rows" :key="row.created_at">
						<b-td>
							<div class="">
								<span style="font-weight: bold; font-size: 16px" class="text-primary mt-25 mr-50">
									{{ Number(row.score).toFixed(2) }}
								</span>
								<feather-icon
									class="cursor-pointer star-score text-warning"
									size="16"
									icon="StarIcon"
								/>
							</div>
						</b-td>
						<b-td>
							<div v-if="row.created_at">
								{{ row.created_at.user }}
								<br />
								<small>{{ row.created_at.date | myGlobalDayShort }}</small>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
		<b-modal centered hide-footer v-model="characteristicsModal.show" :title="characteristicsModal.title">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Registrado por</b-th>
						<b-th>Fecha</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in characteristicsModal.rows" :key="row.created_at">
						<b-td>{{ row.user }}</b-td>
						<b-td>{{ row.created_at | myGlobalDayShort }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</b-modal>
</template>

<script>
import { mapActions } from "vuex"
import preparacionService from "@/services/preparacion/default.service.js"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import CharacteristicTracking from "@/views/amg/preparacion/components/CharacteristicTracking.vue"

export default {
	components: { SpecimenPlate, CharacteristicTracking },
	props: {
		show: Boolean,
		info: Object,
		rating: Number,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		ratingsModal: {
			show: false,
			title: "",
			rows: [],
		},
		characteristicsModal: {
			show: false,
			title: "",
			rows: [],
		},
	}),
	computed: {
		titleModal() {
			if (!this.info.id) return "Detalles de preparacion"
			switch (this.info._m) {
				case 1:
					return `Detalles de preparacion - Total`
				case 2:
					return `Detalles de preparacion - Durante`
				case 3:
					return `Detalles de preparacion - Fuera`
			}
		},
		rowsC() {
			return this.rows.map((row) => {
				row._type = this.translateType(row.type_rival)
				return row
			})
		},
	},
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setTextImage", "setImages"]),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.ratingsModal = {
				show: false,
				rows: [],
			}
			this.trackingModal = {
				active: false,
				loading: false,
				info: {},
				rows: [],
			}
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await preparacionService.getDashbordDetail({
				id: this.info.id,
			})
			this.rows = data
			this.isLoading = false
		},
		translateType(type) {
			switch (type) {
				case "specimen":
					return { name: "ejemplar", id: 1 }
				case "chick":
					return { name: "pollo", id: 2 }
				case "other":
					return { name: "entrenador", id: 3 }
			}
			return null
		},
		colorizePoints(points) {
			if (points == 2.5) return "info"
			else if (points == null) return "secondary"
			else if (points > 2.5) return "success"
			else if (points < 2.5) return "danger"
		},
		//
		async openCharacteristicsTracking(characteristic, isDefect = false) {
			this.isLoading = true
			const data = await preparacionService.getTrackingCharacteristic({
				characteristics_id: characteristic.id,
				type: isDefect ? 2 : 1,
				specimen_id: this.info.id,
			})
			this.characteristicsModal.rows = data
			this.characteristicsModal.title = `Tracking de ${isDefect ? "defecto / " : "cualidad / "} ${
				characteristic.name
			}`
			this.characteristicsModal.show = true
			this.isLoading = false
		},
		async openRatingTracking(row) {
			this.isLoading = true
			const data = await preparacionService.getRatingTracking({
				specimen_id: this.$route.params.specimen_id,
				confrontation_id: row.confrontations_id,
			})
			this.ratingsModal.rows = data
			let oponent
			if (!row.type_rival) {
				oponent = "Sin rival"
			} else {
				oponent = row.specimen.alias || row.specimen.plate
			}
			this.ratingsModal.title = `Tracking de calificaciones / ${this.info.alias || this.info.plate} vs ${oponent}`
			this.ratingsModal.show = true
			this.isLoading = false
		},
		showTopeVideo(row) {
			console.log("row", row, this.info)
			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.uid,
					text: `${this.info.alias || this.info.plate} vs ${
						(row.specimen && (row.specimen.alias || row.specimen.plate)) ||
						(row.chick && row.chick.correlative) ||
						(row.other_rival && row.other_rival.name)
					}`,
					specimen: { id: this.info.id },
				},
			])
			// this.setTextImage({
			// 	title: `${this.info.alias || this.info.plate} vs ${
			// 		(row.specimen && (row.specimen.alias || row.specimen.plate)) ||
			// 		(row.chick && row.chick.correlative) ||
			// 		(row.other_rival && row.other_rival.name)
			// 	}`,
			// 	url: `/ejemplares/detalle/${this.info.id}`,
			// })
			this.setIndexImage(0)
			this.toggleModal()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows()
				this.isActive = val
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.star-score {
	color: var(--primary);
	margin-top: -5px;
}
</style>
