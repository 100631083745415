<template>
	<b-modal
		ok-title="Actualizar"
		cancel-title="Cancelar"
		:title="formattedTitle"
		no-close-on-backdrop
		v-model="isActive"
		@hidden="handleHidden"
		@ok="handleOk"
	>
		<SelectSpecimens :categories="String(numCategory)" @selected="setSelected" :flushSearched="clearSelect" />
	</b-modal>
</template>

<script>
import pedigreeService from "@/services/pedigree.service";
import SelectSpecimens from "@/views/amg/specimens/components/SelectSpecimens.vue";

export default {
	components: { SelectSpecimens },
	props: {
		show: Boolean,
		familyInfo: Object,
	},
	data: () => ({
		isActive: false,
		newParent: null,
		clearSelect: 0,
	}),
	computed: {
		numCategory() {
			switch (this.familyInfo.type) {
				case "padrillo":
					return 1;
				case "madrilla":
					return 2;
			}
			return 0;
		},
		formattedTitle() {
			let name = "";
			let fplate = !!this.familyInfo.plate ? `(${this.familyInfo.plate})` : "";
			if (this.familyInfo.plate || this.familyInfo.alias) {
				name = `de ${this.familyInfo.alias || ""}${fplate}`;
			}
			return `Actualizar ${this.familyInfo.type} ${name}`;
		},
		cBody() {
			return {
				family_tree_id: this.familyInfo.tree_id,
				specimen_id: this.familyInfo.specimen_id,
				category_id: this.numCategory,
			};
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.$emit("closing");
		},
		async handleOk(ev) {
			ev.preventDefault();
			let res = await this.updateParent();
			// probar hijo adonis y padres diamante y zar
			if (!res.success) {
				console.error(res);
				return;
			}
			this.$emit("refresh");
			this.handleHidden();
		},
		setSelected(val) {
			let childDob = new Date(this.familyInfo.dob);
			let newDob = new Date(val.dob);
			let permitted = newDob < childDob;
			let dobNull = val.dob == null;
			if (dobNull) {
				this.clearSelect++;
				this.showToast("danger", "top-right", "Error", "XIcon", "La fecha de nacimiento no puede estar vacia");
				return;
			}
			if (!permitted) {
				this.clearSelect++;
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"XIcon",
					"El ancestro debe haber nacido antes que su descendencia."
				);
				return;
			}
			this.newParent = { ...val };
		},
		async updateParent() {
			if (this.newParent == null) {
				this.showToast("danger", "top-right", "Error", "XIcon", "No se selecciono un nuevo ancestro.");
				return;
			}
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			let body = {
				family_tree_id: this.familyInfo.tree_id,
				specimen_id: this.newParent.specimen_id,
				category_id: this.numCategory,
			};
			return await pedigreeService.changeParent(body);
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = true;
			}
		},
	},
};
</script>
