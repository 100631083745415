<template>
	<div class="w-full h-full p-relative" ref="element">
		<div class="tree-modal__actions">
			<!-- <feather-icon icon="ImageIcon" size="32" @click="transformAndExport" class="cursor-pointer" /> -->
			<feather-icon icon="XCircleIcon" size="32" @click="$emit('close')" class="cursor-pointer" />
		</div>
		<div
			ref="family-tree"
			id="root-d3-tree"
			:style="{
				'--bg-image': `url(${require('@/assets/images/logo_final.png')})`,
				'--bg-texture': `url(${require('@/assets/images/texture.jpg')})`,
			}"
			class="w-full h-full"
		/>
		<section class="abs-center ts-sidebar">
			<TreeLegendCard />
		</section>
		<ModalUpdateSpecimen
			v-if="modalForUpdate"
			:specimen-id="currentSpecimen.specimen_id"
			@hidden="modalForUpdate = false"
			@updated="refreshD3Drawing"
		/>
		<ModalRegisterParents
			v-if="modalForParents"
			:specimen="{
				id: currentSpecimen.specimen_id,
				alias: currentSpecimen.alias,
				plate: currentSpecimen.plate,
				madrilla_id: currentSpecimen.madrilla_id,
				padrillo_id: currentSpecimen.padrillo_id,
				date: currentSpecimen.date,
			}"
			:omits="omits"
			@hidden="modalForParents = false"
			@parent-saved="refreshD3Drawing"
			@updateSpecimen="updateSpecimen"
		/>
		<ModalSwitchSpecimen
			:show="showSwitchspecimen"
			:familyInfo="curFamilyInfo"
			@closing="showSwitchspecimen = false"
			@refresh="refreshD3Drawing"
		/>
	</div>
</template>

<script>
import { D3FamilyTree } from "./d3-tree/index"
import * as htmlToImage from "html-to-image"
import { mapState } from "vuex"
import _ from "lodash"
import TreeLegendCard from "@/views/amg/specimens/specimen-tree/TreeLegendCard.vue"
import ModalUpdateSpecimen from "@/views/amg/specimens/specimen-tree/modals/ModalUpdateSpecimen.vue"
import ModalRegisterParents from "@/views/amg/specimens/specimen-tree/modals/ModalRegisterParents.vue"
import ModalSwitchSpecimen from "@/views/amg/specimens/specimen-tree/modals/ModalSwitchSpecimen.vue"
import { CoLgtm } from "oh-vue-icons/icons"

export default {
	name: "SpecimenFamilyTree",
	components: {
		TreeLegendCard,
		ModalUpdateSpecimen,
		ModalRegisterParents,
		ModalSwitchSpecimen,
	},
	props: {
		specimenId: {
			type: [String, Number],
			default: 1,
		},
		loadingLine: {
			type: Boolean,
		},
	},
	mounted() {
		const vm = this
		this.$nextTick(() => {
			vm.dispatchPedigreeToStore().then((dataset) => {
				vm.updateSpecimenTree = D3FamilyTree(vm.specimens, vm)
			})
		})
	},
	data: () => ({
		modalForUpdate: false,
		modalForParents: false,
		currentSpecimen: {},
		updateSpecimenTree: null,
		omits: [],
		showSwitchspecimen: false,
		// data of family tree to be afected with parent switch
		curFamilyInfo: {},
	}),
	computed: {
		...mapState({
			specimens: (state) => state.pedigree.pedigree,
		}),
	},
	methods: {
		addSpecimen(specimen) {
			this.currentSpecimen = specimen
			this.omits = this.generateDescendants(specimen)
			this.modalForParents = true
		},
		editSpecimen(specimen) {
			this.currentSpecimen = specimen
			this.modalForUpdate = true
		},
		async dispatchPedigreeToStore() {
			return await this.$store.dispatch({
				type: "pedigree/fetchPedigree",
				specimen_id: this.specimenId,
			})
		},
		closeTreeModal() {
			this.$emit("close")
		},
		onClick() {
			this.$refs.element.requestFullscreen({ navigationUI: "show" }).then(function () {
				// element has entered fullscreen mode successfully
			})
		},
		refreshD3Drawing() {
			const vm = this
			this.dispatchPedigreeToStore().then((dataset) => {
				vm.$refs["family-tree"].innerHTML = ""
				vm.isPreloading(false)
				D3FamilyTree(vm.specimens, vm)
			})
		},
		transformAndExport() {
			this.transformPending = true
			htmlToImage.toPng(document.getElementById("tree-modal"), { quality: 0.92 }).then(function (dataUrl) {
				const anchor = document.createElement("a")
				anchor.download = "family-generation.png"
				anchor.href = dataUrl
				anchor.click()
			})
		},
		generateDescendants(object, pusher = []) {
			if (object.parent) {
				this.generateDescendants(object.parent, pusher)
			}
			pusher.push({
				specimen_id: object.specimen_id,
				type: object.type,
			})

			return pusher
		},
		navigateToSpecimen(specimen_id) {
			this.$router.push({
				name: "detalle-ejemplar",
				params: { specimen_id },
			})
			this.$emit("navigate")
		},
		updateSpecimen({ data }) {
			this.currentSpecimen = {
				...this.currentSpecimen,
				...data,
			}
			if (this.currentSpecimen.madrilla_id && this.currentSpecimen.padrillo_id) {
				this.modalForParents = false
			}
		},
		//
		switchSpecimen(node) {
			if (!!!node.parent.date) {
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"XIcon",
					"No se puede editar ancestro si el hijo no posee fecha de nacimiento."
				)
				return
			}

			this.curFamilyInfo = {
				specimen_id: node.specimen_id,
				dob: node.parent.date,
				type: node.type,
				tree_id: node.parent.family_tree,
				alias: node.parent.alias,
				plate: node.parent.plate,
			}
			this.showSwitchspecimen = true
		},
	},
}
</script>

<style lang="scss">
#tree-plot {
	position: relative;
	z-index: 2;
	--card-stroke: #aaaaaa;
	.dark-layout & {
		--card-stroke: #cccccc;
	}
}

#root-d3-tree {
	position: relative;
	&::after {
		content: "";
		background-color: #f8f8f8;
		background-image: var(--bg-image);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-blend-mode: color-burn;
		opacity: 0.05;
		filter: grayscale(1);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;
		width: 100%;
	}
}

.person {
	color: var(--primary);
	.card {
		stroke: var(--card-stroke);
		fill: transparent;
		stroke-width: 1px;
		pointer-events: none;
	}
}

.link {
	stroke: var(--card-stroke);
	fill: none;
	stroke-width: 2px;
	stroke-dasharray: 4;
}

.sg-text {
	color: #858585;
	.dark-layout & {
		color: #bebebe;
	}
}

.name {
	font-weight: 500;
	fill: var(--primary);
	font-size: 20px;
	font-family: Rubik;
}

.ts-date,
.ts-plate {
	font-weight: 400;
	fill: currentColor;
	font-size: 18px;
	font-family: Rubik;
}

.ts-action {
	cursor: pointer;
}

.ts-sidebar {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 1.5rem;
	z-index: 3;
	pointer-events: none;
}
.px-scale-pointer {
	cursor: pointer;
	transition: transform 300ms ease-in-out;
	transform: translateX(0);
	&:hover {
		transform: translateY(-0.5em);
	}
}
.ts-clickable {
	pointer-events: auto;
	cursor: pointer;
}
.not-found-status {
	// filter: grayscale(100%) sepia(50%) !important;
	opacity: 0.7;
}
</style>

<style lang="scss">
html[data-tree-open] .customizer .customizer-toggle {
	transition: all 400ms ease-in-out;
	opacity: 0 !important;
	pointer-events: none !important;
}
.tree-modal__actions {
	display: flex;
	color: var(--primary);
	position: absolute;
	top: 3.2vmin;
	right: 3.2vmin;
	column-gap: 0.5rem;
	z-index: 5;
}
</style>
