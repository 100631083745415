import D3 from "d3"
import { config, useConfigD3 } from "./config"
import { stringify } from "uuid"

/**
 * @param { Object<string, any> } dataset
 * @param { import('vue').ComponentOptions<Vue, {}> } vm
 */
export const D3FamilyTree = (dataset, vm) => {

	console.log(dataset);
	const { plot, tree } = useConfigD3("#root-d3-tree")
	// Aqui se siguen anidando forEachs para que al abrir pedigree, se muestren automaticamente mas generaciones
	dataset._parents.forEach(function (generation2) {
		if (Array.isArray(generation2._parents)) {
			// generation2._parents.forEach((e) => collapse(e))
			generation2._parents.forEach(function (generation3) {
				if (Array.isArray(generation3._parents)) {
					generation3._parents.forEach((e) => collapse(e))
				} else {
					collapse(generation3)
				}
			})
		} else {
			collapse(generation2)
		}
	})

	dataset.x0 = 0
	dataset.y0 = 0
	drawSpecimen(dataset)

	function drawSpecimen(source) {
		const nodes = tree.nodes(dataset)
		const links = tree.links(nodes)
		const link = plot.selectAll("path.link").data(links, function (d) {
			return d.target.id
		})

		link.enter()
			.append("path")
			.attr("class", "link")
			.attr("d", function (d) {
				var o = { x: source.x0, y: source.y0 + config.node.width / 2 }
				return transitionElbow({ source: o, target: o })
			})
			.style("fill-opacity", 0)

		link.transition()
			.ease(d3.ease("back-in-out"))
			.duration(config.duration)
			.attr("d", elbow)
			.style("fill-opacity", 1)

		link.exit()
			.transition()
			.duration(config.duration - 50)
			.style("fill-opacity", 0)
			.attr("d", function (d) {
				var o = { x: source.x, y: source.y + config.node.width / 1.5 }
				return transitionElbow({ source: o, target: o })
			})
			.remove()

		const node = plot.selectAll("g.person").data(nodes, function (specimen) {
			return specimen.id
		})

		const enterSpecimenCard = node
			.enter()
			.append("g")
			.attr("class", "person")
			.attr("transform", function (person) {
				return "translate(" + (source.y0 + config.node.width / 2) + "," + source.x0 + ")"
			})

		const updateSpecimenCard = node
			.transition()
			.ease(d3.ease("back-out"))
			.duration(config.duration)
			.attr("transform", function (d) {
				return "translate(" + d.y + "," + d.x + ")"
			})

		const exitSpecimenCard = node
			.exit()
			.transition()
			.ease(d3.ease("quad-in-out"))
			.duration(config.duration - 50)
			.attr("transform", function (d) {
				return "translate(" + (source.y + config.node.width / 2) + "," + source.x + ")"
			})
			.remove()

		const toogleNode = enterSpecimenCard
			.filter((node) => Boolean(node._parents.length) && node.depth < 4)
			.append("g")
			.attr({
				width: 20,
				height: 20,
				class: "cursor-pointer tsd-toogler",
			})
			.on("click", toggleSpecimen)

		toogleNode.append("rect").attr({
			width: 16,
			height: 16,
			transform: `translate(116, -8)`,
			fill: "transparent",
		})

		toogleNode.append("path").attr({
			d: "M13.384 22.134 20.517 15l-7.133-7.134-1.768 1.768L16.983 15l-5.367 5.366 1.768 1.768Z",
			fill: "#A1A1A1",
			transform: `translate(109, -15)`,
		})

		const enterSpecimenCardContent = enterSpecimenCard.append("g").attr({
			transform: `translate(${-(config.node.width / 2)}, 0)`,
			class: "sg-text",
		})

		const enterSpecimenCardActions = enterSpecimenCard.append("g").attr({
			transform: `translate(0, 0)`,
			class: "sg-actions",
		})

		const updateSpecimenCardActions = updateSpecimenCard.select("g.sg-actions").attr({
			transform: `translate(${88}, ${-(config.node.height / 3 + 12)})`,
		})

		const exitSpecimenCardActions = exitSpecimenCard.select("g.sg-actions").attr({
			transform: `translate(0, 0)`,
		})

		enterSpecimenCard.append("rect").attr({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rx: 0,
			class: "card",
			style: "stroke-opacity: 0",
		})

		updateSpecimenCard.select("rect.card").attr({
			x: -(config.node.width / 2),
			y: -(config.node.height / 2),
			width: config.node.width,
			height: config.node.height,
			rx: 10,
			style: "stroke-opacity: 1",
		})

		exitSpecimenCard.select("rect.card").attr({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rx: 0,
			style: "stroke-opacity: 0",
		})

		enterSpecimenCard.append("path").attr({
			d: () => "M280 0a9.695 9.695 0 0 1-8.963 6.5H9.237A10 10 0 0 1 .577.999L0 0h280Z",
			class: "line",
			transform: "translate(-140, 80)",
			"fill-opacity": "0",
		})

		enterSpecimenCardContent
			.append("text")
			.attr({
				dx: 24,
				dy: -10,
				"text-anchor": "center",
				class: "name",
			})
			.text(function (specimen) {
				return specimen.plate || "Sin placa"
			})
			.style("fill-opacity", 0)

		enterSpecimenCard
			.filter((node) => parseInt(node.ran) === 1)
			.append("path")
			.attr({
				d: () =>
					"M0 4.799c3.385 2.494 6.918 2.88 10.705 1.094v1.801c-.484.037-.975.03-1.436.124-1.324.292-2.373 1.386-2.485 2.954-.03.386.238.693.588.714.35.022.632-.248.67-.634.118-1.24.907-1.94 2.187-1.947h1.004c.513 0 .744-.219.744-.73.007-1.13 0-2.26.007-3.39 0-.49.335-.898.781-.978.513-.095.997.146 1.175.613.053.145.067.306.067.459.008 1.13-.007 2.26.008 3.391a4.88 4.88 0 0 1-.722 2.633c-.454.737-.84 1.48-.9 2.363H1.963c-.044-.175-.059-.365-.133-.525-.238-.518-.446-1.05-.759-1.524C.41 10.225.007 9.175.015 7.979.015 6.943 0 5.9 0 4.799Z M6.182 0c1.13 0 2.254-.007 3.384 0 1.37.007 2.44.853 2.753 2.159.067.299.074.496-.298.642-.617.24-.982.743-1.212 1.356a.945.945 0 0 1-.357.452 8.346 8.346 0 0 1-9.143-.394 6.501 6.501 0 0 1-1.026-.933c-.305-.336-.328-.759-.216-1.203C.394.839 1.443.015 2.767 0h3.415Zm1.004 17H2.351c-.833 0-1.37-.489-1.384-1.24-.007-.773.536-1.305 1.369-1.305 3.213-.008 6.42 0 9.633 0 .848 0 1.369.496 1.369 1.29 0 .781-.506 1.255-1.354 1.255H7.186Z",
				fill: (node) => {
					if (Boolean(node.ran_owner)) {
						return "var(--ran-owner)"
					}
					if (Boolean(node.ran_affected)) {
						return "var(--ran-affected)"
					}
				},
				transform: function (specimen, i) {
					const width = enterSpecimenCard
						.select("text.name")
						.filter((node) => parseInt(node.ran) === 1)[0]
						[i].getBoundingClientRect().width
					return `translate(${width - 100}, -26)`
				},
			})

		enterSpecimenCardContent
			.append("text")
			.attr({
				dx: 60,
				dy: 34,
				"text-anchor": "center",
				class: "ts-plate",
				fill: (specimen) => {
					return !specimen.alias ? "var(--warning)" : "currentColor"
				},
			})
			.text(function (specimen) {
				return specimen.alias || " ----"
			})
			.style("fill-opacity", 1)

		enterSpecimenCardContent.append("path").attr({
			d: config.icons.plate,
			class: "plate-icon",
			transform: `translate(24, 18)`,
			fill: "currentColor",
		})

		enterSpecimenCardContent
			.append("text")
			.attr({
				dx: 60,
				dy: config.node.height / 2.8,
				"text-anchor": "center",
				class: "ts-date",
				fill: (specimen) => (specimen.date ? "currentColor" : "var(--warning)"),
			})
			.text(function (specimen) {
				return specimen.date || "----"
			})
			.style("fill-opacity", 1)

		enterSpecimenCardContent.append("path").attr({
			d: config.icons.date,
			class: "date-icon",
			transform: `translate(${26}, ${config.node.height / 3.8})`,
			fill: "currentColor",
		})

		drawFeatureActions(enterSpecimenCardActions, updateSpecimenCard, exitSpecimenCard, vm)

		const imageConfig = {
			x: -(config.node.width / 2) + 28,
			y: -(config.node.width / 2) + 10,
			width: 74,
			avatar: require("@/assets/images/rooster.jpg"),
		}

		// draw button to swith current
		enterSpecimenCard
			.filter((node) => {
				if (vm.isInvitado) return false
				if (node.depth != 0 && node.parent.chick_id == null) {
					return true
				}
				return false
			})
			.append("path")
			.attr({
				//
				d: config.icons.switch,
				class: "delete-action ts-action",
				transform: `translate(-152, -12)`,
				fill: "currentColor",
			})
			.on("click", (node) => vm.switchSpecimen(node))

		enterSpecimenCard
			.append("image")
			.attr({
				x: 0,
				y: config.node.width / 2,
				width: 0,
				height: 0,
				style: "clip-path: inset(0% round 37px)",
				"xlink:href": function (node) {
					const _this = this
					if (this.getAttribute("href") !== imageConfig.avatar) {
						_this.onerror = function () {
							_this.setAttribute("href", imageConfig.avatar)
							if (node.status !== 1) {
								_this.classList.add("not-found-status")
							}
						}
					}

					return node.image || imageConfig.avatar
				},
				preserveAspectRatio: "xMinYMin slice",
			})
			.on("click", (node) => {
				vm.navigateToSpecimen(node.specimen_id)
			})

		enterSpecimenCard
			.filter((node) => node.inside === "SI")
			.append("path")
			.attr({
				d: () =>
					"M15.505 6.908c-2.154-2.15-4.303-4.305-6.457-6.455-.612-.607-1.48-.601-2.098 0-.346.335-.686.68-1.026 1.021C4.1 3.3 2.274 5.121.454 6.954c-.528.532-.602 1.298-.188 1.888.34.482.822.67 1.44.64v4.676c0 1.111.737 1.843 1.848 1.843H5.85c.046 0 .091 0 .13-.006H10a.72.72 0 0 0 .114.006h2.268c.062 0 .119 0 .18-.006h.126v-.011c1.003-.114 1.598-.805 1.598-1.889V9.49c.085-.006.12-.012.16-.012.668-.005 1.173-.3 1.428-.919.26-.618.107-1.174-.369-1.65Zm-4.422 3.397-.618.193v1.98a7.768 7.768 0 0 1-1.287.402c-.42.085-.805.13-1.168.13-.618 0-1.156-.13-1.621-.385A2.694 2.694 0 0 1 5.3 11.48c-.26-.504-.385-1.123-.385-1.854 0-.726.125-1.35.38-1.866A2.685 2.685 0 0 1 6.4 6.579c.482-.272 1.06-.409 1.74-.409.375 0 .76.04 1.152.125.397.085.8.21 1.207.38l-.102 1.776h-.952l-.227-.908a4.486 4.486 0 0 0-.374-.09 2.798 2.798 0 0 0-.556-.057c-.34 0-.635.079-.879.238-.243.159-.43.397-.56.726-.126.323-.194.732-.194 1.23 0 .681.125 1.214.38 1.606.255.391.647.59 1.168.59.136 0 .284-.017.442-.046.102-.017.204-.045.312-.073v-1.175l-.623-.147v-.828l2.744-.045v.833h.005Z",
				fill: "currentColor",
				transform: `translate(114, 56)`,
			})

		enterSpecimenCard
			.filter((node) => node.chick_id != null)
			// .filter((node) => true)
			.append("path")
			.attr({
				d: config.icons.paw,
				fill: "currentColor",
				transform: `translate(90, 55) scale(0.3)`,
			})

		updateSpecimenCard.select("path.line").attr({
			d: () => "M280 0a9.695 9.695 0 0 1-8.963 6.5H9.237A10 10 0 0 1 .577.999L0 0h280Z",
			fill: (specimen) => {
				if (specimen.type === "padrillo") {
					return "#00E2B7"
				}
				if (specimen.type === "madrilla") {
					return "#FF5749"
				}
				return "transparent"
			},
			transform: "translate(-140, 84)",
			"fill-opacity": "1",
		})

		enterSpecimenCardContent
			.select("text")
			.attr("dx", 24)
			.attr("dy", -10)
			.attr("class", "name")
			.style("fill-opacity", 1)

		updateSpecimenCard.select("image").attr({
			x: imageConfig.x,
			y: imageConfig.y,
			width: imageConfig.width,
			height: imageConfig.width,
			style: (node) => {
				if (node.status == 1) {
					return "clip-path: inset(0% round 37px)"
				}
				return "clip-path: inset(0% round 37px); filter: grayscale(1)"
			},
			class: "px-scale-pointer",
		})

		exitSpecimenCard.select("text").style("fill-opacity", 0).attr("dx", 0)

		exitSpecimenCard.select("image").attr({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		})

		nodes.forEach(function (specimen) {
			specimen.x0 = specimen.x
			specimen.y0 = specimen.y
		})
	}

	function toggleSpecimen(specimen) {
		if (specimen.collapsed) {
			specimen.collapsed = false
		} else {
			collapse(specimen)
		}
		drawSpecimen(specimen)
	}

	function collapse(specimen) {
		specimen.collapsed = true
		if (specimen._parents) {
			specimen._parents.forEach(collapse)
		}
	}

	return (source) => {
		source.x0 = 0
		source.y0 = 0
		drawSpecimen(source, true)
	}
}

/**
 * @param { D3.Selection<D3.layout.tree.Node> } enter
 * @param { D3.Selection<D3.layout.tree.Node> } update
 * @param { D3.Selection<D3.layout.tree.Node> } exit
 */
function drawFeatureActions(enter, update, exit, vm) {
	enter
		.append("svg:g")
		.attr({
			class: "ts-clickable",
			fill: "none",
			width: 15,
			height: 15,
		})
		.on("click", (node) => {
			vm.editSpecimen(node)
		})
		.append("path")
		.attr({
			d: () => "",
			class: "edit-action ts-action",
			transform: `translate(0, 0)`,
			fill: "currentColor",
		})

	update
		.select("g path.edit-action")
		.filter((node) => !vm.isInvitado && node.depth !== 0)
		.attr({
			d: () => config.icons.edit,
			transform: `translate(24, 0)`,
		})

	exit.select("path.edit-action").attr({
		d: "",
		transform: `translate(0, 0)`,
	})

	enter
		.filter((node) => {
			return (!Boolean(node.padrillo_id) || !Boolean(node.madrilla_id)) && node.depth < 3
		})
		.append("svg:g")
		.attr({
			class: "ts-clickable",
			fill: "none",
			width: 15,
			height: 15,
		})
		.on("click", (node) => {
			vm.addSpecimen(node)
		})
		.append("path")
		.attr({
			d: () => "",
			class: "add-action ts-action",
			transform: `translate(24, 24)`,
			fill: "currentColor",
		})

	update
		.select("g path.add-action")
		.filter((node) => {
			return !vm.isInvitado && (!Boolean(node.padrillo_id) || !Boolean(node.madrilla_id))
		})
		.attr({
			d: () => config.icons.add,
			transform: `translate(24, 24)`,
		})

	exit.select("path.add-action").attr({
		d: () => "",
		transform: `translate(0, 0)`,
	})

	enter
		.filter((node) => !Boolean(true))
		.append("path")
		.attr({
			d: () => config.icons.delete,
			class: "delete-action ts-action",
			transform: `translate(24, 50)`,
			fill: "currentColor",
		})
		.on("click", vm.deleteSpecimen)
}

/**
 *
 * @param { Object<string, D3.TreeLayout.Node> } d
 * @returns
 */
function elbow(d) {
	var sourceX = d.source.x,
		sourceY = d.source.y + config.node.width / 2,
		targetX = d.target.x,
		targetY = d.target.y - config.node.width / 2,
		startY = d.source.y + config.node.width / 2 + 8,
		endY = d.target.y - config.node.width / 2 - 8

	return "M" + startY + "," + sourceX + "H" + (sourceY + (targetY - sourceY) / 2) + "V" + targetX + "H" + endY
}

function transitionElbow(d) {
	return "M" + (d.source.y + 8) + "," + d.source.x + "H" + d.source.y + "V" + d.source.x + "H" + (d.source.y - 8)
}
