<template>
  <div class="m-1">
    <div class="mt-2 mb-3 container-header">
      <div class="container-body-header">
        <div class="d-flex align-items-center">
          <p
            class="page-btn"
            :class="[pageActive == 1 ? 'disabled' : null]"
            @click="changePage(pageIn - 1)"
          >
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </p>
          <div class="container-btns">
            <p
              @click="changePage(num)"
              class="page-btn"
              :class="[pageActive == num ? 'active' : null]"
              v-for="(num, index) in pages"
              :key="index"
            >
              {{ num }}
            </p>
          </div>
          <p
            class="page-btn"
            :class="[pageActive == totalPages ? 'disabled' : null]"
            @click="changePage(pageIn + 1)"
          >
            <feather-icon icon="ChevronRightIcon" size="20" />
          </p>
        </div>
        <div
          class="d-flex align-items-center justify-content-center mt-2 mt-md-0"
        >
          <input
            disabled
            type="text"
            class="form-control"
            placeholder="Buscar padrillo"
            v-model="campo"
            v-on:keyup.enter="keyEnter"
          />
          <button class="btn btn-primary btn-buscar-placa">
            Buscar placa
          </button>
        </div>
      </div>
    </div>

    <div class="m-auto card content-table">
      <div class="container-table-reports header-table header-sticky">
        <div class="header-table-icon">
          <p>#</p>
        </div>
        <div class="header-table-icon">
          <p>Padrillo</p>
        </div>
        <div class="header-table-icon">
          <p>Madrilla</p>
        </div>

        <div class="header-table-icon">
          <p>Total</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/007-gallo-galo_ippt4p.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Jugadas</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/001-tweezer_gi7x2h.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Victorias</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/001-exito_sptglr.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Empate</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/005-sello_b1ar7t.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Derrotas</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/002-perder_o39iua.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Efectividad</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654122331/amg/004-polla_mwaaze.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Puntaje</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/001-calculator_maigky.png"
            alt=""
          />
        </div>
        <div class="header-table-icon">
          <p>Acciones</p>
          <img
            src="https://res.cloudinary.com/mikunaalli/image/upload/v1654277378/amg/002-double-click_jbyacr.png"
            alt=""
          />
        </div>
      </div>

      <div
        class="container-table-reports body-table"
        v-for="(item, index) in data"
        :key="index"
        :class="[
          item.total_effect >= 50 && item.results.total_effect != 'Sin jugar'
            ? 'table-1'
            : '',
          item.total_effect >= 25 && item.total_effect < 50
            ? 'table-2' && item.results.total_effect != 'Sin jugar'
            : '',
          item.total_effect < 25 && item.results.total_effect != 'Sin jugar'
            ? 'table-3'
            : ''
        ]"
      >
        <div
          class="d-flex align-items-center justify-content-center body-index"
        >
          <p class="number-report">{{ index + 1 }}</p>
        </div>

        <div
          class="d-flex align-items-center justify-content-center body-index"
        >
          <div class="container-padrillo">
            <b-avatar
              size="40"
              button
              :src="item.padrillo.thumb"
              :text="avatarText(item.padrillo.alias)"
              :disabled="!item.padrillo.thumb"
              class="mr-1"
            />
            <div class="d-flex align-items-center padrillo-flex">
              <div class="padrillo">
                <p class="alias">
                  {{
                    item.padrillo.alias ? item.padrillo.alias : "------------"
                  }}
                </p>
                <div
                  @click="doCopy(item.padrillo.plate)"
                  class="plate cursor-pointer"
                >
                  <feather-icon class="icon" icon="ClipboardIcon" />
                  <p class="text-plate">
                    {{ item.padrillo.plate }}
                  </p>
                </div>
              </div>
              <feather-icon
                @click="
                  selectPadrillo(
                    item.padrillo.plate,
                    'padrillo',
                    item.padrillo.alias
                  )
                "
                class="cursor-pointer"
                icon="EyeIcon"
                color="#28A745"
                size="18"
              />
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <div class="container-padrillo ">
            <b-avatar
              size="40"
              button
              :src="item.madrilla.thumb"
              :text="avatarText(item.madrilla.alias)"
              :disabled="!item.madrilla.thumb"
              class="mr-1"
            />
            <div class="d-flex align-items-center padrillo-flex">
              <div class="padrillo">
                <p class="alias">
                  {{
                    item.madrilla.alias ? item.madrilla.alias : "------------"
                  }}
                </p>
                <div
                  @click="doCopy(item.madrilla.plate)"
                  class="plate cursor-pointer"
                >
                  <feather-icon class="icon" icon="ClipboardIcon" />
                  <p class="text-plate">
                    {{ item.madrilla.plate }}
                  </p>
                </div>
              </div>
              <feather-icon
                @click="
                  selectPadrillo(
                    item.madrilla.plate,
                    'madrilla',
                    item.madrilla.alias
                  )
                "
                class="cursor-pointer"
                icon="EyeIcon"
                color="#28A745"
                size="18"
              />
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <p @click="selectPostura(item, 1)" class="number-report">
            {{ item.count }}
          </p>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <p @click="selectPostura(item, 2)" class="number-report">
            {{ item.results.plays }}
          </p>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <p @click="selectPostura(item, 3)" class="number-report">
            {{ item.results.victories }}
          </p>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <p @click="selectPostura(item, 4)" class="number-report">
            {{ item.results.draws }}
          </p>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <p @click="selectPostura(item, 5)" class="number-report">
            {{ item.results.defeats }}
          </p>
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center body-cell"
        >
          <p class="number-report">
            {{ item.results.total_effect }}
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-center body-cell">
          <p class="number-report">
            {{
              `${
                item.results.score == 0
                  ? item.results.score
                  : `+${item.results.score}`
              }`
            }}
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-center body-cell">
          <feather-icon
            class="cursor-pointer icon-eye"
            icon="EyeIcon"
            color="#28A745"
            :size="width < 600 ? '16' : '20'"
            @click="selectPostura(item, 2)"
          />
          <smart-icon
            @click="toggleFavorite(item.padrillo.id, item.madrilla.id, index)"
            v-if="item.favorite"
            color="#ff9f43"
            class="cursor-pointer"
            icon="Star1Icon"
            :size="width < 600 ? '18' : '24'"
          />
          <feather-icon
            @click="toggleFavorite(item.padrillo.id, item.madrilla.id, index)"
            v-else
            class="icon text-warning cursor-pointer"
            icon="StarIcon"
            :size="width < 600 ? '16' : '20'"
          />
        </div>
      </div>
    </div>

    <b-modal
      v-if="posturaSelect"
      :title="
        `Reporte del encaste ${
          posturaSelect.padrillo.alias ? posturaSelect.padrillo.alias : ''
        } (${
          posturaSelect.padrillo.plate ? posturaSelect.padrillo.plate : ''
        }) - ${
          posturaSelect.madrilla.alias ? posturaSelect.madrilla.alias : ''
        } (${posturaSelect.madrilla.plate ? posturaSelect.madrilla.plate : ''})`
      "
      v-model="modalDetailsPostura"
      size="lg"
      body-class="p-0 m-0"
      hide-footer
    >
      <ViewDetailsReportPostura :item="posturaSelect" />
    </b-modal>
  </div>
</template>

<script>
import { getReportsPosturas } from "@/services/reports.service";
import ViewDetailsReportPostura from "./ViewDetailsReportPostura.vue";
import { useClipboard } from "@vueuse/core";
import { avatarText } from "@core/utils/filter";

export default {
  name: "SearchPosturasByPlate",
  props: ["plate"],
  components: {
    ViewDetailsReportPostura
  },
  setup() {
    return {
      avatarText
    };
  },
  data() {
    return {
      data: [],
      width: this.$store.state["app"].windowWidth,
      pageIn: 1,
      perPageIn: 10,
      campo: null,
      pages: [],
      pageActive: 1,
      totalPages: null,
      modalDetailsPostura: false,
      posturaSelect: null,
      modalSearchPadrillo: false,
      plateSelect: null,
      posturaSelect: null,
      modalDetailsPostura: false
    };
  },
  async mounted() {
    console.log("Plate: ", this.plate);
    this.campo = this.plate.plate;
    await this.init();
  },
  methods: {
    selectPostura(item) {
      this.modalDetailsPostura = true;
      this.posturaSelect = item;
      //   console.log("item: ", item);
    },
    async init() {
      const {
        data: { data, last_page, current_page }
      } = await getReportsPosturas({
        pageIn: this.pageIn,
        perPAgeIn: this.perPageIn,
        orderbyIn: "id",
        ordenIn: "desc",
        campoIn: this.campo
      });
      this.data = data.map(item => {
        return {
          ...item,
          data: JSON.parse(item.data),
          // madrilla: JSON.parse(item.madrilla),
          // padrillo: JSON.parse(item.padrillo),
          results: JSON.parse(item.results),
          total_effect: parseFloat(item.total_effect)
        };
      });
      // console.log("RESP: ", this.data);
      this.pages = this.createPages(last_page);
      this.pageActive = current_page;
      this.totalPages = last_page;
    },
    createPages(num) {
      let pages = [];
      for (let i = 1; i <= num; i++) {
        pages.push(i);
      }
      return pages;
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.pageIn = page;
        this.init();
      }
    },
    keyEnter() {
      this.pageIn = 1;
      this.init();
    },
    doCopy(message) {
      const { copy } = useClipboard();

      copy(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.container-header {
  background: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: 0.1s all ease-in-out;
  @media screen and (min-width: 700px) {
    padding: 1rem;
  }
  .page-btn {
    height: 32px;
    width: 32px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
    border-radius: 32px;
    cursor: pointer;
    background: #f3f2f7;
  }
  .container-btns {
    margin: 0 0.4rem;
    width: auto;
    background: #f3f2f7;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    transition: 0.1s all ease-in-out;
    .active {
      background: #7367f0;
      color: #fff;
    }
  }
  .disabled {
    color: #d5d5d5;
  }
}
.dark-layout {
  .container-header {
    background: #283046;
    .page-btn {
      background: #242b3d;
    }
    .container-btns {
      background: #242b3d;
      .active {
        background: #7367f0;
        color: #fff;
      }
    }
  }
}
//min-width
.content-table {
  min-width: 100%;
  background: #fff;

  border-radius: 10px;
  overflow-x: scroll;
  overflow-y: visible;
  @media screen and (min-width: 1000px) {
    overflow-x: visible;
    overflow-y: visible;
  }
  .container-table-reports {
    // min-width: 800px;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 4fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
    text-align: center;
    @media screen and (min-width: 600px) {
      padding: 1rem 2rem;
    }

    p {
      margin: 0;
      font-size: 12px;
      transition: 0.1s all ease-in-out;
      @media screen and (min-width: 600px) {
        font-size: 14px;
      }
    }
    .icon {
      //color: red;
      margin: 0 0.2rem;
      cursor: pointer;
    }
  }
  .header-table {
    transition: 0.1s all ease-in-out;
    background: #f3f2f7;
    text-align: center;
    p {
      font-weight: 600;
    }
  }
  .body-table {
    border-bottom: 0.5px solid #e6e6e6;
    transition: 0.1s all ease-in-out;
    &:last-child {
      border-bottom: none;
    }
    .body-index {
      //   min-width: 30.88px;
    }
    .body-cell {
      //   min-width: 123.52px;
      p {
        word-wrap: break-word;
      }
      .badge {
        width: 80%;
        @media screen and (min-width: 600px) {
          width: 50% !important;
        }
      }
    }
  }
}
.dark-layout {
  .content-table {
    background: #283046;
    .header-table {
      background: #343d55;
    }
    .body-table {
      border-bottom-color: #3b4253;
    }
  }
}
.btn-agregar-organizer {
  width: 180px;
}
.circle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 4px;
}
.container-body-header {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
.font-other-name {
  font-size: 12px;
  font-weight: bold;
}
.header-table-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 10px;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: cover;
  }
}
.btn-buscar-placa {
  width: 220px !important;
  margin-left: 1rem;
}
.container-padrillo {
  display: flex;
  align-items: center;
  .padrillo {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    .alias {
    }
    .plate {
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      transition: 0.2s all ease-in-out;
      padding: 0.05rem 0.2rem !important;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      .icon {
        color: #9c97da;
        font-weight: bold;
      }
      .text-plate {
        color: #9c97da;
        font-weight: bold;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
.number-report {
  font-weight: bold;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  &:hover {
    color: #9c97da;
  }
}
.icon-eye {
  margin-right: 0.5rem;
}
.table-1 {
  background: #ddfcdd;
}
.table-2 {
  background: #bbf4fe;
}
.table-3 {
  background: #fecccb;
}
</style>
