<template>
	<div>

		<b-modal centered no-close-on-backdrop hide-footer size="xmd" :title="titleModal" v-model="isActive" @hidden="handleHidden">
			<b-overlay :show="isLoading">
			<b-table-simple sticky-header="50vh">
				<b-thead class="text-center" @click="handleClick">
					<b-tr>
						<b-th>Ejemplar</b-th>
						<b-th>Rival</b-th>
						<b-th>Torneo</b-th>
						<b-th>Fecha</b-th>
						<b-th>Video</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<template>
						<b-tr v-for="(row, index) in rows" :key="index">
							<b-td>
								<SpecimenPlate
									:specimen="{
										plate: row.specimen.plate,
										alias: row.specimen.alias,
										id: row.specimen.id,
										thumb: row.specimen.thumb,
										image: row.specimen.image,
									}"
									is_specimen
									is_icon
								/>
								<small class="d-block mt-05 text-left">
									<b-badge :variant="colorizeSpecimenStatus(row.status_local)">
										Estado:
										<b>{{ row.status_local }}</b>
									</b-badge>
								</small>
								<small class="d-block mt-05 text-left">
										Origen:
									<b>{{ row.origin.name }}</b>
								</small>
							</b-td>
							<b-td>{{ row.rival_name }}</b-td>
							<b-td>{{ row.tournament_name }}</b-td>
							<b-td>{{ row.date | myGlobalDayShort }}</b-td>
							<b-td>
								<p v-if="!row.video || row.video.status != '2'">Sin video</p>
								<b-button
									v-else
									class="p-0 m-0"
									@click="validActiveToVideoTope(row)"
									v-b-tooltip.hover
									:title="'Video listo'"
								>
									<b-avatar
										:src="row.video.thumb"
										rounded
										size="80"
										icon="film"
										badge
										badge-variant="danger"
									>
										<template #badge>{{ row.video.duration }}</template>
									</b-avatar>
								</b-button>
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
			</b-table-simple>
		</b-overlay>

		</b-modal>
		<b-modal
			id="modal-xl"
			ok-only
			ok-title="Accept"
			centered
			size="xl"
			title=""
			v-model="videoPlayerController"
			hide-footer
			>
			<b-embed type="video" aspect="21by9" controls allowfullscreen>
				<source :src="selectedVideoUrl" type="video/webm" />
			</b-embed>
		</b-modal>
	</div>
	</template>

<script>
import VueApexCharts from "vue-apexcharts"
import { mapActions, mapState } from "vuex"
import ConfrontationsResultService from '@/services/confrontationsResults.service.js'
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	name: "EffectivenessForSeason",
	components: { 
		VueApexCharts,
		SpecimenPlate,
	},
	props: {
		show: Boolean,
		info: Object,
		seasonData: Object,
	},
	data: () => ({
		isActive: false,
		buttonInactive: false,
		isLoading: false,
		videoPlayerController: null,
		selectedVideoUrl: null,
		titleModal: null,
		rows: [],
		dataVideo: {},
		updateVideo: {
				show: false,
				info: null,
				title: "",
			},
	}),
	computed: {
		totalPercentage() {
			return this.lines.reduce((pre, cur) => cur.percentage + pre, 0)
		},
	},
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setTextImage", "setImages"]),
		handleClick(){
			this.rows.forEach(element => {
				console.log(element.video)		
			})
		},
		handleHidden() {
			this.lines = [{ breeder: null, percentage: null }]
			this.breederOpts = []
			this.rows= []
			this.titleModal= null
			this.prevSelected = []
			this.$emit("closing")
		},
		colorizeSpecimenStatus(status) {
			switch (status) {
				case "LIMPIO":
					return "success"
				case "MUERTO":
					return "danger"
				case "HERIDO":
					return "warning"
				default:
					return "secondary"
			}
		},
		showTopeVideo(row) {

			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.uid,
					text: `${row.specimen.alias ?? row.specimen.plate} vs ${row.rival_name}`,
					specimen: { id: row.specimen.id },
				},
			])
			this.setTextImage({
				title: `${row.specimen.alias ?? row.specimen.plate} vs ${row.rival_name}`,
				url: `/ejemplares/detalle/${row.specimen.id}`,
			})
			this.setIndexImage(0)
			this.toggleModal()
		},
		async validActiveToVideoTope(row) {
			this.isPreloading()
			this.showTopeVideo(row)
		},
		async getConfrontationsResultsFromChildreen(dataSeasonInfo){
			this.isPreloading()
			if(!dataSeasonInfo.dataSelected){
				const { data } = await ConfrontationsResultService.getResultsBySpecimenChilds(
				this.info.category,
				this.info.id,
				null,
				null,
				dataSeasonInfo.counterResults.id,
				)
				this.titleModal = `Efectividad de hijos / Todos los años / ${dataSeasonInfo.counterResults.name}`
				this.rows = data;
				this.isPreloading(false)
				return
			}
			const { data } = await ConfrontationsResultService.getResultsBySpecimenChilds(
				this.info.category,
				this.info.id,
				dataSeasonInfo.dataSelected.start,
				dataSeasonInfo.dataSelected.end,
				dataSeasonInfo.counterResults.id,
				)

			this.titleModal = `Efectividad de hijos / ${dataSeasonInfo.dataSelected.season} / ${dataSeasonInfo.counterResults.name}`
			this.rows = data;
			this.isPreloading(false)
		}
	},
	watch: {
		show(val) {
			if (val) {
			this.isActive = val
			}
		},
		async seasonData(val){
			if(val){
				await this.getConfrontationsResultsFromChildreen(val)
			}
		}
	}
}
</script>