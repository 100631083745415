<template>
	<div>
		<ecommerce-statistics
			:data="data"
			:title="'Pollos vivos'"
			:caption="today | myGlobalDayWhour"
			clickableItems
			@onClickItem="changeTab"
		/>
		<b-tabs
			content-class="mt-2"
			lazy
			nav-wrapper-class="card pt-1 pr-1 pl-1"
			:active-nav-item-class="tabIndex == 1 ? 'custom-tab-danger' : tabIndex == 2 ? 'custom-tab-success' : ''"
			v-model="tabIndex"
		>
			<b-tab title="Cintillo pata">
				<specimen-chicken-cintillo-pata :specimenData="specimenData"></specimen-chicken-cintillo-pata>
			</b-tab>
			<b-tab title="Cintillo ala">
				<specimen-chicken-cintillo-ala :specimenData="specimenData"></specimen-chicken-cintillo-ala>
			</b-tab>
			<b-tab title="Placa">
				<specimen-chicken-plate :specimenData="specimenData"></specimen-chicken-plate>
			</b-tab>
			<div></div>
		</b-tabs>
	</div>
</template>

<script>
import SpecimenChickenCintilloPata from "./SpecimenChickenCintilloPata.vue";
import SpecimenChickenCintilloAla from "./SpecimenChickenCintilloAla.vue";
import SpecimenChickenPlate from "./SpecimenChickenPlate.vue";
import moment from "moment";
export default {
	components: {
		SpecimenChickenCintilloPata,
		SpecimenChickenCintilloAla,
		SpecimenChickenPlate,
	},
	props: {
		specimenData: Object,
	},
	mounted() {
		this.getSpecimenCounters();
		this.today = moment().format("YYYY-MM-DD");
	},
	data() {
		return {
			data: [
				{
					color: "light-primary",
					customClass: "mb-2 mb-xl-0",
					icon: "GitBranchIcon",
					subtitle: "Cintillo pata",
					title: 0,
				},
				{
					color: "light-danger",
					customClass: "mb-2 mb-xl-0",
					icon: "Link2Icon",
					subtitle: "Cintillo Ala",
					title: 0,
				},
				{
					color: "light-success",
					customClass: "mb-2 mb-xl-0",
					icon: "GitlabIcon",
					subtitle: "Placa",
					title: 0,
				},
			],
			today: null,
			tabIndex: 0,
		};
	},
	methods: {
		getSpecimenCounters() {
			axios
				.post("/api/specimens/get-chicken-counter", {
					specimen_plate: this.specimenData.plate,
					gender: this.specimenData.gender,
					season: this.specimenData.current_season.toString(),
				})
				.then((res) => {
					let data = res.data;
					this.data[0].title = data[0].cintillo_pata_counter;
					this.data[1].title = data[0].cintillo_ala_counter;
					this.data[2].title = data[0].placa_counter;
				});
		},
		changeTab(index) {
			this.tabIndex = index;
		},
	},
};
</script>

<style>
.nav-tabs .custom-tab-danger:after {
	left: 0;
	/* (115, 103, 240, 0.5) */
	background: linear-gradient(30deg, #ea5455, rgb(63, 52, 71, 0.5)) !important;
}
.custom-tab-danger {
	color: #ea5455 !important;
}
.nav-tabs .custom-tab-success:after {
	left: 0;
	background: linear-gradient(30deg, #28c76f, rgb(40, 66, 74, 0.5)) !important;
}
.custom-tab-success {
	color: #28c76f !important;
}
</style>
