<template>
  <b-modal
    ref="modal-tracking-fields-specimens"
    @hidden="$emit('hidden')"
    :title="titleModal"
    size="lg"
    scrollable
    hide-footer
    title-class="h4 font-weight-bolder"
  >
    <b-table-simple hover small caption-top responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th>{{ paramsModalTracking.title }}</b-th>
          <b-th>Fecha</b-th>
          <b-th>Ingresado por</b-th>
          <b-th>Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(tracking, index) in S_TRACKING_FIELDS"
          :key="`tracking-${index}`"
        >
          <b-th v-if="tracking.type_field === 'DOB'">{{
            tracking.field | myGlobalDayWhour
          }}</b-th>
          <b-th v-else>{{ tracking.field }}</b-th>
          <b-th>{{ tracking.created_at | myGlobalDayWhour }}</b-th>
          <b-th>{{ tracking.user_name }}</b-th>
          <b-th>
            <feather-icon
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              @click="deleteFieldTrackingSpecimen(tracking, index)"
              v-if="isAdmin"
            />
          </b-th>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [modalMixin],
  props: {
    paramsModalTracking: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  mounted() {
    this.toggleModal("modal-tracking-fields-specimens");
  },
  computed: {
    ...mapState("createEditSpecimen", ["S_TRACKING_FIELDS"]),
    titleModal() {
      return `TRACKING ${this.paramsModalTracking.title} - ${this.paramsModalTracking.specimenIdentification}`;
    },
  },
  methods: {
    ...mapActions("createEditSpecimen", [
      "A_GET_TRACKING_FIELDS_SPECIMEN",
      "A_DELETE_TRACKING_FIELD_SPECIMEN",
    ]),

    async deleteFieldTrackingSpecimen(tracking, index) {
      const confirm = await this.showConfirmSwal(
        "Eliminaras un registro del TRACKING"
      );
      if (!confirm.value) return;

      this.isPreloading();
      try {
        const params = {
          tracking_id: tracking.id,
          type_tracking: this.paramsModalTracking.type_tracking,
        };
        await this.A_DELETE_TRACKING_FIELD_SPECIMEN({ params, index });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isPreloading(false);
      }
    },
  },
};
</script>

<style>
</style>
