<template>
	<b-modal centered hide-footer size="xl" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!-- <pre style="max-height: 10rem">{{ rows }}</pre> -->
			<b-row class="mb-1">
				<b-col cols="12" md="8" class="d-flex align-items-center mb-1 mb-md-0">
					<b-tabs v-model="status" @input="getRows">
						<b-tab>
							<template #title>EN ESPERA</template>
						</b-tab>
						<b-tab>
							<template #title>EN PROCESO</template>
						</b-tab>
						<b-tab>
							<template #title>FINALIZADOS</template>
						</b-tab>
						<b-tab>
							<template #title>CANCELADOS</template>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="70vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th>Periodo</b-th>
						<b-th v-if="[2, 3].includes(status)">Estado</b-th>
						<b-th>Razones</b-th>
						<b-th v-if="![0, 3].includes(status)">Diagnosticos</b-th>
						<b-th v-if="![0, 3].includes(status)">Tratamientos</b-th>
						<b-th>Ingresado por</b-th>
						<b-th v-if="status != 0">Actualizado por</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td style="position: relative">
							{{ row.type }}
						</b-td>
						<b-td v-if="[2, 3].includes(status)">
							<div
								v-if="row.is_dead"
								v-b-tooltip.hover
								:title="status == 2 ? 'DE BAJA' : 'MUERTO'"
								class="d-flex justify-content-center"
							>
								<div style="width: 15px; height: 15px; border-radius: 50%; background: red"></div>
							</div>
							<div
								v-else
								v-b-tooltip.hover
								:title="status == 2 ? 'DE ALTA' : 'VIVO'"
								class="d-flex justify-content-center"
							>
								<div style="width: 15px; height: 15px; border-radius: 50%; background: green"></div>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex flex-column align-items-start gap-y-1 text-left">
								<small
									v-for="(reason, idx) in row.reasons"
									:key="idx"
									variant=""
									class="d-flex align-items-center"
								>
									-
									{{ reason }}
								</small>
							</div>
						</b-td>
						<b-td v-if="![0, 3].includes(status)">
							<div class="d-flex align-items-center gap-x-2">
								<div
									class="d-flex flex-column align-items-start gap-y-1 text-left"
									v-if="row.diagnostics.length != 0"
								>
									<small v-for="(diagnostic, idx) in row.diagnostics" :key="idx">
										- {{ diagnostic }}
									</small>
								</div>
								<b-button
									class="text-nowrap"
									variant="outline-secondary"
									size="sm"
									@click="
										;(manageDiagnostic.info = { ...row, _readonly: true }),
											(manageDiagnostic.show = !manageDiagnostic.show)
									"
								>
									{{ row.counter_diagnostic }}
									<feather-icon icon="EyeIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td v-if="![0, 3].includes(status)">
							<div class="d-flex align-items-center gap-x-2">
								<div
									class="d-flex flex-column align-items-start gap-y-1 text-left"
									v-if="row.treatments.length != 0"
								>
									<small v-for="(treatment, idx) in row.treatments" :key="idx">
										- {{ treatment }}
									</small>
								</div>
								<b-button
									class="text-nowrap"
									variant="outline-secondary"
									size="sm"
									@click="
										;(manageTreatment.info = { ...row, _readonly: true }),
											(manageTreatment.show = !manageTreatment.show)
									"
								>
									{{ row.counter_treatment }}
									<feather-icon icon="EyeIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td>
							<span>{{ row.created_by.name }}</span>
							<br />
							<small>{{ row.created_by.date | myGlobalDayWithHour }}</small>
						</b-td>
						<b-td v-if="status != 0">
							<span>{{ row.updated_by.name }}</span>
							<br />
							<small>{{ row.updated_by.date | myGlobalDayWithHour }}</small>
						</b-td>

						<b-td>
							<div class="d-flex justify-content-center align-items-center gap-x-2">
								<b-button
									class="btn-icon"
									variant="outline-secondary"
									@click=";(tracking.info = row), (tracking.show = !tracking.show)"
								>
									<feather-icon icon="ListIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td class="text-center" colspan="7" v-if="rows.length == 0">
							No hay registros para mostrar
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<ManageDiagnosticModal
			:show="manageDiagnostic.show"
			:info="manageDiagnostic.info"
			@closing="manageDiagnostic.show = false"
			@refresh="getRows"
		/>
		<ManageTreatmentModal
			:show="manageTreatment.show"
			:info="manageTreatment.info"
			@closing="manageTreatment.show = false"
			@refresh="getRows"
		/>
		<TrackingModal
			:show="tracking.show"
			:info="tracking.info"
			@closing="tracking.show = false"
			:is_dead="status == 2"
		/>
	</b-modal>
</template>

<script>
import nurseryService from "@/services/nursery/default.service"

import ManageDiagnosticModal from "./ManageDiagnosticModal.vue"
import ManageTreatmentModal from "./ManageTreatmentModal.vue"
import TrackingModal from "./TrackingModal.vue"

export default {
	components: { ManageDiagnosticModal, ManageTreatmentModal, TrackingModal },
	props: {
		show: Boolean,
		info: Object,
		is_dead: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		status: 0,
		rows: [],
		manageDiagnostic: { show: false, info: {} },
		manageTreatment: { show: false, info: {} },
		tracking: { show: false, info: {} },
	}),
	computed: {
		isChick() {
			return !!this.info.chick
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.status = 0
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const data = await nurseryService.getTrackingDashboard({
				specimen_id: this.info.specimen.id,
				status_id: this.status + 1,
			})
			this.rows = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
				if (true) {
					this.title = `TRACKING DE ENFERMERIA / ${this.info.specimen.alias || this.info.specimen.plate}`
				}
			}
		},
	},
}
</script>
