<template>
	<div class="m-auto card content-table">
		<div class="header-table grid-table-sons-by-posturas">
			<div class="header-table-icon">
				<p class="mb-0 p-1">#</p>
			</div>

			<div class="header-table-icon">
				<p class="mb-0 p-1">Ejemplar</p>
			</div>

			<div class="header-table-icon">
				<p class="mb-0 p-1">Estado</p>
			</div>

			<div class="header-table-icon">
				<p class="mb-0 p-1">Género</p>
			</div>
		</div>

		<template v-if="arraySonsByPostura.length > 0">
			<div
				class="grid-table-sons-by-posturas body-table"
				v-for="(item, index) in arraySonsByPostura"
				:key="index"
			>
				<div class="d-flex align-items-center justify-content-center body-index p-1">
					<p class="number-report mb-0">{{ index + 1 }}</p>
				</div>

				<div class="d-flex align-items-center justify-content-center body-index p-1">
					<div class="container-padrillo">
						<b-avatar
							size="40"
							button
							:src="item.preload"
							:text="avatarText(item.alias)"
							:disabled="!item.preload"
							class="mr-1"
							@click="
								selectFile(
									item.url,
									'image',
									`${item.alias == null ? '' : `${item.alias} -`} (${item.plate && item.plate})`,
									`/ejemplares/detalle/${item.id}`
								)
							"
						/>
						<div class="d-flex align-items-center padrillo-flex">
							<div class="padrillo">
								<p class="alias mb-0">
									{{ item.alias ? item.alias : "----------" }}
								</p>
								<div @click="doCopy(item.plate)" class="plate cursor-pointer">
									<feather-icon class="icon" icon="ClipboardIcon" />
									<p class="text-plate mb-0">
										{{ item.plate }}
									</p>
								</div>
							</div>
							<feather-icon
								@click="linkSpecimen(item.id)"
								icon="ExternalLinkIcon"
								size="20"
								class="cursor-pointer text-primary"
							/>
						</div>
					</div>
				</div>

				<div class="d-flex align-items-center justify-content-center body-index p-1">
					<p class="number-report mb-0">{{ item.status }}</p>
				</div>

				<div class="d-flex align-items-center justify-content-center body-index p-1">
					<p class="number-report mb-0">{{ item.gender }}</p>
				</div>
			</div>
		</template>

		<template v-else>
			<div class="body-table">
				<div class="d-flex align-items-center justify-content-center body-index p-1">
					<p class="number-report mb-0">Sin datos</p>
				</div>
			</div>
		</template>

		<SlidersImageVideo
			v-if="dataSelectVideo.open"
			:open="dataSelectVideo.open"
			:url="dataSelectVideo.url"
			:text="dataSelectVideo.text"
			:text_url="dataSelectVideo.text_url"
			:type="dataSelectVideo.type"
			@refreshSlider="refreshSlider"
		/>
	</div>
</template>

<script>
import { getSonsByPostura } from "@/services/reports.service";
import { avatarText } from "@core/utils/filter";
import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue";

export default {
	name: "TabSonsPosturas",
	props: ["TabSonsPosturaIndex", "TabSonsPosturaMadrilla", "TabSonsPosturaPadrillo"],
	components: {
		SlidersImageVideo,
	},
	data() {
		return {
			arraySonsByPostura: [],
			dataSelectVideo: {
				url: null,
				type: null,
				text: null,
				text_url: null,
				open: false,
			},
		};
	},
	setup() {
		return {
			avatarText,
		};
	},
	async mounted() {
		await this.getSons(this.TabSonsPosturaPadrillo, this.TabSonsPosturaMadrilla, this.TabSonsPosturaIndex);
	},
	methods: {
		async getSons(padrillo, madrilla, gender) {
			this.isPreloading();
			const { data, message, status } = await getSonsByPostura({
				padrillo_id: padrillo && padrillo.id,
				madrilla_id: madrilla && madrilla.id,
				gender: gender == 0 ? null : gender,
			});
			// console.log('resp', resp);
			// console.log("RESP: ", data);
			this.arraySonsByPostura = data;
			this.isPreloading(false);
		},
		linkSpecimen(id) {
			window.open(`/ejemplares/detalle/${id}`, "_blank");
		},
		selectFile(url, type, text, text_url) {
			// console.log("image: ", url);
			this.dataSelectVideo.url = url;
			this.dataSelectVideo.type = type;
			this.dataSelectVideo.text = text;
			this.dataSelectVideo.text_url = text_url;
			this.dataSelectVideo.open = true;
		},
		refreshSlider() {
			this.dataSelectVideo.open = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.container-header {
	background: #fff;
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: 0.1s all ease-in-out;
	@media screen and (min-width: 700px) {
		padding: 1rem;
	}
	.page-btn {
		height: 32px;
		width: 32px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;
		border-radius: 32px;
		cursor: pointer;
		background: #f3f2f7;
	}
	.container-btns {
		margin: 0 0.4rem;
		width: auto;
		background: #f3f2f7;
		height: 32px;
		border-radius: 16px;
		display: flex;
		align-items: center;
		transition: 0.1s all ease-in-out;
		.active {
			background: #7367f0;
			color: #fff;
		}
	}
	.disabled {
		color: #d5d5d5;
	}
}
.dark-layout {
	.container-header {
		background: #283046;
		.page-btn {
			background: #242b3d;
		}
		.container-btns {
			background: #242b3d;
			.active {
				background: #7367f0;
				color: #fff;
			}
		}
	}
}
.content-table {
	min-width: 100%;
	background: #fff;

	border-radius: 10px;
	overflow-x: scroll;
	// overflow-y: scroll;
	// height: 43.5vh;
	@media screen and (min-width: 1000px) {
		overflow-x: visible;
		// overflow-y: visible;
	}
	.container-table-reports {
		min-width: 800px;
		// padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 1fr 4fr 1fr 1fr 1fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
		text-align: center;
		@media screen and (min-width: 600px) {
			// padding: 1rem 2rem;
		}

		p {
			margin: 0;
			font-size: 12px;
			transition: 0.1s all ease-in-out;
			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}
		.icon {
			//color: red;
			margin: 0 0.2rem;
			cursor: pointer;
		}
	}
	.header-table {
		transition: 0.1s all ease-in-out;
		background: #f3f2f7;
		text-align: center;
		p {
			font-weight: 600;
		}
	}
	.body-table {
		border-bottom: 0.5px solid #e6e6e6;
		transition: 0.1s all ease-in-out;
		&:last-child {
			border-bottom: none;
		}
		.body-index {
			//   min-width: 30.88px;
		}
		.body-cell {
			//   min-width: 123.52px;
			p {
				word-wrap: break-word;
			}
			.badge {
				width: 80%;
				@media screen and (min-width: 600px) {
					width: 50% !important;
				}
			}
		}
	}
}

.content-table::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}
.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.dark-layout {
	.content-table {
		background: #283046;
		.header-table {
			background: #343d55;
		}
		.body-table {
			border-bottom-color: #3b4253;
		}
	}
}
.btn-agregar-organizer {
	width: 180px;
}
.circle {
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 4px;
}
.container-body-header {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
		justify-content: space-between;
	}
}
.font-other-name {
	font-size: 12px;
	font-weight: bold;
}
.header-table-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
	}
	img {
		height: 24px;
		width: 24px;
		object-fit: cover;
	}
}
.btn-buscar-placa {
	width: 220px !important;
	margin-left: 1rem;
}
.container-padrillo {
	display: flex;
	align-items: center;
	flex: 1;
	width: 100%;
	padding: 0 1rem;
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		// justify-content: center;
		margin-right: 0.5rem;
		.alias {
		}
		.plate {
			background: #fff;
			border-radius: 8px;
			display: flex;
			align-items: center;
			transition: 0.2s all ease-in-out;
			padding: 0.05rem 0.2rem !important;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			.icon {
				color: #9c97da;
				font-weight: bold;
			}
			.text-plate {
				color: #9c97da;
				font-weight: bold;
			}
			&:hover {
				transform: scale(1.05);
			}
		}
	}
}
.number-report {
	font-weight: bold;
	transition: 0.2s all ease-in-out;
	cursor: pointer;
	&:hover {
		color: #9c97da;
	}
}
.icon-eye {
	margin-right: 0.5rem;
}
.table-1 {
	background: #ddfcdd;
}
.table-2 {
	background: #bbf4fe;
}
.table-3 {
	background: #fecccb;
}
.modal-df {
	padding: 1rem !important;
}
.data-null {
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		font-size: 18px;
		font-weight: bold;
		margin: 2rem 0;
		padding-left: 1rem;
	}
}

@keyframes ldio-m8h18h0i76f {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.ldio-m8h18h0i76f > div {
	animation: ldio-m8h18h0i76f 1s infinite linear;
	transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div {
	position: absolute;
}
.ldio-m8h18h0i76f > div div:nth-child(1),
.ldio-m8h18h0i76f > div div:nth-child(2) {
	width: 84px;
	height: 84px;
	border: 14px solid;
	border-radius: 50%;
	border-color: transparent #7367f0 #7367f0 #7367f0;
	box-sizing: border-box;
	position: absolute;
	transform: rotate(45deg);
	transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div:nth-child(1) {
	transform: rotate(45deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(2) {
	transform: rotate(0deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(3) {
	width: 0;
	height: 0;
	border: 12px solid;
	border-color: transparent transparent transparent #7367f0;
	transform: translate(50px, 2px);
}
.loadingio-spinner-reload-r3yh8h7kig {
	width: 50px;
	height: 50px;
	display: inline-block;
	overflow: hidden;
	background: #fff;
}
.ldio-m8h18h0i76f {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.5);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-m8h18h0i76f div {
	box-sizing: content-box;
}
.header-sidebar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&-search {
		display: flex;
		align-items: center;
	}
	.title-sidebar {
		margin: 0;
		font-size: 15px;
		font-weight: bold;
	}
	margin-bottom: 1rem;
	.icon {
		transition: 0.2s all ease-in-out;
		cursor: pointer;
		&:hover {
			transform: scale(1.05);
		}
	}
}
.header-sticky {
	position: sticky;
	top: 0;
}
.search-plate {
	display: flex;
	align-items: center;
	margin-left: 1rem;
	.form-control {
		height: 2rem;
	}
	.icon {
		margin-left: 0.5rem;
	}
}
.page-btn {
	height: 32px;
	width: 32px;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s all ease-in-out;
	border-radius: 32px;
	cursor: pointer;
	background: #f3f2f7;
}
.container-btns {
	margin: 0 0.4rem;
	width: auto;
	background: #f3f2f7;
	height: 32px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	transition: 0.1s all ease-in-out;
	.active {
		background: #7367f0;
		color: #fff;
	}
}
.disabled {
	color: #d5d5d5;
}
.container-search-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
	@media screen and (min-width: 800px) {
		input {
			width: 360px;
		}
		.btn {
			max-width: 200px !important;
		}
	}
	.btn {
		width: 100% !important;
		margin-left: 0;
		margin-top: 0.5rem;
		@media screen and (min-width: 600px) {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
}
.dark-layout {
	.table-1 {
		background: #9bb09b;
	}
	.table-2 {
		background: #83abb2;
	}
	.table-3 {
		background: #b28f8e;
	}
	.number-report {
		color: #fff !important;
	}
}
.dark-layout {
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
		.alias {
			color: #fff;
		}
		.plate {
			.icon {
			}
			.text-plate {
			}
		}
	}
}
.padrillo-flex {
	width: 100%;
	justify-content: space-between;
}
.header-hijos {
	grid-column: span 3;
	.container-hijos-title {
	}
	.container-hijos-header {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.childs-specimen {
	background: #d6f8fe !important;
}
.grid-table-sons-by-posturas1 {
	display: grid !important;
	grid-template-columns: 1fr 4fr 3fr 2fr 4fr !important;
	//   background: red !important;
	.container-padrillo {
		padding: 0 !important;
	}
}

.container-header {
	background: #fff;
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: 0.1s all ease-in-out;
	@media screen and (min-width: 700px) {
		padding: 1rem;
	}
	.page-btn {
		height: 32px;
		width: 32px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;
		border-radius: 32px;
		cursor: pointer;
		background: #f3f2f7;
	}
	.container-btns {
		margin: 0 0.4rem;
		width: auto;
		background: #f3f2f7;
		height: 32px;
		border-radius: 16px;
		display: flex;
		align-items: center;
		transition: 0.1s all ease-in-out;
		.active {
			background: #7367f0;
			color: #fff;
		}
	}
	.disabled {
		color: #d5d5d5;
	}
}
.dark-layout {
	.container-header {
		background: #283046;
		.page-btn {
			background: #242b3d;
		}
		.container-btns {
			background: #242b3d;
			.active {
				background: #7367f0;
				color: #fff;
			}
		}
	}
}
.content-table {
	min-width: 100%;
	background: #fff;

	border-radius: 10px;
	overflow-x: scroll;
	// overflow-y: scroll;
	// height: 43.5vh;
	@media screen and (min-width: 1000px) {
		overflow-x: visible;
		// overflow-y: visible;
	}
	.container-table-reports {
		min-width: 1200px !important;
		// padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 1fr 5fr 1.2fr 1.2fr 1.2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
		text-align: center;
		@media screen and (min-width: 600px) {
			// padding: 1rem 2rem;
		}

		p {
			margin: 0;
			font-size: 12px;
			transition: 0.1s all ease-in-out;
			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}
		.icon {
			//color: red;
			margin: 0 0.2rem;
			cursor: pointer;
		}
	}
	.header-table {
		transition: 0.1s all ease-in-out;
		background: #f3f2f7;
		text-align: center;
		p {
			font-weight: 600;
		}
	}
	.body-table {
		border-bottom: 0.5px solid #e6e6e6;
		transition: 0.1s all ease-in-out;
		&:last-child {
			border-bottom: none;
		}
		.body-index {
			//   min-width: 30.88px;
		}
		.body-cell {
			//   min-width: 123.52px;
			p {
				word-wrap: break-word;
			}
			.badge {
				width: 80%;
				@media screen and (min-width: 600px) {
					width: 50% !important;
				}
			}
		}
	}
}

.content-table::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}
.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.content-table::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}
.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.dark-layout {
	.content-table {
		background: #283046;
		.header-table {
			background: #343d55;
		}
		.body-table {
			border-bottom-color: #3b4253;
		}
	}
}
.btn-agregar-organizer {
	width: 180px;
}
.circle {
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 4px;
}
.container-body-header {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
		justify-content: space-between;
	}
}
.font-other-name {
	font-size: 12px;
	font-weight: bold;
}
.header-table-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
	}
	img {
		height: 24px;
		width: 24px;
		object-fit: cover;
	}
}
.btn-buscar-placa {
	width: 220px !important;
	margin-left: 1rem;
}
.container-padrillo {
	display: flex;
	align-items: center;
	flex: 1;
	width: 100%;
	// padding: 0 1rem;
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		// justify-content: center;
		margin-right: 0.5rem;
		.alias {
		}
		.plate {
			background: #fff;
			border-radius: 8px;
			display: flex;
			align-items: center;
			transition: 0.2s all ease-in-out;
			padding: 0.05rem 0.2rem !important;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			.icon {
				color: #9c97da;
				font-weight: bold;
			}
			.text-plate {
				color: #9c97da;
				font-weight: bold;
			}
			&:hover {
				transform: scale(1.05);
			}
		}
	}
}
.number-report {
	font-weight: bold;
	transition: 0.2s all ease-in-out;
	cursor: pointer;
	&:hover {
		color: #9c97da;
	}
}
.icon-eye {
	margin-right: 0.5rem;
}
.table-1 {
	background: #ddfcdd;
}
.table-2 {
	background: #bbf4fe;
}
.table-3 {
	background: #fecccb;
}
.dark-layout {
	.table-1 {
		background: #9bb09b;
	}
	.table-2 {
		background: #83abb2;
	}
	.table-3 {
		background: #b28f8e;
	}
	.number-report {
		color: #fff !important;
	}
}
.modal-df {
	padding: 1rem !important;
}
.data-null {
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		font-size: 18px;
		font-weight: bold;
		margin: 2rem 0;
		padding-left: 1rem;
	}
}

@keyframes ldio-m8h18h0i76f {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.ldio-m8h18h0i76f > div {
	animation: ldio-m8h18h0i76f 1s infinite linear;
	transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div {
	position: absolute;
}
.ldio-m8h18h0i76f > div div:nth-child(1),
.ldio-m8h18h0i76f > div div:nth-child(2) {
	width: 84px;
	height: 84px;
	border: 14px solid;
	border-radius: 50%;
	border-color: transparent #7367f0 #7367f0 #7367f0;
	box-sizing: border-box;
	position: absolute;
	transform: rotate(45deg);
	transform-origin: 50px 50px;
}
.ldio-m8h18h0i76f > div div:nth-child(1) {
	transform: rotate(45deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(2) {
	transform: rotate(0deg) translate(8px, 8px);
}
.ldio-m8h18h0i76f > div div:nth-child(3) {
	width: 0;
	height: 0;
	border: 12px solid;
	border-color: transparent transparent transparent #7367f0;
	transform: translate(50px, 2px);
}
.loadingio-spinner-reload-r3yh8h7kig {
	width: 50px;
	height: 50px;
	display: inline-block;
	overflow: hidden;
	background: #fff;
}
.ldio-m8h18h0i76f {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.5);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-m8h18h0i76f div {
	box-sizing: content-box;
}
.header-sidebar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&-search {
		display: flex;
		align-items: center;
	}
	.title-sidebar {
		margin: 0;
		font-size: 15px;
		font-weight: bold;
	}
	margin-bottom: 1rem;
	.icon {
		transition: 0.2s all ease-in-out;
		cursor: pointer;
		&:hover {
			transform: scale(1.05);
		}
	}
}
.content-table {
	.header-sticky {
		@media screen and (min-width: 1000px) {
			top: calc(62.4px + 1.3rem);
			// top: 200px;
			// top: 50%;
			position: sticky;
			z-index: 999;
			// border-bottom: 1px solid #aba3f5;
			box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
		}
	}
	.header-sticky-mobile {
		position: fixed;
	}
}
.search-plate {
	display: flex;
	align-items: center;
	margin-left: 1rem;
	.form-control {
		height: 2rem;
	}
	.icon {
		margin-left: 0.5rem;
	}
}
.page-btn {
	height: 32px;
	width: 32px;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s all ease-in-out;
	border-radius: 32px;
	cursor: pointer;
	background: #f3f2f7;
}
.container-btns {
	margin: 0 0.4rem;
	width: auto;
	background: #f3f2f7;
	height: 32px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	transition: 0.1s all ease-in-out;
	.active {
		background: #7367f0;
		color: #fff;
	}
}
.disabled {
	color: #d5d5d5;
}

.container-search-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
	@media screen and (min-width: 800px) {
		input {
			width: 360px;
		}
		.btn {
			max-width: 200px !important;
		}
	}
	.btn {
		width: 100% !important;
		margin-left: 0;
		margin-top: 0.5rem;
		@media screen and (min-width: 600px) {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
}

.dark-layout {
	.table-1 {
		background: #9bb09b;
	}
	.table-2 {
		background: #83abb2;
	}
	.table-3 {
		background: #b28f8e;
	}
	.number-report {
		color: #fff !important;
	}
}
.dark-layout {
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
		.alias {
			color: #fff;
		}
		.plate {
			.icon {
			}
			.text-plate {
			}
		}
	}
}
.padrillo-flex {
	width: 100%;
	justify-content: space-between;
}
.header-hijos {
	grid-column: span 3;
	.container-hijos-title {
	}
	.container-hijos-header {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.childs-specimen {
	background: #d6f8fe !important;
}
.grid-table-sons-by-posturas {
	display: grid;
	grid-template-columns: 1fr 4fr 3fr 2fr !important;
	.container-padrillo {
		padding: 0 !important;
	}
}
.sticky-plate-specimen {
	position: sticky;
	top: calc(62.4px + 1.3rem);
	z-index: 1000;
	bottom: 0;
}
.dark-layout {
	.padrillo {
		display: flex;
		align-items: flex-start !important;
		justify-content: flex-start !important;
	}
}
.dark-layout {
	.sons-dark {
		color: #6e6b7b !important;
	}
}
.info-icon {
	margin-left: 0.4rem;
}
.success-icon {
	animation: pulse1 1s infinite;
	cursor: pointer;
	margin-left: 0.2rem;
}
.danger-icon {
	cursor: pointer;
	animation: pulse2 1s infinite;
	margin-left: 0.2rem;
}

@keyframes pulse1 {
	0% {
		color: #80e12f;
	}
	50% {
		color: #73cb2a;
	}
	100% {
		color: #80e12f;
	}
}

@keyframes pulse2 {
	0% {
		color: #f50d0d;
	}
	50% {
		color: #dd0c0c;
	}
	100% {
		color: #f50d0d;
	}
}
.sticky-plate-specimen {
	position: sticky;
	top: calc(62.4px + 1.3rem);
	z-index: 1000;
	bottom: 0;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	border: 2px solid #7366ef !important;
}

.grid-table-sons-by-posturas {
	grid-template-columns: 1fr 4fr 2fr 2fr !important;
}
</style>
