var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"element",staticClass:"w-full h-full p-relative"},[_c('div',{staticClass:"tree-modal__actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XCircleIcon","size":"32"},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('div',{ref:"family-tree",staticClass:"w-full h-full",style:({
			'--bg-image': ("url(" + (require('@/assets/images/logo_final.png')) + ")"),
			'--bg-texture': ("url(" + (require('@/assets/images/texture.jpg')) + ")"),
		}),attrs:{"id":"root-d3-tree"}}),_c('section',{staticClass:"abs-center ts-sidebar"},[_c('TreeLegendCard')],1),(_vm.modalForUpdate)?_c('ModalUpdateSpecimen',{attrs:{"specimen-id":_vm.currentSpecimen.specimen_id},on:{"hidden":function($event){_vm.modalForUpdate = false},"updated":_vm.refreshD3Drawing}}):_vm._e(),(_vm.modalForParents)?_c('ModalRegisterParents',{attrs:{"specimen":{
			id: _vm.currentSpecimen.specimen_id,
			alias: _vm.currentSpecimen.alias,
			plate: _vm.currentSpecimen.plate,
			madrilla_id: _vm.currentSpecimen.madrilla_id,
			padrillo_id: _vm.currentSpecimen.padrillo_id,
			date: _vm.currentSpecimen.date,
		},"omits":_vm.omits},on:{"hidden":function($event){_vm.modalForParents = false},"parent-saved":_vm.refreshD3Drawing,"updateSpecimen":_vm.updateSpecimen}}):_vm._e(),_c('ModalSwitchSpecimen',{attrs:{"show":_vm.showSwitchspecimen,"familyInfo":_vm.curFamilyInfo},on:{"closing":function($event){_vm.showSwitchspecimen = false},"refresh":_vm.refreshD3Drawing}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }