<template>
	<div>
		<div class="d-flex">
			<div class="dseCard__head dseCard__head--wfull">Raza del Ejemplar</div>
		</div>
		<div class="dseCard__body dseCard__body--wfull position-relative p-0">
			<b-overlay :show="isLoading">
				<VueApexCharts type="donut" class="pt-1" height="300" :series="chartData" :options="chartOpts" />
				<div class="customOverlay" v-if="isProcessing">
					Los registros se estan procesando. Estaran disponibles a partir de las 12:00 AM
				</div>
				<FeatherIcon
					class="position-absolute cursor-pointer text-primary"
					style="top: 1rem; left: 1rem"
					icon="Edit3Icon"
					size="20"
					@click="openEdit"
					v-if="specimen.origin != 1 || specimen.origin == null"
				/>
			</b-overlay>
		</div>
		<UpdateRaceModal :show="update.show" :info="update.info" @closing="update.show = false" @refresh="getData" />
	</div>
</template>
<script>
import raceService from "@/services/race.service"

import VueApexCharts from "vue-apexcharts"
import UpdateRaceModal from "./UpdateRaceModal.vue"

export default {
	props: { specimen: Object },
	components: { VueApexCharts, UpdateRaceModal },
	data: () => ({
		isLoading: false,
		chartData: [1],
		chartOpts: {
			labels: ["1"],
			colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc"],
			legend: {
				position: "bottom",
			}
		},
		isProcessing: false,
		update: { show: false, info: {} },
		rawRaces: [],
	}),
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	methods: {
		async getData() {
			this.isLoading = true
			const { data } = await raceService.getSpecimenRaces({ id: this.specimen.id })
			this.rawRaces = data.races
			if (data.loading) {
				this.isProcessing = true
			} else if (data.races.length == 0) {
				this.chartData = [100]
				this.chartOpts = {
					labels: ["Sin resultados"],
					legend: {
						fontWeight: 600,
						labels: { colors: "rgb(153, 153, 153)" },
						position: this.$store.state.app.windowWidth > 990 ? "bottom" : "right",
					},
					colors: ["#999999"],
					tooltip: { enabled: false },
					dataLabels: { formatter: () => "" },
				}
			} else {
				this.chartData = data.races.map((r) => Number(r.percentage))
				this.chartOpts = {
					labels: data.races.map((r) => r.name),
					colors: [
						data.races[0].race_specimen_id == null ? "#999999" : "#b9b3f8",
						"#9d95f5",
						"#7367f0",
						"#6258cc",
					],
					legend: { position: this.$store.state.app.windowWidth > 990 ? "bottom" : "right" },
				}
			}
			this.isLoading = false
		},
		openEdit() {
			this.update.info = { id: this.specimen.id, updating: true, races: this.rawRaces }
			this.update.show = !this.update.show
		},
	},
	created() {
		this.getData()
	},
}
</script>

<style scoped>
.customOverlay {
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 4rem;
	backdrop-filter: blur(10px);
	background: rgba(0, 0, 0, 0.2);
	text-align: center;
	font-size: 1.2rem;
	font-weight: 500;
	color: #fff;
}
</style>
