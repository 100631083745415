import { render, staticRenderFns } from "./TreeLegendCard.vue?vue&type=template&id=c080da02&scoped=true"
import script from "./TreeLegendCard.vue?vue&type=script&lang=js"
export * from "./TreeLegendCard.vue?vue&type=script&lang=js"
import style0 from "./TreeLegendCard.vue?vue&type=style&index=0&id=c080da02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c080da02",
  null
  
)

export default component.exports