<template>
    <div>
        <div class="d-flex">
            <div class="dseCard__head dseCard__head--wfull">Linea del Ejemplar</div>
        </div>
        <div class="dseCard__body dseCard__body--wfull position-relative pt-2">
            <VueApexCharts
                type="donut"
                :height="300"
                :options="donutOpts"
                :series="donutData"
            />
            <FeatherIcon
                class="position-absolute cursor-pointer text-primary"
                style="top: 1rem; left: 1rem"
                icon="Edit3Icon"
                size="20"
                @click="$emit('action')"
                v-if="specimen.origin != 1 || specimen.origin == null"
            />
        </div>
    </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts"

export default {
	name: "LineBreederChart",
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
		donutData: Array,
		donutOpts: {
			type: Object,
			default: () => {},
		}
	},
    components:{
        VueApexCharts,
    },
    computed: {
        widthScreen() {
			return this.$store.state.app.windowWidth
		},
    },
}

</script>