<template>
  <b-modal
    ref="modal-upload-image-specimen"
    @hidden="$emit('hidden')"
    title="Agregar Imagen"
    ok-title="Guardar"
    cancel-title="Cancelar"
    @ok.prevent="uploadImageSpecimen()"
    :ok-disabled="!dataFile"
    size="md"
  >
    <!-- <p>p: {{ loading }}</p> -->
    <div
      class="container-imagev2"
      :class="[errorVideo ? 'container-video-error' : '']"
      @click="fileChange"
    >
      <img class="image" v-if="dataFile" :src="dataFile.base" alt="" />
      <div class="skeletor-video-content">
        <p>Click aqui</p>
      </div>
      <input
        ref="fileInput"
        type="file"
        @input="fileChange"
        class="d-none"
        accept=".jpeg,.jpg,.png,.heif,.heic"
      />
      <p v-if="errorVideo" style="color: red">El video debe ser obligatorio</p>
    </div>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import heic2any from "heic2any";

export default {
  mixins: [modalMixin],
  props: { specimen: {} },
  components: { DragAndDropImages },
  data() {
    return {
      arrayImages: [],
      errorVideo: false,
      dataFile: null,
      loading: false,
    };
  },
  mounted() {
    this.toggleModal("modal-upload-image-specimen");
  },
  computed: {
    ...mapState("createEditSpecimen", ["S_PHOTOS"]),
  },
  methods: {
    ...mapMutations("createEditSpecimen", ["M_SET_PHOTOS"]),
    ...mapActions("createEditSpecimen", [
      "A_ADD_IMAGE_BY_ID",
      "A_UPDATE_PRINCIPAL_PHOTO",
      "A_GET_IMAGES_SPECIMEN",
    ]),
    async uploadImageSpecimen() {
      this.isPreloading();
      try {
        const formData = new FormData();
        formData.append("file", this.dataFile.data);
        formData.append("id_specimens", this.specimen.id);
        formData.append("user_id", this.currentUser.user_id);
        formData.append("statusTope", false);
        const { data } = await this.A_ADD_IMAGE_BY_ID(formData);

        const imageUpdate = {
          id_specimens: this.specimen.id,
          id: data[0].photo_id,
        };
        await this.A_UPDATE_PRINCIPAL_PHOTO(imageUpdate);
        await this.A_GET_IMAGES_SPECIMEN(this.specimen.id);
        this.$emit("set-index-carrousel");
        this.$emit("hidden");
        this.showSuccessToast(
          "Imagen guardada Correctamente, se actualizo la nueva imagen Principal"
        );
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isPreloading(false);
      }
    },
    fileChange() {
      if (!this.loading) {
        this.loading = true;
        this.$refs.fileInput.click();
        let input = this.$refs.fileInput;
        let file = input.files;
        const typeOfFile =
          file[0] &&
          file[0].name.split(".")[file[0].name.split(".").length - 1];
        console.log(typeOfFile);
        if (typeOfFile === "heic" || typeOfFile === "heif") {
          this.isPreloading();
          this.showToast(
            "warning",
            "top-right",
            "Cargando imagen, espere un momento",
            "CheckIcon",
            "El formato HEIF o HEIC no es soportado, por ello puede tardar un momento."
          );
          heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then(
            (newImage) => {
              const url = URL.createObjectURL(newImage);
              let newFile = new File([newImage], "heic" + ".jpg", {
                type: "image/jpeg",
                lastModified: new Date().getTime(),
              });
              this.dataFile = {
                name: "name",
                data: newFile,
                type: "image",
                base: url,
                progress: 0,
                uploadFinished: false,
                extension: typeOfFile,
              };
              this.isPreloading(false);
            }
          );
        } else {
          console.log(file);
          if (file && file[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              this.dataFile = {
                name: file[0].name,
                data: file[0],
                type: "image",
                base: e.target.result,
                progress: 0,
                uploadFinished: false,
                extension: typeOfFile,
              };
            };
            reader.readAsDataURL(file[0]);
          }
        }
        /* this.$refs.fileInput.value = null;
        // this.loading = false; */
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">
.container-imagev2 {
  height: 260px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  .skeletor-video-content {
    cursor: pointer;
    position: absolute;
    // height: 100%;
    // width: 100%;
    background: rgba(30, 30, 30, 0.5);
    padding: 0.5rem;
    border-radius: 8px;

    // display: flex;
    // align-items: center;
    // justify-content: center;
    p {
      margin-bottom: 0;
      color: white;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .image {
    // z-index: 100;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
