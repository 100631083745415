import D3 from "d3"

export const config = Object.freeze({
	width: ((w) => w.innerWidth)(window),
	height: ((w) => w.innerHeight)(window),
	node: {
		width: 280,
		height: 180,
	},
	icons: {
		delete: "M7.502 6.098h6.127c.418 0 .436.032.418.417-.07 1.429-.14 2.858-.209 4.303-.087 1.574-.156 3.147-.26 4.72-.053.852-.784 1.462-1.707 1.462H3.133c-.922 0-1.636-.61-1.706-1.461-.087-1.092-.139-2.184-.191-3.276A627.237 627.237 0 0 0 .94 6.42c-.017-.305.157-.321.4-.321h6.162ZM4.63 2.115V1.57C4.648.719 5.361.044 6.284.012c.8-.016 1.601-.016 2.42 0 .94.016 1.653.707 1.67 1.558v.545h1.55c.626 0 1.253-.032 1.845.049.748.096 1.166.594 1.218 1.284.017.337.017.69 0 1.028-.018.37-.244.562-.644.594H.73c-.486 0-.713-.21-.73-.658v-.835c.017-.883.627-1.445 1.584-1.445 1.01-.033 2.02-.017 3.046-.017ZM9.208 2.1c0-.08.017-.128.017-.192v-.354c-.034-.272-.226-.465-.54-.465h-2.35c-.278 0-.469.144-.521.37-.052.208-.018.433-.018.641h3.412Z",
		add: "M0 7.492V3.536C0 1.518 1.52 0 3.54 0h7.904C13.497 0 15 1.502 15 3.552v7.896C15 13.498 13.48 15 11.444 15H3.54C1.503 15 0 13.482 0 11.464V7.492Zm9.52.646h1.019c.436 0 .711-.259.711-.646 0-.404-.29-.678-.727-.678-.68 0-1.358-.016-2.053 0-.243 0-.307-.08-.307-.307.016-.678.016-1.372 0-2.05 0-.501-.404-.824-.825-.727-.323.08-.517.339-.517.743v1.97c0 .355 0 .355-.34.355H4.51c-.453 0-.76.258-.76.678 0 .403.29.662.744.662h1.94c.387 0 .387 0 .387.371v1.938c0 .29.08.549.356.694.468.258.986-.08 1.002-.662a44.03 44.03 0 0 0 0-2.067c0-.21.08-.274.275-.274H9.52Z",
		edit: "M4.657 13.337a511.983 511.983 0 0 0-3.005-3.042c2.54-2.576 5.1-5.169 7.64-7.762 1.014 1.038 2.01 2.057 2.971 3.043-2.49 2.558-5.048 5.151-7.606 7.761Zm5.545-11.651c.412-.432.841-.916 1.305-1.348.515-.484 1.305-.432 1.803.052.464.45.927.916 1.374 1.383.395.397.429 1.002.051 1.4a44.797 44.797 0 0 1-1.562 1.573c-.996-1.02-1.992-2.04-2.97-3.06ZM3.695 14.202c-.893.224-1.854.449-2.816.674-.12.034-.258.069-.378.103-.378.087-.566-.103-.48-.484.24-1.106.497-2.212.738-3.301l2.936 3.008Z",
		date: "M.505 7.567h20.03c.012.195.025.38.025.575v8.448c0 2.07-1.274 3.392-3.33 3.392-4.47.024-8.938.024-13.407 0-2.045-.013-3.306-1.323-3.318-3.355v-9.06ZM6.7 10.358H4.704v1.984H6.7v-1.984Zm9.672 1.996v-1.996H14.39v1.996h1.983Zm-4.848-2.008H9.553v1.996h1.97v-1.996ZM6.7 14.509H4.704v1.959H6.7v-1.96Zm9.685 0H14.4v1.959h1.984v-1.96Zm-4.873 1.959v-1.972H9.565v1.972h1.947ZM4.655 2.032V.012h2.057v1.971H9.48V.024h2.07v1.972h2.766V0h2.082v2.008c1.42-.037 2.706.122 3.575 1.347.514.734.686 1.567.526 2.791H.541c-.134-1.175-.012-2.277.87-3.17.881-.895 2.008-1.005 3.244-.944Z",
		plate: "M25.724 3.104C25.724 1.272 24.47.01 22.647 0H3.335C1.548.009.275 1.29.275 3.086v12.995c0 .199.01.388.037.587.262 1.47 1.462 2.472 2.996 2.472 3.23.01 6.47 0 9.701 0h9.566c1.895 0 3.15-1.245 3.15-3.122V3.104ZM8.1 15.81a.939.939 0 0 1-.975-.712c-.108-.47.136-1.724.424-2.157.154.18.307.36.479.568.162-.19.316-.379.496-.595.081.207.172.37.208.55.08.487.171.975.198 1.471.027.451-.37.84-.83.875Zm-.903-4.52c.434-.434 1.192-.434 1.66-.01-.017.027-.026.055-.053.082-.253.306-.497.613-.776.947-.298-.36-.569-.686-.83-1.02Zm4.846.46c-.036.722-.46 1.245-1.137 1.543-.135.054-.18.117-.207.27-.081.605-.406 1.03-.984 1.29-.054-.91-.18-1.786-.686-2.562.271-.343.55-.677.84-1.029-.434-.622-1.002-1.029-1.778-1.046-.821-.028-1.426.379-1.895 1.046.225.271.424.55.659.803.144.163.162.271.045.47-.361.613-.47 1.308-.533 2.003-.009.09 0 .18 0 .325-.451-.19-.794-.47-.893-.902-.108-.443-.325-.695-.713-.912-.47-.27-.731-.749-.75-1.281-.044-1.011-.107-2.022.145-3.014.388-1.48 1.2-2.618 2.653-3.258-.099-.145-.18-.28-.27-.424-.307-.47-.208-1.011.234-1.282.443-.27.912-.117 1.21.388.009.01.027.01.054.027v-.532c.018-.56.505-.92 1.047-.785.631.162.938.821.65 1.417-.181.388-.38.767-.57 1.146 1.697.722 2.573 2.057 2.825 3.799.109.821.1 1.67.054 2.5Zm4.034-6.958c.93-.01 1.86 0 2.789 0h2.698c.587 0 1.002.352.984.821-.01.46-.406.794-.966.803-1.84.01-3.69.01-5.532 0-.568 0-.92-.334-.911-.821.009-.505.352-.803.938-.803Zm5.55 10.08c-1.868.009-3.727.009-5.595 0-.56 0-.884-.316-.893-.794 0-.505.343-.794.93-.803h5.548c.533 0 .92.325.939.776.027.451-.38.821-.93.821Zm-.027-4.241c-1.85 0-3.7.009-5.55 0-.577 0-.92-.325-.911-.822.009-.487.334-.767.911-.776.93-.009 1.868 0 2.798 0H21.6c.569 0 .957.334.957.794 0 .47-.388.804-.957.804Z",
		switch: "m12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm5.85 12.1c-.04.09-.09.17-.16.24l-3.04 3.04c-.15.15-.34.22-.53.22s-.38-.07-.53-.22c-.29-.29-.29-.77 0-1.06l1.76-1.76h-8.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10.31c.1 0 .19.02.29.06.18.08.33.22.41.41.06.18.06.39-.01.57zm-.7-3.17h-10.3c-.1 0-.2-.02-.29-.06-.18-.08-.33-.22-.41-.41-.08-.18-.08-.39 0-.57.04-.09.09-.17.16-.24l3.04-3.04c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-1.75 1.76h8.5c.41 0 .75.34.75.75s-.34.75-.76.75z",
		paw: "m39 62a36.939 36.939 0 0 0 2.488-10.363l.878-9.667a26.1 26.1 0 0 1 4.67-12.688l3.764-5.334a42.878 42.878 0 0 0 6.2-12.948 34.165 34.165 0 0 0 -11.472 10.708l-5.528 8.292v-17.384a28.412 28.412 0 0 0 -1.461-8.995l-.539-1.621a28.426 28.426 0 0 0 -3 12.708v14.865a2.428 2.428 0 0 1 -2.427 2.427 2.431 2.431 0 0 1 -1.085-.256l-11.311-6.3a38.162 38.162 0 0 0 -13.177-4.444 40.663 40.663 0 0 0 11.867 9.72l11.769 6.374a10.963 10.963 0 0 1 5.722 9l.326 5.534a33.163 33.163 0 0 0 2.316 10.372z",
	},
	duration: 400,
})

/**
 * @param {string} target
 */
export const useConfigD3 = (target) => {
	const plot = D3.select(target)
		.append("svg")
		.attr({
			width: config.width,
			height: config.height,
			viewBox: `0 0 ${config.width} ${config.height}`,
			id: "tree-plot",
		})
		.call(
			D3.behavior
				.zoom()
				.scaleExtent([0.25, 2.5])
				.on("zoom", function () {
					plot.attr("transform", "translate(" + D3.event.translate + ") scale(" + D3.event.scale + ")")
				})
				.translate([config.node.width, config.node.width * 1.8])
		)
		.append("g")
		.attr("transform", `translate(${config.node.width}, ${config.node.width * 1.8})`)

	const tree = D3.layout
		.tree()
		.nodeSize([config.node.height - 50, config.node.width + 100])
		.separation(function () {
			return 2.25
		})
		.children(function (specimen) {
			if (specimen.collapsed) {
				return
			}
			return specimen._parents
		})

	return { plot, tree }
}

export const legends = [
	{
		title: "Eliminar",
		icon: {
			path: "M6.502 5.38h5.31c.362 0 .377.028.362.369l-.18 3.796c-.076 1.389-.137 2.777-.227 4.166-.045.75-.679 1.289-1.478 1.289H2.716c-.8 0-1.419-.538-1.479-1.29-.075-.963-.12-1.926-.166-2.89a563.411 563.411 0 0 0-.256-5.156c-.015-.27.136-.284.347-.284h5.34ZM4.013 1.867v-.482C4.028.634 4.646.039 5.446.01a51.73 51.73 0 0 1 2.097 0c.814.014 1.433.623 1.448 1.374v.482h1.343c.543 0 1.086-.029 1.599.042.648.085 1.01.524 1.056 1.133.015.298.015.61 0 .907-.015.326-.212.496-.559.524H.635c-.423 0-.619-.184-.634-.58v-.737c.015-.78.543-1.275 1.373-1.275.875-.029 1.75-.014 2.64-.014Zm3.967-.015c0-.07.015-.113.015-.17v-.311A.443.443 0 0 0 7.528.96H5.49c-.241 0-.407.127-.452.326-.046.184-.015.382-.015.566H7.98Z",
			width: 13,
			height: 15,
		},
	},
	{
		title: "Agregar",
		icon: {
			path: "M0 6.493V3.065C0 1.315 1.317 0 3.068 0h6.85C11.698 0 13 1.301 13 3.079V9.92C13 11.7 11.683 13 9.918 13h-6.85C1.303 13 0 11.685 0 9.935V6.493Zm8.251.56h.883c.378 0 .616-.224.616-.56 0-.35-.252-.588-.63-.588-.589 0-1.177-.014-1.78 0-.21 0-.266-.07-.266-.266.014-.587.014-1.189 0-1.777 0-.434-.35-.713-.714-.63-.28.07-.448.294-.448.644v1.707c0 .308 0 .308-.295.308H3.908c-.392 0-.658.224-.658.588 0 .35.252.574.644.574h1.681c.337 0 .337 0 .337.322v1.679c0 .252.07.476.308.602.406.223.854-.07.868-.574a38.17 38.17 0 0 0 0-1.791c0-.182.07-.238.239-.238h.924Z",
			width: 13,
			height: 13,
		},
	},
	{
		title: "Editar",
		icon: {
			path: "M4.967 14.131a541.177 541.177 0 0 0-3.205-3.223c2.71-2.73 5.44-5.476 8.15-8.224l3.168 3.224c-2.655 2.71-5.384 5.458-8.113 8.223Zm5.915-12.344c.44-.458.898-.97 1.392-1.429.55-.513 1.392-.458 1.923.055.495.476.99.97 1.466 1.465.42.422.457 1.063.054 1.484a47.693 47.693 0 0 1-1.666 1.667 523.653 523.653 0 0 1-3.169-3.242Zm-6.941 13.26c-.952.238-1.978.476-3.003.714-.129.037-.275.073-.403.11-.403.092-.605-.11-.513-.513.256-1.172.531-2.344.787-3.498l3.132 3.187Z",
			width: 16,
			height: 16,
		},
	},
	{
		title: "Alias",
		icon: {
			path: "M20 2.44C20 1 19.014.007 17.582 0H2.404C1 .007 0 1.014 0 2.426v10.212c0 .156.007.305.028.461.206 1.156 1.15 1.944 2.355 1.944 2.539.007 5.085 0 7.624 0h7.518c1.49 0 2.475-.98 2.475-2.454V2.439ZM6.149 12.426a.738.738 0 0 1-.766-.56c-.085-.37.106-1.355.333-1.696.12.142.241.284.376.447.128-.149.248-.298.39-.468.064.163.135.29.163.433.064.382.135.765.156 1.156.022.354-.29.66-.652.688Zm-.71-3.554c.341-.34.937-.34 1.306-.007-.014.022-.022.043-.043.064-.198.241-.39.482-.61.745-.234-.284-.447-.54-.652-.802Zm3.81.362c-.03.567-.362.979-.894 1.213-.107.042-.142.092-.164.213-.063.475-.319.808-.773 1.014-.042-.717-.141-1.404-.539-2.014.213-.27.433-.532.66-.809-.34-.49-.787-.808-1.397-.823-.646-.02-1.12.298-1.49.823.178.213.334.433.518.631.114.128.128.213.036.37-.284.481-.37 1.027-.419 1.573-.007.071 0 .142 0 .256-.354-.15-.624-.369-.702-.71-.085-.347-.255-.546-.56-.716a1.183 1.183 0 0 1-.589-1.007c-.035-.794-.085-1.588.114-2.369.305-1.163.943-2.056 2.085-2.56-.078-.113-.142-.22-.213-.333-.241-.369-.163-.795.184-1.007.348-.213.717-.092.95.305.008.007.022.007.043.021v-.418a.644.644 0 0 1 .823-.618.776.776 0 0 1 .51 1.114c-.141.305-.297.603-.446.9 1.333.568 2.021 1.618 2.22 2.986.085.646.078 1.313.042 1.965Zm3.17-5.468c.73-.007 1.46 0 2.19 0h2.121c.462 0 .788.277.773.645-.006.362-.319.624-.758.632-1.447.007-2.901.007-4.348 0-.447 0-.723-.263-.716-.646.007-.397.276-.631.737-.631Zm4.361 7.922c-1.468.007-2.929.007-4.397 0-.44 0-.695-.248-.702-.624 0-.397.27-.624.73-.631h4.362c.419 0 .724.255.738.61.02.354-.298.645-.73.645Zm-.021-3.333c-1.454 0-2.908.007-4.362 0-.454 0-.723-.256-.716-.646.007-.383.262-.603.716-.61.73-.007 1.468 0 2.199 0h2.163c.447 0 .752.263.752.624 0 .37-.305.632-.752.632Z",
			width: 20,
			height: 16,
		},
	},
	{
		title: "Nacimiento",
		icon: {
			path: "M.007 5.302h13.976c.008.137.017.265.017.402v5.894c0 1.444-.888 2.367-2.324 2.367-3.118.017-6.236.017-9.354 0-1.427-.009-2.307-.923-2.315-2.341V5.302ZM4.329 7.25H2.937v1.384h1.392V7.25Zm6.75 1.392V7.25H9.693v1.392h1.384Zm-3.384-1.4H6.32v1.392h1.375V7.24ZM4.33 10.145H2.937v1.367h1.392v-1.367Zm6.758 0H9.703v1.367h1.384v-1.367Zm-3.4 1.367v-1.376h-1.36v1.376h1.359ZM2.903 1.44V.032h1.435v1.375h1.93V.04h1.444v1.375h1.931V.023h1.452v1.4c.991-.025 1.888.086 2.495.94.359.513.478 1.094.367 1.948H.032c-.094-.82-.008-1.589.607-2.213.615-.623 1.4-.7 2.264-.657Z",
			width: 14,
			height: 14,
		},
	},
	{
		title: "Pertenece al galpón",
		icon: {
			path: "M15.505 6.908c-2.154-2.15-4.303-4.305-6.457-6.455-.612-.607-1.48-.601-2.098 0-.346.335-.686.68-1.026 1.021C4.1 3.3 2.274 5.121.454 6.954c-.528.532-.602 1.298-.188 1.888.34.482.822.67 1.44.64v4.676c0 1.111.737 1.843 1.848 1.843H5.85c.046 0 .091 0 .13-.006H10a.72.72 0 0 0 .114.006h2.268c.062 0 .119 0 .18-.006h.126v-.011c1.003-.114 1.598-.805 1.598-1.889V9.49c.085-.006.12-.012.16-.012.668-.005 1.173-.3 1.428-.919.26-.618.107-1.174-.369-1.65Zm-4.422 3.397-.618.193v1.98a7.768 7.768 0 0 1-1.287.402c-.42.085-.805.13-1.168.13-.618 0-1.156-.13-1.621-.385A2.694 2.694 0 0 1 5.3 11.48c-.26-.504-.385-1.123-.385-1.854 0-.726.125-1.35.38-1.866A2.685 2.685 0 0 1 6.4 6.579c.482-.272 1.06-.409 1.74-.409.375 0 .76.04 1.152.125.397.085.8.21 1.207.38l-.102 1.776h-.952l-.227-.908a4.486 4.486 0 0 0-.374-.09 2.798 2.798 0 0 0-.556-.057c-.34 0-.635.079-.879.238-.243.159-.43.397-.56.726-.126.323-.194.732-.194 1.23 0 .681.125 1.214.38 1.606.255.391.647.59 1.168.59.136 0 .284-.017.442-.046.102-.017.204-.045.312-.073v-1.175l-.623-.147v-.828l2.744-.045v.833h.005Z",
			width: 16,
			height: 16,
		},
	},
	{
		title: "Encaste",
		icon: {
			path: "m39 62a36.939 36.939 0 0 0 2.488-10.363l.878-9.667a26.1 26.1 0 0 1 4.67-12.688l3.764-5.334a42.878 42.878 0 0 0 6.2-12.948 34.165 34.165 0 0 0 -11.472 10.708l-5.528 8.292v-17.384a28.412 28.412 0 0 0 -1.461-8.995l-.539-1.621a28.426 28.426 0 0 0 -3 12.708v14.865a2.428 2.428 0 0 1 -2.427 2.427 2.431 2.431 0 0 1 -1.085-.256l-11.311-6.3a38.162 38.162 0 0 0 -13.177-4.444 40.663 40.663 0 0 0 11.867 9.72l11.769 6.374a10.963 10.963 0 0 1 5.722 9l.326 5.534a33.163 33.163 0 0 0 2.316 10.372z",
			width: 16,
			height: 16,
			transform: "scale(0.3)",
		},
	},
	{
		title: "Actualizar ancestro",
		icon: {
			path: config.icons.switch,
			width: 18,
			height: 18,
			transform: "scale(0.8)",
		},
	},
]

export const genders = [
	{
		title: "Padrillo",
		icon: {
			path: '<circle cx="7" cy="7" r="7" fill="#00E2B7" transform="rotate(90 7 7)"/>',
			width: 14,
			height: 14,
		},
	},
	{
		title: "Madrilla",
		icon: {
			path: '<circle cx="7" cy="7" r="7" fill="#FF5749" transform="rotate(90 7 7)"/>',
			width: 14,
			height: 14,
		},
	},
]
