<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Mostrando {{ start_page }} a {{ to_page }} de
                {{ total_data }} registros</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="current_page"
                :total-rows="total_data"
                :per-page="perpage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perpage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="mr-2">registros</label>
            <feather-icon
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="20"
              @click="resetSearch"
            />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search_input"
                class="d-inline-block mr-1"
                placeholder="Placa..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <b-table
          :api-url="'/api/chick/get-specimen-specimens-detail'"
          ref="refCaSpecimen"
          class="position-relative"
          :items="myProvider"
          :stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
          sticky-header="75vh"
          :fields="arrayColumns"
          table-class="text-nowrap"
          responsive
          show-empty
          :busy.sync="isBusy"
          empty-filtered-text="No hay registros que coincidan con tu búsqueda."
          empty-text="No hay registros que mostrar."
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="current_page"
          :per-page="perpage"
          :filter="search_input"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(code)="data">
            <a href="#" @click="openModalPosturaDetail(data.item)">{{
              data.item.code
            }}</a>
            <br />
            <div class="card cintillo-card">
              <span
                style="font-size: 12px; line-height: 1.7"
                v-if="data.item.cintillo_color"
                >{{ data.item.chickenleg_description }}
                {{ data.item.cintillo_description }}
              </span>
              <span class="fa-squaress" v-if="data.item.cintillo_color"
                ><feather-icon
                  :style="`color: ${data.item.cintillo_color}; background: ${data.item.cintillo_color}; border-radius: 3px;`"
                  icon="SquareIcon"
                  size="20"
              /></span>
              <span
                style="font-size: 12px; line-height: 1.7"
                v-if="data.item.second_color_color"
                >{{ data.item.second_color_description }}
              </span>
              <span class="fa-squaress" v-if="data.item.second_color_color"
                ><feather-icon
                  :style="`color: ${data.item.second_color_color}; background: ${data.item.second_color_color}; border-radius: 3px;`"
                  icon="SquareIcon"
                  size="20"
              /></span>
              <!-- <b-badge
                variant="danger"
                class="mt-1"
                v-if="data.item.cintillo_pata_postura_number == 2"
              >
                LIBERADO
              </b-badge>
              <b-badge
                variant="success"
                class="mt-1"
                v-else-if="data.item.cintillo_pata_postura_number == 1"
              >
                COLOCADO
              </b-badge> -->
            </div>
          </template>
          <template #cell(assigned_plate)="data">
            <specimen-card
              :postura="data.item"
              :index="data.index"
              isMadrilla
            ></specimen-card>
          </template>
          <template #cell(date_of_birth)="data">
            {{ data.item.date_of_birth | myGlobalDayWhour }}
          </template>
          <template #cell(status)="data">
            <p
              :class="
                data.item.status == 'ACTIVO' ? 'text-success' : 'text-danger'
              "
            >
              {{ data.item.status }}
            </p>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Mostrando {{ start_page }} a {{ to_page }} de
              {{ total_data }} registros</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="current_page"
              :total-rows="total_data"
              :per-page="perpage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="detailPosturaModal"
      :title="`Postura ${selectedPostura.code}`"
      v-model="modalDetailPostura"
      hide-footer
      size="xl"
      v-b-modal.modal-scrollable
    >
      <postura-detail
        :postura="selectedPostura"
        @closeModal="closeModal"
      ></postura-detail>
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PosturaDetail from "../../encaste/posturas/PosturaDetail.vue";
export default {
  props: {
    specimenData: Object,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: "code",
      sortDesc: true,
      arrayColumns: [
        {
          key: "code",
          label: "Postura",
          class: "text-center column-table",
          sortable: true,
        },
        {
          key: "assigned_plate",
          label: "Ejemplar",
          class: "text-center",
          sortable: true,
        },
        {
          key: "cintillo_ala",
          label: "Cintillo ala",
          class: "text-center",
          sortable: true,
        },
        {
          key: "date_of_birth",
          label: "Fecha de nacimiento",
          class: "text-center",
          sortable: true,
        },
        {
          key: "gender",
          label: "Género",
          class: "text-center column-table",
          sortable: true,
        },
        {
          key: "status",
          label: "Estado",
          class: "text-center column-table",
          sortable: true,
        },
      ],
      search_input: "",
      orderby: this.dato2 == null ? 3 : this.dato2,
      order: this.dato1 == null ? "desc" : this.dato1,
      start_page: "",
      end_page: "",
      total_data: "",
      perpage: 100,
      next_page: "",
      current_page: 1,
      to_page: "",
      dato1: "",
      dato2: "",
      loadingData: false,
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      chickenleg: null,
      loading: false,
      createDeadsPageController: false,
      deadSpecimenDetail: false,
      selectedDeadSpecimenId: null,
      show: false,
      modalReasonController: false,
      selectedReason: {},
      selectedPostura: { code: null },
      modalDetailPostura: false,
    };
  },
  components: {
    vSelect,
    AppCollapse,
    AppCollapseItem,
    PosturaDetail,
  },
  methods: {
    openModalPosturaDetail(postura) {
      this.selectedPostura = postura;
      this.modalDetailPostura = true;
    },
    onChangeFilter() {
      this.$refs.refCaSpecimen.refresh();
    },
    myProvider(ctx) {
      const promise = axios.post(
        // "/some/url?page=" + ctx.currentPage + "&size=" + ctx.perPage
        `${ctx.apiUrl}?page=${ctx.currentPage}`,
        {
          orderby: ctx.sortDesc == 1 ? "desc" : "asc",
          order: ctx.sortBy == "" ? "code" : ctx.sortBy,
          campo: ctx.filter,
          perpage: ctx.perPage,
          specimen_plate: this.specimenData.plate,
          gender: this.specimenData.gender,
          season: this.specimenData.current_season.toString(),
        }
      );

      // Must return a promise that resolves to an array of items
      return promise.then((data) => {
        // Pluck the array of items off our axios response
        const items = data.data.data;
        this.start_page = data.data.from;
        this.current_page = data.data.current_page;
        this.perpage = data.data.per_page;
        this.next_page = this.start_page + 1;
        this.end_page = data.data.last_page;
        this.total_data = data.data.total;
        this.to_page = data.data.to;
        // Must return an array of items or an empty array if an error occurred
        return items || [];
      });
    },
    resetSearch() {
      this.search_input = "";
      this.$refs.refCaSpecimen.refresh();
    },
    editReason(item) {
      this.selectedReason = item;
      this.modalReasonController = true;
    },
    deleteReason(item) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/reasons/delete", {
              deleted_by: this.currentUser.user_id,
              reason_id: item.id,
            })
            .then((res) => {
              this.showToast(
                "success",
                "top-right",
                "Felicidades",
                "CheckIcon",
                "El motivo ha sido eliminado con éxito!"
              );
              this.$refs.refCaSpecimen.refresh();
            });
        }
      });
    },
    openRegisterReason() {
      this.selectedReason = null;
      this.modalReasonController = true;
    },
    showToast(variant, position, title, icon, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },
    closeModal() {
      this.modalReasonController = false;
      this.$refs.refCaSpecimen.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
td.div {
  width: 100% !important;
}
@media (max-width: 960px) {
  .column-table {
    display: flex;
    flex-direction: column;
  }
}
.cintillo-card {
  width: 100% !important;
  max-width: 150px !important;
  margin: 0 auto !important;
  flex-wrap: wrap !important;
  white-space: initial !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
