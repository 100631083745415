<template>
	<div>
		<b-row class="mb-2">
			<!-- <pre>{{specimen}}</pre> -->

			<b-col>
				<h1 class="d-flex align-items-center">
					<span class="text-primary">{{ specimenValue.alias || "---" }}</span>
					<span class="ml-1">{{ specimenValue.plate || "---" }}</span>

					<i
						v-if="[1, 3].includes(specimenValue.category) && specimen.counter_victories > 0"
						v-b-tooltip.hover="`${specimen.counter_victories} Victorias`"
						class="fa-solid fa-flag ml-75"
						style="color: #28c76f; font-size: 1.8rem; padding-right: 0.3rem; padding-left: 0.5rem"
					></i>

					<div
						v-if="[1, 3].includes(specimenValue.category)"
						class="d-flex justify-content-center align-items-center ml-75"
						v-b-tooltip.hover="`Torneos jugados`"
					>
						<DoubleFlag
							:variant="`${specimen.playeds > 0 ? 'primary' : 'secondary'}`"
							:width="34"
							:height="34"
						/>
						<span class="font-weight-bold text-primary" style="font-size: 19px; color: #8d8a85">
							({{ specimen.playeds }})
						</span>
					</div>

					<div
						v-if="(specimen.category == 1 || specimen.category == 2) && specimen.childs_behavior.length > 0"
						@click="goIdSpecimen(specimen.childs_behavior[0].specimen_id)"
						v-b-tooltip.hover="
							`Hijo corrido: ${
								specimen.childs_behavior[0].alias
									? specimen.childs_behavior[0].alias
									: specimen.childs_behavior[0].plate
							}`
						"
						style="padding-left: 0.5rem"
						class="cursor-pointer"
					>
						<img src="@/assets/images/svg/paw1.svg" class="img-paw" />
					</div>

					<div
						v-else-if="specimen.behavior == 'rajado'"
						v-b-tooltip.hover="`Corrido`"
						style="padding-left: 0.5rem"
					>
						<img src="@/assets/images/svg/paw1.svg" class="img-paw" />
					</div>
					<div
						v-else-if="siblings.length > 0"
						@click="goIdSpecimen(siblings[0].specimen_id)"
						v-b-tooltip.hover="
							`Hermano corrido: ${siblings[0].alias ? siblings[0].alias : siblings[0].plate}`
						"
						style="padding-left: 0.5rem"
						class="cursor-pointer"
					>
						<img src="@/assets/images/svg/paw2.svg" class="img-paw" />
					</div>

					<div
						v-else-if="stepBrother.length > 0"
						@click="goIdSpecimen(stepBrother[0].specimen_id)"
						v-b-tooltip.hover="
							`Medio hermano corrido: ${
								stepBrother[0].alias ? stepBrother[0].alias : stepBrother[0].plate
							}`
						"
						class="cursor-pointer"
						style="padding-left: 0.5rem"
					>
						<img src="@/assets/images/svg/paw3.svg" class="img-paw" />
					</div>

					<div v-else></div>
				</h1>
			</b-col>
			<b-col class="d-flex justify-content-end align-items-center gap-x-2">
				<div class="btnRadio">
					<button :class="['btnRadio__btn', tab == 0 ? 'btnRadio__btn--selected' : '']" @click="tab = 0">
						Características
					</button>
					<button :class="['btnRadio__btn', tab == 1 ? 'btnRadio__btn--selected' : '']" @click="tab = 1">
						Reportes
					</button>
				</div>
				<b-button
					class="btn-icon bg-danger bg-lighten-3"
					variant="flat-danger"
					v-b-tooltip
					title="Eliminar ejemplar"
					@click="$emit('custom-action', 'delete-specimen')"
				>
					<feather-icon class="text-white" icon="TrashIcon" size="20" />
				</b-button>
			</b-col>
		</b-row>
		<div v-if="tab == 0">
			<b-row class="mb-2">
				<b-col>
					<div>
						<div class="d-flex">
							<div class="dseCard__head">Especificaciones Generales</div>
						</div>
						<div class="dseCard__body">
							<b-row>
								<b-col cols="12" md="6" xl="4">
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Alias</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<span v-if="!specimenEdit.alias">
												{{ specimenValue.alias || "---" }}
											</span>
											<b-input-group size="sm" v-else>
												<b-form-input
													v-model="specimenValue.alias"
													@input="specimenValue.alias = specimenValue.alias.toUpperCase()"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-success"
														@click="
															updateFieldDashboard(
																specimenValue.alias,
																'alias',
																'value_text'
															)
														"
													>
														<feather-icon icon="SaveIcon" />
													</b-button>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.alias = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.alias"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item @click="specimenEdit.alias = true" v-if="!isInvitado">
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('alias')"
													:disabled="!hasTracking('alias')"
												>
													Tracking
												</b-dropdown-item>
												<b-dropdown-item
													@click="modalViewDefinition.show = true"
													v-if="!isInvitado"
												>
													Agregar definicion
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Placa</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<span v-if="!specimenEdit.plate">
												{{ specimenValue.plate || "---" }}
											</span>
											<b-input-group size="sm" v-else>
												<b-form-input
													v-model="specimenValue.plate"
													@input="specimenValue.plate = specimenValue.plate.toUpperCase()"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-success"
														@click="
															updateFieldDashboard(
																specimenValue.plate,
																'plate',
																'value_text'
															)
														"
													>
														<feather-icon icon="SaveIcon" />
													</b-button>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.plate = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.plate"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item @click="specimenEdit.plate = true" v-if="!isInvitado">
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('plate')"
													:disabled="!hasTracking('plate')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Placa adicional</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<span v-if="!specimenEdit.additional_plate">
												{{ specimenValue.additional_plate || "---" }}
											</span>
											<b-input-group size="sm" v-else>
												<b-form-input
													v-model="specimenValue.additional_plate"
													@input="
														specimenValue.additional_plate =
															specimenValue.additional_plate.toUpperCase()
													"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-success"
														@click="
															updateFieldDashboard(
																specimenValue.additional_plate,
																'additional_plate',
																'value_text'
															)
														"
													>
														<feather-icon icon="SaveIcon" />
													</b-button>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.additional_plate = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.additional_plate"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item
													@click="specimenEdit.additional_plate = true"
													v-if="!isInvitado"
												>
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('additional_plate')"
													:disabled="!hasTracking('additional_plate')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Estado</b>
										</b-col>
										<b-col class="d-flex align-items-start" cols="8">
											<div
												v-if="!specimenEdit.status"
												class="d-flex align-items-center justify-content-center"
											>
												<div
													class="d-flex flex-column justify-content-center align-items-center"
												>
													<div class="d-flex gap-x-1">
														<feather-icon
															size="18"
															v-b-tooltip.hover.top="
																specimen.in_galpon === 1
																	? 'Disponible'
																	: 'No disponible'
															"
															:class="{
																'text-danger': specimen.in_galpon === 0,
																'text-success': specimen.in_galpon === 1,
															}"
															icon="CheckCircleIcon"
														></feather-icon>
														{{ specimen.s_description || "---" }}
													</div>
													<span
														v-if="specimen.status == 10 && specimen.training_status != null"
														class="text-primary"
													>
														{{ `(${specimen.training_status})` }}
													</span>
												</div>
											</div>
											<b-spinner small v-else />
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.status"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item @click="updateStatusSpecimen(2)" v-if="!isInvitado">
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="updateStatusSpecimen(3)"
													:disabled="!hasTracking('status')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Origen</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-if="isAdmin">
											<span v-if="!specimenEdit.origin">
												{{ specimen.origin_description || "---" }}
											</span>
											<b-input-group size="sm" v-else>
												<v-select
													class="bg-white rounded"
													style="min-width: 10rem"
													label="name"
													v-model="specimenValue.origin"
													:options="S_ORIGINS_SPECIMENS"
													:clearable="false"
													:reduce="(opt) => opt.id"
													:disabled="[1, 2].includes(specimenValue.origin_id)"
													@input="
														updateFieldDashboard(specimenValue.origin, 'origin', 'value_id')
													"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.origin = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.origin"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item
													@click="specimenEdit.origin = true"
													v-if="!isInvitado"
													:disabled="
														(specimenValue.origin == 1 &&
															!!specimen.padrillo_description &&
															!!specimen.madrilla_description) ||
														!!specimen.cintillo
													"
												>
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('origin')"
													:disabled="!hasTracking('origin')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-else>
											<span>---</span>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Criador</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-if="isAdmin">
											<span v-if="!specimenEdit.breeder">
												<span v-if="specimenValue.breeder && S_BREEDERS.length > 0">
													{{ S_BREEDERS.find((it) => it.id == specimenValue.breeder).name }}
												</span>
												<span v-else>---</span>
											</span>
											<b-input-group size="sm" v-else>
												<v-select
													class="bg-white rounded"
													style="min-width: 10rem"
													label="name"
													v-model="specimenValue.breeder"
													:options="S_BREEDERS"
													:reduce="(opt) => opt.id"
													:clearable="false"
													@input="updateFieldDashboard($event, 'breeder', 'value_id')"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.breeder = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.breeder"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item
													@click="specimenEdit.breeder = true"
													v-if="!isInvitado"
												>
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('breeder')"
													:disabled="!hasTracking('breeder')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-else>---</b-col>
									</b-row>
									<b-row class="mb-1" v-if="isAdmin && [1].includes(specimenValue.category)">
										<b-col cols="4">
											<b class="text-primary">Tipo Inseminacion</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<ChangeSpecimenEggEater
													dashboard
													:info="specimen"
													:type="'padrillo'"
													@refresh="$emit('update-status')"
												/>
											</div>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="12" md="6" xl="4">
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Padrillo</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-if="isAdmin">
											<div class="d-inline-block">
												<span>
													{{ specimen.padrillo_description || "---" }}
												</span>
												<br />
												<small v-if="specimen.color_padrillo" v-b-tooltip.hover title="Color">
													<b>{{ specimen.color_padrillo }}</b>
												</small>
											</div>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-else>
											<span>---</span>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Madrilla</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-if="isAdmin">
											<div class="d-inline-block">
												<span>
													{{ specimen.madrilla_description || "---" }}
												</span>
												<br />
												<small v-if="specimen.color_madrilla" v-b-tooltip.hover title="Color">
													<b>{{ specimen.color_madrilla }}</b>
												</small>
											</div>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-else>
											<span>---</span>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Precio</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-if="isAdmin">
											<span v-if="!specimenEdit.price">
												<span v-if="specimenValue.currency && arrayCurrency.length != 0">
													{{
														arrayCurrency.find((it) => it.id == specimenValue.currency).name
													}}
												</span>
												{{ specimenValue.price ? specimenValue.price : "Sin precio" }}
											</span>
											<b-input-group size="" v-else>
												<v-select
													class="bg-white rounded"
													style="width: 4rem"
													label="name"
													:options="arrayCurrency"
													:reduce="(opt) => opt.id"
													:clearable="false"
													v-model="specimenValue.currency"
												/>
												<money
													v-bind="money"
													v-model="specimenValue.price"
													class="form-control bg-white rounded"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-success"
														@click="
															updateFieldDashboard(
																specimenValue.price,
																'price',
																'value_text'
															)
														"
													>
														<feather-icon icon="SaveIcon" />
													</b-button>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.price = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.price"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item @click="specimenEdit.price = true" v-if="!isInvitado">
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('price')"
													:disabled="!hasTracking('price')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8" v-else>
											<span>---</span>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Cintillo</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<span>
													{{ specimen.cintillo || "---" }}
												</span>
												<br />
												<small
													v-b-tooltip.hover
													title="Desparacitado"
													v-if="specimen.deworm_chick"
												>
													{{ specimen.deworm_chick }}
												</small>
											</div>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Categoria</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<span v-if="!specimenEdit.category">
												<span
													class="mt-25 mr-1"
													v-if="specimenValue.category && S_CATEGORIES_SPECIMENS.length > 0"
												>
													{{
														S_CATEGORIES_SPECIMENS.find(
															(it) => it.id == specimenValue.category
														)
															? S_CATEGORIES_SPECIMENS.find(
																	(it) => it.id == specimenValue.category
															  ).description
															: null
													}}
												</span>
												<span v-else>---</span>
											</span>
											<b-input-group size="sm" v-else>
												<v-select
													class="bg-white rounded"
													style="min-width: 10rem"
													v-model="specimenValue.category"
													:options="S_CATEGORIES_SPECIMENS"
													:clearable="false"
													label="description"
													:reduce="(option) => option.id"
													@input="
														updateFieldDashboard(
															specimenValue.category,
															'category',
															'value_id'
														)
													"
												/>
												<b-input-group-append>
													<b-button
														class="btn-icon"
														variant="outline-danger"
														@click="specimenEdit.category = false"
													>
														<feather-icon icon="XIcon" />
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.category"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item
													@click="specimenEdit.category = true"
													v-if="!isInvitado"
												>
													Editar
												</b-dropdown-item>
												<b-dropdown-item
													@click="viewTrackingDashboard('category')"
													:disabled="!hasTracking('category')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Corral</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<span v-if="specimen.corral">
													{{ specimen.corral.code }}
													<span v-if="specimen.corral.alias">
														/ {{ specimen.corral.alias }}
													</span>
												</span>
												<span v-else>---</span>
												<br />
												<small v-if="specimen.corral && specimen.corral.retired">
													<span>{{ specimen.corral.retired | myGlobalDayShort }}</span>
													(Retiro)
												</small>
											</div>
											<!--  -->
											<b-dropdown variant="link" size="sm" toggle-class="p-0 ml-1" no-caret>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item
													@click="corralTracking.show = !corralTracking.show"
													:disabled="!hasTracking('corral')"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="12" md="6" xl="4">
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Genero</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<span v-if="specimen.category && S_CATEGORIES_SPECIMENS.length > 0">
													{{
														S_CATEGORIES_SPECIMENS.find((it) => it.id == specimen.category)
															? S_CATEGORIES_SPECIMENS.find(
																	(it) => it.id == specimen.category
															  ).gender
															: null
													}}
												</span>
												<span v-else>---</span>
											</div>
										</b-col>
									</b-row>
									<b-row class="mb-1" v-if="[2, 4, 6].includes(specimenValue.category)">
										<b-col cols="4">
											<b class="text-primary">Come huevo</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<ChangeSpecimenEggEater
													dashboard
													:info="specimen"
													:type="'madrilla'"
													@refresh="$emit('update-status')"
												/>
											</div>
										</b-col>
									</b-row>

									<b-row v-if="[1, 2].includes(specimenValue.category)" class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Fertilidad</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<b class="mr-05" v-b-tooltip title="Acumulado">
													{{ Number(specimen.fertilidad_accumulated).toFixed(1) }}%
												</b>
												<span v-b-tooltip title="Temporada">
													(T: {{ Number(specimen.fertilidad).toFixed(1) }}%)
												</span>
											</div>
											<b-dropdown
												variant="link"
												size="sm"
												toggle-class="p-0 ml-1"
												no-caret
												v-if="!specimenEdit.category"
											>
												<template #button-content>
													<feather-icon icon="MoreVerticalIcon" />
												</template>
												<b-dropdown-item
													@click="modalFertilityTracking.show = !modalFertilityTracking.show"
													:disabled="
														Number(specimen.fertilidad) +
															Number(specimen.fertilidad_accumulated) ==
														0
													"
												>
													Tracking
												</b-dropdown-item>
											</b-dropdown>
										</b-col>
									</b-row>
									<b-row v-if="[1, 2].includes(specimenValue.category)" class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Hijos vivos</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<span v-b-tooltip title="Temporada">
													T: {{ Number(specimen.sum_childs_season_now) || 0 }}
												</span>
												<span class="ml-05" v-b-tooltip title="Acumulado">
													A: {{ Number(specimen.childs_global) || 0 }}
												</span>
											</div>
											<b-button
												class="btn-icon ml-1"
												size="sm"
												variant="outline-primary"
												v-b-tooltip
												title="Ver hijos"
												@click="chicksGlobalSidebar.show = !chicksGlobalSidebar.show"
												v-if="!specimenEdit.category && Number(specimen.childs_global) != 0"
											>
												<feather-icon icon="EyeIcon" />
											</b-button>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Creado por</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block" v-if="specimen.user">
												<span>{{ specimen.user.name }}</span>
												<br />
												<small>{{ specimen.user.date | myGlobalDayShort }}</small>
											</div>
											<span v-else>---</span>
										</b-col>
									</b-row>
									<b-row class="mb-1">
										<b-col cols="4">
											<b class="text-primary">Publico</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<ChangeSpecimenToPublic
													:specimen="{
														...specimen,
														is_public: specimen.is_public == 1 ? true : false,
													}"
													@refresh="$emit('update-status')"
													:tabIndex="specimen.is_public == 1 ? 0 : 1"
												/>
											</div>
										</b-col>
									</b-row>
									<b-row class="mb-1" v-if="!isInvitado">
										<b-col cols="4">
											<b class="text-primary">Venta</b>
										</b-col>
										<b-col class="d-flex align-items-center" cols="8">
											<div class="d-inline-block">
												<span v-if="specimen.is_sale" class="d-flex align-items-center">
													<p class="mb-0 mr-50">VENDIDO</p>
													<ChangeSpecimenToSale
														:hasSell="false"
														:hasReserve="false"
														:hasCheckbox="false"
														hasTracking
														:specimen="{
															...specimen,
															is_sale: null,
															sale: null,
														}"
														:currency_type="specimen.sale.currency_id"
														:price="specimen.sale.price"
														@refresh="$emit('update-status')"
													/>
												</span>
												<ChangeSpecimenToSale
													v-else-if="!specimen.sale"
													:specimen="{ ...specimen }"
													@refresh="$emit('update-status')"
												/>
												<ChangeSpecimenToSale
													v-else
													:hasSell="false"
													:hasReserve="false"
													hasTracking
													:specimen="{
														...specimen,
														is_sale: [1, 3].includes(specimen.sale.status_id)
															? true
															: false,
													}"
													:currency_type="specimen.sale.currency_id"
													:price="specimen.sale.price"
													@refresh="$emit('update-status')"
													:hasChangeStatus="false"
													:hasCheckbox="true"
												/>
											</div>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12" md="6">
					<div>
						<div class="d-flex">
							<div class="dseCard__head">Otras Especificaciones</div>
						</div>
						<div class="dseCard__body">
							<b-row class="mb-1">
								<b-col cols="4">
									<b class="text-primary">Edad</b>
								</b-col>
								<b-col class="d-flex align-items-center" cols="8">
									<div class="d-inline-block" v-if="!specimenEdit.dob">
										<SpecimenAge :age="specimen.dob" />
										<small>{{ specimen.dob | myGlobalDayShort }}</small>
									</div>
									<b-input-group size="sm" v-else>
										<b-form-datepicker size="sm" v-model="specimenValue.dob" />
										<b-input-group-append>
											<b-button
												class="btn-icon"
												variant="outline-success"
												@click="updateFieldDashboard(specimenValue.dob, 'dob', 'value_text')"
											>
												<feather-icon icon="SaveIcon" />
											</b-button>
											<b-button
												class="btn-icon"
												variant="outline-danger"
												@click="specimenEdit.dob = false"
											>
												<feather-icon icon="XIcon" />
											</b-button>
										</b-input-group-append>
									</b-input-group>
									<b-dropdown
										variant="link"
										size="sm"
										toggle-class="p-0 ml-1"
										no-caret
										v-if="!specimenEdit.dob"
									>
										<template #button-content>
											<feather-icon icon="MoreVerticalIcon" />
										</template>
										<b-dropdown-item @click="specimenEdit.dob = true" v-if="!isInvitado">
											Editar
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols="4">
									<b class="text-primary">Peso</b>
								</b-col>
								<b-col class="d-flex align-items-center" cols="8">
									<span v-if="!specimenEdit.weight">{{ specimenValue.weight || "---" }}</span>
									<b-spinner small v-else />
									<b-dropdown
										variant="link"
										size="sm"
										toggle-class="p-0 ml-1"
										no-caret
										v-if="!specimenEdit.weight"
									>
										<template #button-content>
											<feather-icon icon="MoreVerticalIcon" />
										</template>
										<b-dropdown-item @click="addWeightSpecimen = true" v-if="!isInvitado">
											Editar
										</b-dropdown-item>
										<b-dropdown-item
											@click="openModalWeightTracking"
											:disabled="!hasTracking('weight')"
										>
											Tracking
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols="4">
									<b class="text-primary">Color</b>
								</b-col>
								<b-col class="d-flex align-items-center" cols="8">
									<!--  -->
									<span v-if="!specimenEdit.color">
										<span v-if="specimenValue.color && S_COLORS_SPECIMENS.length > 0">
											{{
												S_COLORS_SPECIMENS.find((it) => it.id == specimenValue.color)
													? S_COLORS_SPECIMENS.find((it) => it.id == specimenValue.color)
															.color
													: null
											}}
										</span>
									</span>
									<b-input-group size="sm" v-else>
										<v-select
											class="bg-white rounded"
											style="min-width: 10rem"
											v-model="specimenValue.color"
											:options="S_COLORS_SPECIMENS"
											:clearable="false"
											label="color"
											:reduce="(option) => option.id"
											@input="updateFieldDashboard(specimenValue.color, 'color', 'value_id')"
										/>
										<b-input-group-append>
											<b-button
												class="btn-icon"
												variant="outline-danger"
												@click="specimenEdit.alias = false"
											>
												<feather-icon icon="XIcon" />
											</b-button>
										</b-input-group-append>
									</b-input-group>
									<b-dropdown
										variant="link"
										size="sm"
										toggle-class="p-0 ml-1"
										no-caret
										v-if="!specimenEdit.color"
									>
										<template #button-content>
											<feather-icon icon="MoreVerticalIcon" />
										</template>
										<b-dropdown-item @click="specimenEdit.color = true" v-if="!isInvitado">
											Editar
										</b-dropdown-item>
										<b-dropdown-item
											@click="viewTrackingDashboard('color')"
											:disabled="!hasTracking('color')"
										>
											Tracking
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols="4">
									<b class="text-primary">Descripcion</b>
								</b-col>
								<b-col class="d-flex align-items-center" cols="8">
									<p
										class="overflow-auto m-0"
										style="max-height: 5rem"
										v-if="!specimenEdit.observations"
									>
										{{ specimenValue.observations || "---" }}
									</p>
									<b-input-group size="sm" v-else>
										<b-textarea v-model="specimenValue.observations" />
										<b-input-group-append>
											<b-button
												class="btn-icon"
												variant="outline-success"
												@click="
													updateFieldDashboard(
														specimenValue.observations,
														'observations',
														'value_text'
													)
												"
											>
												<feather-icon icon="SaveIcon" />
											</b-button>
											<b-button
												class="btn-icon"
												variant="outline-danger"
												@click="specimenEdit.observations = false"
											>
												<feather-icon icon="XIcon" />
											</b-button>
										</b-input-group-append>
									</b-input-group>
									<b-dropdown
										variant="link"
										size="sm"
										toggle-class="p-0 ml-1"
										no-caret
										v-if="!specimenEdit.observations"
									>
										<template #button-content>
											<feather-icon icon="MoreVerticalIcon" />
										</template>
										<b-dropdown-item @click="specimenEdit.observations = true" v-if="!isInvitado">
											Editar
										</b-dropdown-item>
										<b-dropdown-item
											@click="viewTrackingDashboard('observations')"
											:disabled="!hasTracking('observations')"
										>
											Tracking
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols="4">
									<b class="text-primary">Enfermeria</b>
								</b-col>
								<b-col class="d-flex align-items-center" cols="8">
									<span v-if="specimen.nursing_last_status">
										{{ String(specimen.nursing_last_status).toUpperCase() }}
									</span>
									<span v-else>---</span>
									<b-dropdown variant="link" size="sm" toggle-class="p-0 ml-1" no-caret>
										<template #button-content>
											<feather-icon icon="MoreVerticalIcon" />
										</template>
										<b-dropdown-item
											@click="addNursery.show = !addNursery.show"
											v-if="!isInvitado && specimen.nursing_status_id === null"
										>
											ENVIAR A ENFERMERIA
										</b-dropdown-item>
										<!--  -->
										<b-dropdown-item
											@click="nurseryTracking.show = !nurseryTracking.show"
											v-if="specimen.nursing_counter_history > 0"
										>
											Tracking
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols="4">
									<b class="text-primary">Descreste</b>
								</b-col>
								<b-col class="d-flex align-items-center" cols="8">
									<div class="d-inline-block" v-if="specimen.decrest">
										<span>{{ specimen.decrest.name }}</span>
										<br />
										<small>{{ specimen.decrest.date | myGlobalDayShort }}</small>
									</div>
									<span v-else>---</span>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-col>
				<b-col cols="12" md="6">
					<Measures :specimen="specimen" />
				</b-col>
			</b-row>
		</div>
		<div v-if="tab == 1">
			<b-row :class="{ cdw: widthScreen > 990, heightControl: widthScreen > 992 && widthScreen < 1200 }">
				<!--Esta clase se encarga de Modificar el width y height del apexchart-->
				<b-col class="mt-2" cols="12" :xl="[1, 2].includes(specimen.category) ? 5 : 12">
					<b-row>
						<b-col class="mb-2" :cols="[1, 2].includes(specimen.category) ? 12 : 6">
							<LineBreederChart
								:specimen="specimen"
								:donutData="donutData"
								:donutOpts="donutOpts"
								@action="$emit('custom-action', 'edit-breeder-line')"
							/>
						</b-col>
						<b-col :cols="[1, 2].includes(specimen.category) ? 12 : 6">
							<RaceChart :specimen="specimen" @action="$emit('custom-action', 'edit-breeder-line')" />
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mt-2" cols="12" xl="7" v-if="[1, 2].includes(specimen.category)">
					<ChildsDeadChart :specimen="specimen" @modal-actions="handleActionDescription" />
				</b-col>

				<!-- <b-col class="mt-2" cols="12" lg="4" v-if="specimen.category == 1 || specimen.category == 2">
				</b-col> -->
				<b-col class="mt-2" cols="12" xl="5" v-if="[1, 2].includes(specimen.category)">
					<ChildsEffectivenessChart :specimen="specimen" @modal-actions="handleActionDescription" />
				</b-col>
				<b-col class="mt-2" cols="12" xl="7" v-if="[1, 2].includes(specimen.category)">
					<ChildsFightsEffectivenessChart :specimen="specimen" />
				</b-col>
			</b-row>
		</div>
		<b-row class="mb-2" v-if="false">
			<b-col>
				<div>
					<div class="d-flex">
						<div class="dseCard__head">Especificaciones Generales</div>
					</div>
					<div class="dseCard__body">
						<b-row>
							<b-col cols="12" md="6" xl="4">
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Alias</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.alias">{{ specimenValue.alias || "---" }}</span>
										<b-input-group size="sm" v-else>
											<b-form-input
												v-model="specimenValue.alias"
												@input="specimenValue.alias = specimenValue.alias.toUpperCase()"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-success"
													@click="
														updateFieldDashboard(specimenValue.alias, 'alias', 'value_text')
													"
												>
													<feather-icon icon="SaveIcon" />
												</b-button>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.alias = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.alias"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item @click="specimenEdit.alias = true" v-if="!isInvitado">
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('alias')"
												:disabled="!hasTracking('alias')"
											>
												Tracking
											</b-dropdown-item>
											<b-dropdown-item
												@click="modalViewDefinition.show = true"
												v-if="!isInvitado"
											>
												Agregar definicion
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Placa</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.plate">{{ specimenValue.plate || "---" }}</span>
										<b-input-group size="sm" v-else>
											<b-form-input
												v-model="specimenValue.plate"
												@input="specimenValue.plate = specimenValue.plate.toUpperCase()"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-success"
													@click="
														updateFieldDashboard(specimenValue.plate, 'plate', 'value_text')
													"
												>
													<feather-icon icon="SaveIcon" />
												</b-button>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.plate = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.plate"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item @click="specimenEdit.plate = true" v-if="!isInvitado">
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('plate')"
												:disabled="!hasTracking('plate')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Placa adicional</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.additional_plate">
											{{ specimenValue.additional_plate || "---" }}
										</span>
										<b-input-group size="sm" v-else>
											<b-form-input
												v-model="specimenValue.additional_plate"
												@input="
													specimenValue.additional_plate =
														specimenValue.additional_plate.toUpperCase()
												"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-success"
													@click="
														updateFieldDashboard(
															specimenValue.additional_plate,
															'additional_plate',
															'value_text'
														)
													"
												>
													<feather-icon icon="SaveIcon" />
												</b-button>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.additional_plate = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.additional_plate"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item
												@click="specimenEdit.additional_plate = true"
												v-if="!isInvitado"
											>
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('additional_plate')"
												:disabled="!hasTracking('additional_plate')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Estado</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.status">{{ specimen.s_description || "---" }}</span>
										<b-spinner small v-else />
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.status"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item @click="updateStatusSpecimen(2)" v-if="!isInvitado">
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="updateStatusSpecimen(3)"
												:disabled="!hasTracking('status')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Origen</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.origin">
											{{ specimen.origin_description || "---" }}
										</span>
										<b-input-group size="sm" v-else>
											<v-select
												class="bg-white rounded"
												style="min-width: 10rem"
												label="name"
												v-model="specimenValue.origin"
												:options="S_ORIGINS_SPECIMENS"
												:clearable="false"
												:reduce="(opt) => opt.id"
												:disabled="[1, 2].includes(specimenValue.origin_id)"
												@input="
													updateFieldDashboard(specimenValue.origin, 'origin', 'value_id')
												"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.origin = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<!-- {{ specimen }} -->
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.origin"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item
												@click="specimenEdit.origin = true"
												:disabled="
													(specimenValue.origin == 1 &&
														specimen.padrillo_description &&
														specimen.madrilla_description) ||
													specimen.cintillo
												"
												v-if="!isInvitado"
											>
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('origin')"
												:disabled="!hasTracking('origin')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Criador</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.breeder">
											<span v-if="specimenValue.breeder && S_BREEDERS.length > 0">
												{{ S_BREEDERS.find((it) => it.id == specimenValue.breeder).name }}
											</span>
											<span v-else>---</span>
										</span>
										<b-input-group size="sm" v-else>
											<v-select
												class="bg-white rounded"
												style="min-width: 10rem"
												label="name"
												v-model="specimenValue.breeder"
												:options="S_BREEDERS"
												:reduce="(opt) => opt.id"
												:clearable="false"
												@input="updateFieldDashboard($event, 'breeder', 'value_id')"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.breeder = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.breeder"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item @click="specimenEdit.breeder = true" v-if="!isInvitado">
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('breeder')"
												:disabled="!hasTracking('breeder')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="12" md="6" xl="4">
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Padrillo</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span>
												{{ specimen.padrillo_description || "---" }}
											</span>
											<br />
											<small v-if="specimen.color_padrillo" v-b-tooltip.hover title="Color">
												<b>{{ specimen.color_padrillo }}</b>
											</small>
										</div>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Madrilla</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span>
												{{ specimen.madrilla_description || "---" }}
											</span>
											<br />
											<small v-if="specimen.color_madrilla" v-b-tooltip.hover title="Color">
												<b>{{ specimen.color_madrilla }}</b>
											</small>
										</div>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Precio</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.price">
											{{
												specimenValue.price > 0
													? arrayCurrency.find((it) => it.id == specimenValue.currency).name
													: ""
											}}
											{{ specimenValue.price ? specimenValue.price : "Sin precio" }}
										</span>
										<b-input-group size="" v-else>
											<v-select
												class="bg-white rounded"
												style="width: 4rem"
												label="name"
												:options="arrayCurrency"
												:reduce="(opt) => opt.id"
												:clearable="false"
												v-model="specimenValue.currency"
											/>
											<money
												v-bind="money"
												v-model="specimenValue.price"
												class="form-control bg-white rounded"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-success"
													@click="
														updateFieldDashboard(specimenValue.price, 'price', 'value_text')
													"
												>
													<feather-icon icon="SaveIcon" />
												</b-button>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.price = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.price"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item @click="specimenEdit.price = true" v-if="!isInvitado">
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('price')"
												:disabled="!hasTracking('price')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Cintillo</b>
										<!--  -->
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span>
												{{ specimen.cintillo || "---" }}
											</span>
											<br />
											<small v-b-tooltip.hover title="Desparacitado" v-if="specimen.deworm_chick">
												{{ specimen.deworm_chick }}
											</small>
										</div>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Categoria</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<span v-if="!specimenEdit.category">
											<span
												class="mt-25 mr-1"
												v-if="specimenValue.category && S_CATEGORIES_SPECIMENS.length > 0"
											>
												{{
													S_CATEGORIES_SPECIMENS.find((it) => it.id == specimenValue.category)
														? S_CATEGORIES_SPECIMENS.find(
																(it) => it.id == specimenValue.category
														  ).description
														: null
												}}
											</span>
											<span v-else>---</span>
										</span>
										<b-input-group size="sm" v-else>
											<v-select
												class="bg-white rounded"
												style="min-width: 10rem"
												v-model="specimenValue.category"
												:options="
													S_CATEGORIES_SPECIMENS.filter(
														(it) => it.evolution_order >= specimenValue.evolution_order
													)
												"
												:clearable="false"
												label="description"
												:reduce="(option) => option.id"
												@input="
													updateFieldDashboard(specimenValue.category, 'category', 'value_id')
												"
											/>
											<b-input-group-append>
												<b-button
													class="btn-icon"
													variant="outline-danger"
													@click="specimenEdit.category = false"
												>
													<feather-icon icon="XIcon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.category"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item @click="specimenEdit.category = true" v-if="!isInvitado">
												Editar
											</b-dropdown-item>
											<b-dropdown-item
												@click="viewTrackingDashboard('category')"
												:disabled="!hasTracking('category')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Corral</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span v-if="specimen.corral">
												{{ specimen.corral.code }}
												<span v-if="specimen.corral.alias">/ {{ specimen.corral.alias }}</span>
											</span>
											<span v-else>---</span>
											<br />
											<small v-if="specimen.corral && specimen.corral.retired">
												<span>{{ specimen.corral.retired | myGlobalDayShort }}</span>
												(Retiro)
											</small>
										</div>
										<!--  -->
										<b-dropdown variant="link" size="sm" toggle-class="p-0 ml-1" no-caret>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item
												@click="corralTracking.show = !corralTracking.show"
												:disabled="!hasTracking('corral')"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="12" md="6" xl="4">
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Genero</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span v-if="specimen.category && S_CATEGORIES_SPECIMENS.length > 0">
												{{
													S_CATEGORIES_SPECIMENS.find((it) => it.id == specimen.category)
														? S_CATEGORIES_SPECIMENS.find(
																(it) => it.id == specimen.category
														  ).gender
														: null
												}}
											</span>
											<span v-else>---</span>
										</div>
									</b-col>
								</b-row>
								<b-row class="mb-1" v-if="[2, 4, 6].includes(specimenValue.category)">
									<b-col cols="4">
										<b class="text-primary">Come huevo</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<ChangeSpecimenEggEater
												dashboard
												:info="specimen"
												:type="'madrilla'"
												@refresh="$emit('update-status')"
											/>
										</div>
									</b-col>
								</b-row>

								<b-row v-if="[1, 2].includes(specimenValue.category)" class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Fertilidad</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span v-b-tooltip title="Temporada">
												T: {{ Number(specimen.fertilidad).toFixed(1) }}%
											</span>
											<span class="ml-05" v-b-tooltip title="Acumulado">
												A: {{ Number(specimen.fertilidad_accumulated).toFixed(1) }}%
											</span>
										</div>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.category"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item
												@click="modalFertilityTracking.show = !modalFertilityTracking.show"
												:disabled="
													Number(specimen.fertilidad) +
														Number(specimen.fertilidad_accumulated) ==
													0
												"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row v-if="[1, 2].includes(specimenValue.category)" class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Hijos vivos</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span v-b-tooltip title="Temporada">
												T: {{ Number(specimen.sum_childs_season_now) || 0 }}
											</span>
											<span class="ml-05" v-b-tooltip title="Acumulado">
												A: {{ Number(specimen.childs_global) || 0 }}
											</span>
										</div>
										<b-dropdown
											variant="link"
											size="sm"
											toggle-class="p-0 ml-1"
											no-caret
											v-if="!specimenEdit.category"
										>
											<template #button-content>
												<feather-icon icon="MoreVerticalIcon" />
											</template>
											<b-dropdown-item
												@click="chicksGlobalSidebar.show = !chicksGlobalSidebar.show"
												:disabled="Number(specimen.childs_global) == 0"
											>
												Tracking
											</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Creado por</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block" v-if="specimen.user">
											<span>{{ specimen.user.name }}</span>
											<br />
											<small>{{ specimen.user.date | myGlobalDayShort }}</small>
										</div>
										<span v-else>---</span>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="4">
										<b class="text-primary">Publico</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<ChangeSpecimenToPublic
												:specimen="{
													...specimen,
													is_public: specimen.is_public == 1 ? true : false,
												}"
												@refresh="$emit('update-status')"
												:tabIndex="specimen.is_public == 1 ? 0 : 1"
											/>
										</div>
									</b-col>
								</b-row>
								<b-row class="mb-1" v-if="!isInvitado">
									<b-col cols="4">
										<b class="text-primary">Venta</b>
									</b-col>
									<b-col class="d-flex align-items-center" cols="8">
										<div class="d-inline-block">
											<span v-if="specimen.is_sale" class="d-flex align-items-center">
												<p class="mb-0 mr-50">VENDIDO</p>
												<ChangeSpecimenToSale
													:hasSell="false"
													:hasReserve="false"
													:hasCheckbox="false"
													hasTracking
													:specimen="{
														...specimen,
														is_sale: null,
														sale: null,
													}"
													:currency_type="specimen.sale.currency_id"
													:price="specimen.sale.price"
													@refresh="$emit('update-status')"
												/>
											</span>
											<ChangeSpecimenToSale
												v-else-if="!specimen.sale"
												:specimen="{ ...specimen }"
												@refresh="$emit('update-status')"
											/>
											<ChangeSpecimenToSale
												v-else
												:hasSell="false"
												:hasReserve="false"
												hasTracking
												:specimen="{
													...specimen,
													is_sale: [1, 3].includes(specimen.sale.status_id) ? true : false,
												}"
												:currency_type="specimen.sale.currency_id"
												:price="specimen.sale.price"
												@refresh="$emit('update-status')"
												:hasChangeStatus="false"
												:hasCheckbox="true"
											/>
										</div>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-modal
			@ok="updateDefinitionSpecimen"
			v-model="modalViewDefinition.show"
			:title="modalViewDefinition.title"
			ok-title="Guardar"
		>
			<b-form-textarea
				id="textarea"
				v-model="specimenValue.definition"
				placeholder="Agregar "
				rows="3"
				max-rows="6"
			></b-form-textarea>
		</b-modal>

		<b-modal v-model="modalViewTracking.show" :title="modalViewTracking.title" hide-footer>
			<div>
				<b-table-simple sticky-header="calc(100vh - 300px)" class="position-relative mb-0">
					<b-thead class="">
						<b-tr class="text-center">
							<b-th class="border-0 m-0 p-0">
								<div class="row-header">
									<p class="mb-0">ID</p>
								</div>
							</b-th>
							<b-th class="border-0 m-0 p-0">
								<div class="row-header">
									<p class="mb-0">Valor</p>
								</div>
							</b-th>

							<b-th class="border-0 m-0 p-0">
								<div class="row-header">
									<p class="mb-0">Actualizado</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>

					<b-tbody>
						<b-tr v-for="(item, key) in dataTracking" :key="key" class="text-center">
							<b-td>{{ key + 1 }}</b-td>
							<b-td>{{ item.value }}</b-td>

							<b-td>
								<p class="mb-0">{{ item.created_by }}</p>
								<small class="">{{ item.created_at | myGlobalDayShort }}</small>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-modal>

		<SpecimenStatusChangedModal
			v-if="modalViewUpdateStatus.show"
			@hidden="modalViewUpdateStatus.show = false"
			:specimen-obj="{ ...specimen, category_id: specimen.category }"
			:arrayStatus="arrayStatus"
			:typeModalStatus="modalViewUpdateStatus.type"
			@update-status="$emit('update-status')"
		></SpecimenStatusChangedModal>

		<specimen-weight-tracking-modal
			v-if="specimenWeightListController"
			@hidden="specimenWeightListController = false"
			:specimen-obj="specimen"
			@update-specimen-weight="$emit('update-status')"
		/>

		<AddSpecimenWeight
			v-if="addWeightSpecimen"
			@hidden="addWeightSpecimen = false"
			:specimen-obj="specimen"
			@update-specimen-weight="updateSpecimenWeight"
		/>

		<b-modal
			id="modal-primary"
			ok-only
			ok-title="Agregar"
			modal-class="modal-primary"
			centered
			title="Agregar criador"
			v-model="addCriadorModal"
			@ok.prevent="saveRival"
		>
			<b-row>
				<b-col cols="12">
					<b-form-group label="Nombre" label-for="criadorName">
						<b-form-input id="criadorName" v-model="criadorObject.name" />
					</b-form-group>
				</b-col>
			</b-row>
		</b-modal>
		<FertilityTracking
			:show="modalFertilityTracking.show"
			:info="specimen"
			@closing="modalFertilityTracking.show = false"
		/>
		<SidebarChicksGlobal
			:specimen="specimen"
			@hidden="chicksGlobalSidebar.show = false"
			:yearSelected="curYear"
			v-if="chicksGlobalSidebar.show"
		/>
		<CorralTrackingModal
			:show="corralTracking.show"
			:info="{ ...specimen, dashboard: true }"
			@closing="corralTracking.show = false"
		/>
		<AddSpecimensNursery
			:show="addNursery.show"
			:info="{ specimen }"
			@closing="addNursery.show = false"
			@refresh="$emit('update-status')"
		/>
		<NurseryDsTracking
			:show="nurseryTracking.show"
			:info="{ specimen, _specimen_id: specimen.id }"
			@closing="nurseryTracking.show = false"
		/>
		<DeadChildsModal
			:show="childDeads.show"
			:info="childDeads.info"
			:_id="childDeads.id"
			@closing="childDeads.show = false"
		/>
		<EffectivenessForSeason
			:show="effectivenessForSeason.show"
			:info="specimen"
			:seasonData="effectivenessForSeason.seasonData"
			@closing="effectivenessForSeason.show = false"
		/>
	</div>
</template>

<script>
import SpecimenAge from "@/components/specimens/SpecimenAge.vue"
import { mapState, mapActions } from "vuex"
import specimensService from "@/services/specimens.service"
import SpecimenStatusChangedModal from "../components/SpecimenStatusChangedModal.vue"
import { Money } from "v-money"
import SpecimenWeightTrackingModal from "../specimens-weight/SpecimenWeightTrackingModal.vue"
import AddSpecimenWeight from "../specimens-weight/AddSpecimenWeight.vue"
import AddSpecimenToSale from "./AddSpecimenToSale.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ChangeSpecimenToPublic from "../ChangeSpecimenToPublic.vue"
import ChangeSpecimenToSale from "@/views/amg/specimens/ChangeSpecimenToSale.vue"
import CardLineBreeder from "./CardLineBreeder.vue"
import VueApexCharts from "vue-apexcharts"
import FertilityTracking from "./modals/FertilityTracking.vue"
import SidebarChicksGlobal from "@/views/amg/specimens/components/SidebarChicksGlobal.vue"
import ChangeSpecimenEggEater from "../ChangeSpecimenEggEater.vue"
import CorralTrackingModal from "../../encaste/corrales/components/CorralTrackingModal.vue"
import AddSpecimensNursery from "../../nursery/components/AddSpecimensNursery.vue"
import NurseryDsTracking from "../../nursery/components/DsTrackingModal.vue"
import DeadChildsModal from "@/views/amg/specimens/dashboard/modals/DeadChildsModal.vue"
import LineBreederChart from "@/views/amg/specimens/components/LineBreederChart.vue"
import ChildsEffectivenessChart from "@/views/amg/specimens/components/ChildsEffectivinessChart.vue"
import ChildsDeadChart from "@/views/amg/specimens/components/ChildsDeadChart.vue"
import EffectivenessForSeason from "@/views/amg/specimens/dashboard/modals/EffectivinessForSeason.vue"
import RaceChart from "../components/RaceChart.vue"
import ChildsFightsEffectivenessChart from "@/views/amg/specimens/components/ChildsFightsEffectivenessChart.vue"
import decrestService from "@/services/header/decrest.service"
import LineLife from "./modals/LineLife.vue"
import Measures from "./Measures.vue"
import specimen from "@/store/modules/inventory/specimen"
import DoubleFlag from "@/icons/training/DoubleFlag.vue"

export default {
	name: "GeneralInfoDescription",
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
		arrayStatus: {
			type: Array,
			default: [],
		},
		loadingData: {
			type: Boolean,
		},
		stepBrother: {
			type: Array,
			default: () => [],
		},
		siblings: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		SpecimenStatusChangedModal,
		SpecimenAge,
		Money,
		DeadChildsModal,
		SpecimenWeightTrackingModal,
		AddSpecimenWeight,
		AddSpecimenToSale,
		AddSpecimenToSale,
		SpecimenPlate,
		ChangeSpecimenToPublic,
		ChangeSpecimenToSale,
		CardLineBreeder,
		VueApexCharts,
		FertilityTracking,
		SidebarChicksGlobal,
		ChangeSpecimenEggEater,
		CorralTrackingModal,
		AddSpecimensNursery,
		NurseryDsTracking,
		LineBreederChart,
		ChildsEffectivenessChart,
		ChildsDeadChart,
		EffectivenessForSeason,
		RaceChart,
		ChildsFightsEffectivenessChart,
		LineLife,
		Measures,
		DoubleFlag,
	},
	data() {
		return {
			isLoading: false,
			specimenEdit: {
				alias: false,
				plate: false,
				status: false,
				additional_plate: false,
				origin: false,
				breeder: false,
				price: false,
				category: false,
				weight: false,
				color: false,
				observations: false,
				origin: false,
				dob: false,
			},
			specimenValue: {
				alias: null,
				plate: null,
				status: null,
				additional_plate: null,
				origin: null,
				breeder: null,
				price: null,
				category: null,
				weight: null,
				color: null,
				observations: null,
				definition: null,
				id: null,
				currency: null,
				evolution_order: null,
				weight_counter: null,
				origin: null,
				coyp_origin: null,
				dob: null,
			},
			modalViewDefinition: {
				show: false,
				title: "Definicion",
				data: null,
			},
			modalViewUpdateFields: {
				show: false,
				title: "Agregar campos",
			},
			modalViewUpdateStatus: {
				show: false,
				title: null,
				type: 1,
			},
			modalViewTracking: {
				show: false,
				title: null,
			},
			arrayCurrency: [
				{
					id: 1,
					name: "$",
				},
				{
					id: 2,
					name: "S/.",
				},
			],
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false,
			},
			dataTracking: [],
			keys_spanish: {
				alias: "alias",
				plate: "placa",
				status: "estado",
				additional_plate: "placa adicional",
				origin: "origen",
				breeder: "criador",
				price: "precio",
				category: "categoria",
				weight: "peso",
				color: "color",
				observations: "observaciones",
				definition: "definicion",
			},
			specimenWeightListController: false,
			addWeightSpecimen: false,
			specimenValueCopy: {},
			addCriadorModal: false,
			criadorObject: {
				name: null,
			},
			modalFertilityTracking: { show: false },
			chicksGlobalSidebar: { show: false },
			corralTracking: { show: false },
			nurseryTracking: { show: false, info: {} },
			addNursery: { show: false, info: {} },
			childDeads: { show: false, info: {}, id: null },
			effectivenessForSeason: {
				show: false,
				info: {},
				dataSeason: {},
			},
			currentCategory: null,
			positionLegend: null,
			donutData: [],
			donutOpts: {},
			tab: 0,
			showLineSpecimen: {
				show: false,
				specimen_id: null,
			},
		}
	},
	computed: {
		...mapState("createEditSpecimen", [
			"S_CATEGORIES_SPECIMENS",
			"S_COLORS_SPECIMENS",
			"S_ORIGINS_SPECIMENS",
			"S_BREEDERS",
		]),
		curYear() {
			if (this.seasons.length != 0) {
				return Number(this.seasons[0].season)
			}
			return new Date().getUTCFullYear()
		},
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	mounted() {
		// this.positionLegend = this.widthScreen < 1600 ? "bottom" : "right"
		this.positionLegend = this.$store.state.app.windowWidth > 990 ? "bottom" : "right"
		this.currentCategory = this.specimen.category
		this.initData()
		this.$root.$emit("bv::show::tooltip", "id-tree")
		this.loadBreederLines()
	},
	methods: {
		...mapActions("createEditSpecimen", ["A_GET_BREEDERS"]),

		goIdSpecimen(id) {
			const url = this.$router.resolve({
				name: "detalle-ejemplar",
				params: { specimen_id: id },
			}).href
			window.open(url, "_blank")
		},

		initData() {
			this.isPreloading()

			if (this.specimen.id) {
				this.specimenValue = {
					alias: this.specimen.alias ? this.specimen.alias.toUpperCase().trim() : this.specimen.alias,
					plate: this.specimen.plate ? this.specimen.plate.toUpperCase().trim() : this.specimen.plate,
					status: this.specimen.status,
					additional_plate: this.specimen.additional_plate
						? this.specimen.additional_plate.toUpperCase().trim()
						: this.specimen.additional_plate,
					origin: this.specimen.origin,
					breeder: this.specimen.breeder,
					price: this.specimen.price ? this.specimen.price : 0,
					category: this.specimen.category,
					weight: `${Number(this.specimen.kg_weight).toFixed(3)} (Kg) - ${Number(
						this.specimen.pounds_weight
					).toFixed(3)} (Lb)`,
					color: this.specimen.color,
					observations: this.specimen.observations
						? this.specimen.observations.trim()
						: this.specimen.observations,
					definition: this.specimen.definition,
					id: this.specimen.id,
					currency: this.specimen.currency,
					evolution_order: this.specimen.evolution_order,
					weight_counter: this.specimen.weight_counter,
					origin: this.specimen.origin,
					coyp_origin: this.specimen.origin,
				}
				this.specimenValueCopy = { ...this.specimenValue }
			}
		},
		handleActionDescription(e, infoSeason) {
			switch (e) {
				case "show-deads-modal":
					this.childDeads.show = !this.childDeads.show
					this.childDeads.info = this.specimen
					this.childDeads.id = this.specimen.id
					break
				case "show-efectiveness-for-season":
					this.effectivenessForSeason.show = !this.effectivenessForSeason.show
					this.effectivenessForSeason.info = this.specimenData
					this.effectivenessForSeason.seasonData = infoSeason
					break
			}
		},
		// openConfrontationsModal(){
		// 	this.$emit("update-status")
		// },
		async updateDefinitionSpecimen() {
			const { success, message } = await specimensService.updateDefinition({
				definition: this.specimenValue.definition,
				specimen_id: this.specimenValue.id,
			})
			this.showToast(
				success == true ? "success" : "warning",
				"top-right",
				"Dashboard del ejemplar",
				"AlertCircleIcon",
				message
			)
		},
		updateStatusSpecimen(type) {
			this.modalViewUpdateStatus.show = true
			this.modalViewUpdateStatus.title = "Actualizar estado del ejemplar"
			this.modalViewUpdateStatus.type = type
		},
		async viewTrackingDashboard(type) {
			this.isPreloading()
			const { message, data, status } = await specimensService.getTrackingDashboard({
				key: type,
				specimen_id: this.specimen.id,
			})
			this.isPreloading(false)
			if (data.length > 0) {
				this.modalViewTracking.show = true
				this.modalViewTracking.title = `Tracking de ${this.keys_spanish[type]} del ejemplar`
				this.dataTracking = data
			} else {
				this.showToast("info", "top-right", "Dashboard", "SuccessIcon", "No hay cambios.")
			}
		},

		async isAvailableForDecrest() {
			const { data: isAvailable } = await decrestService.isAvailableForDecrest(this.specimen.id)
			if (!isAvailable) {
				return "unqualified"
			} else {
				this.isPreloading(false)
				await this.$swal({
					icon: "info",
					title: "Se descrestará al ejemplar",
					text: "Se encontró al ejemplar como disponible a descrestar.",
					customClass: {
						confirmButton: "btn btn-primary",
						confirmButtonText: "Save",
					},
					buttonsStyling: false,
				})
				this.isPreloading()
				const data = { specimens: [this.specimen.id] }
				await decrestService.setDecrested(data)
				this.$store.dispatch("commons/A_GET_DECREST_COUNTER")
				return "decrested"
			}
		},

		async updateFieldDashboard(field, type, type_value) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) {
				this.specimenValue.category = this.currentCategory
				this.specimenEdit.category = false
				return
			}

			this.isPreloading()
			let body = {}
			if (type_value == "value_id") {
				let df = null
				if (type == "category") {
					df = this.S_CATEGORIES_SPECIMENS.find((it) => it.id == field).description
					if (field == 3) {
						await this.isAvailableForDecrest()
					}
				} else if (type == "breeder") {
					df = this.S_BREEDERS.find((it) => it.id == field).name
				} else if (type == "color") {
					df = this.S_COLORS_SPECIMENS.find((it) => it.id == field).color
				} else if (type == "origin") {
					df = this.S_ORIGINS_SPECIMENS.find((it) => it.id == field).name
				}

				body = {
					specimen_id: this.specimen.id,
					key: type,
					value_id: field,
					value_text: type == "breeder" ? df.trim() : df.trim(),
				}
			} else {
				body = {
					specimen_id: this.specimen.id,
					key: type,
					value_text: type == "price" ? parseFloat(field) : field.trim(),
					currency: this.specimenValue.currency,
				}
			}

			if (type == "price" && !this.specimenValue.currency) {
				this.isPreloading(false)
				return this.showToast(
					"warning",
					"top-right",
					"Dashboard",
					"SuccessIcon",
					"El tipo de moneda es obligatoria."
				)
			}
			if (body.value_text != this.specimenValueCopy[type]) {
				const { message, status } = await specimensService.updateFieldSpecimen(body)

				this.isPreloading(false)

				if (status) {
					if (type == "category") {
						this.specimenValue.evolution_order = this.S_CATEGORIES_SPECIMENS.find(
							(it) => it.id == field
						).evolution_order
					}
					this.showToast("success", "top-right", "Dashboard", "SuccessIcon", message)
					if (type_value == "value_id") {
						this.specimenValue[type] = field
					} else {
						this.specimenValue[type] = field
					}
				} else {
					this.specimenValue[type] = this.specimenValueCopy[type]
				}
			} else {
				this.isPreloading(false)
				this.showToast("success", "top-right", "Dashboard", "SuccessIcon", "Se guardo Correctamente")
			}
			this.specimenEdit[type] = false
			this.specimenValueCopy = { ...this.specimenValue }
			this.$emit("update-status")
		},

		updateSpecimenWeight(specimen, weight) {
			const weight_kg = Number(weight.kg_weight).toFixed(3)
			const weight_po = Number(weight.pounds_weight).toFixed(3)
			this.specimenValue.weight = `${weight_kg} (Kg) - ${weight_po} (Lb)`
			this.specimenValue.weight_counter++
			this.$emit("update-status")
		},
		openModalWeightTracking() {
			// En caso se tenga al menos un tracking mostrar el modal
			if (this.specimenValue.weight_counter > 0) {
				this.specimenWeightListController = true
				return
			}
			this.showToast("info", "top-right", "Dashboard", "InfoIcon", "No existe historial de pesos.")
		},

		async saveRival() {
			try {
				await axios.post("/api/save-rival", {
					namerival: this.criadorObject.name,
				})
				this.addCriadorModal = false
				this.A_GET_BREEDERS()
				this.$emit("update-status")
				this.criadorObject.name = null
			} catch (error) {
				this.showErrorSwal(error)
			}
		},
		hasTracking(label) {
			//
			let labelMatch = this.specimen.labels.find((l) => l.key == label)
			if (!labelMatch) return false
			return labelMatch.counter !== null && labelMatch.counter !== 0
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		// Cards with Donut Data charts

		loadBreederLines() {
			const data = []
			const labels = []
			let unknowLine = -1
			this.specimen.breeder_line.forEach((line) => {
				if (line.breeder_name == "NO HAY LÍNEA") {
					return
				}
				data.push(line.percentage)
				labels.push(`${line.breeder_name} (${line.percentage}%)`)
				if (line.breeder_id == null) unknowLine = line.percentage
			})
			if (!data.length && !labels.length) {
				this.donutData = [1]
				this.donutOpts = {
					labels: ["Sin resultados"],
					colors: ["rgb(153, 153, 153)"],
					legend: {
						position: this.positionLegend,
						fontWeight: 600,
						labels: { colors: "rgb(153, 153, 153)" },
					},
					tooltip: {
						enabled: false,
					},
					dataLabels: {
						// valida si hay linea desconocida en el primer index del array de lineas para saber si mostrar etiquetas
						formatter: (val, opts) => {
							return ""
						},
					},
				}
				return
			}
			this.donutData = data
			this.donutOpts = {
				labels: labels,
				colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: {
					position: this.positionLegend,
				},
				dataLabels: {
					// valida si hay linea desconocida en el primer index del array de lineas para saber si mostrar etiquetas
					formatter: (val, opts) => {
						if (unknowLine != -1 && unknowLine == val && opts.seriesIndex == 0) return ""
						return `${val}%`
					},
				},
				tooltip: {
					enabled: this.specimen.breeder_line.filter((it) => it.breeder_id).length == 0 ? false : true,
					y: {
						formatter: (val, opts) => {
							if (unknowLine != -1 && unknowLine == val && opts.seriesIndex == 0) return ""
							return `${val}%`
						},
						title: {
							formatter: (val, opts) => {
								if (val == "NO HAY LÍNEA" && opts.seriesIndex == 0) return val
								return `${val}:`
							},
						},
					},
				},
			}
		},
	},
	watch: {
		loadingData() {
			this.initData()
		},
		"specimen.id"() {
			this.initData()
		},
		"specimen.breeder_line"() {
			this.loadBreederLines()
		},
	},
}
</script>

<style lang="scss">
.dseCard__head {
	padding: 0.5rem 1rem;
	background: var(--primary);
	color: var(--white);
	border-radius: 0.5rem 0.5rem 0 0;
	font-size: 1.2rem;

	&--wfull {
		flex: 1;
		text-align: center;
	}
}

.img-paw {
	width: 35px;
	height: 35px;
}

.dseCard__body {
	padding: 1rem;
	background: #edecf7;
	border-radius: 0 0.5rem 0.5rem 0.5rem;

	&--wfull {
		border-radius: 0 0 0.5rem 0.5rem;
	}
}
.row-header {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.apexcharts-legend {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	@media screen and (min-width: 1600px) {
		max-width: 280px;
	}
}

.btnRadio {
	display: flex;
	border: 1px solid var(--primary);
	border-radius: 5rem;
	overflow: hidden;
}

.btnRadio__btn {
	padding: 0.5rem 1.5rem;
	outline: none;
	border: none;
	background: none;
	color: var(--primary);
	font-size: 1.2rem;
	font-weight: 500;
	transition: all 0.5s ease;
}

.btnRadio__btn:nth-last-child(1) {
	border-left: 1px solid var(--primary);
}
.btnRadio__btn--selected {
	background: var(--primary);
	color: var(--white);
}
.heightControl {
	height: 30em;
	overflow: auto;
}
.cdw {
	.apexcharts-legend {
		min-width: 100% !important;
	}
}
</style>
