<template>
	<div class="mb-4 pb-4">
		<b-row class="sp-specimen-card">
			<b-col cols="12">
				<GeneralInfo
					:specimenData="specimen"
					@update-status="fetchAndFillSpecimenById()"
					@changeTabToResponsable="tab = 4"
					@open-pedigree="openSpecimentTreeFamily"
					:loadingData="loadingData"
					v-if="specimen.id"
					:loadingLine="loadingLine"
					@toggleLine="toggleLine"
				/>
			</b-col>
		</b-row>
		<div v-if="specimen.category" class="card align-item-center p-2 flex-lg-row justify-content-md-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0" role="tabslits">
				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:active="tabIndex == 0"
					@click="tabIndex = 0"
					:class="isParent"
				>
					<span>POSTURAS</span>
				</b-nav-item>
				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:active="tabIndex == 1"
					@click="tabIndex = 1"
					:class="isMale"
					v-if="specimen.count_video > 0"
				>
					<span>VIDEOS</span>
					<b-badge pill variant="danger" class="ml-1">
						{{ specimen.count_video }}
					</b-badge>
				</b-nav-item>
				<b-nav-item v-if="tabIndex == 0 && specimen.category == 3">
					<b-button class="mb-0" variant="primary" @click="controllerAddPostura = true" block>
						<span class="text-nowrap">Agregar Postura</span>
					</b-button>
				</b-nav-item>
				<!--  -->
				<b-nav-item
					link-classes="px-1 justify-content-between"
					href="#"
					class="x-tab-width"
					:class="isMale"
					:active="tabIndex == 2"
					@click="tabIndex = 2"
				>
					<span>PREPARACION</span>
					<b-badge pill variant="danger" class="ml-1" v-if="specimen.count_training > 0">
						{{ specimen.count_training }}
					</b-badge>
				</b-nav-item>
			</ul>
			<b-form-select v-if="tabIndex == 2" :options="seasonOpts" v-model="season" style="max-width: 10rem" />
		</div>
		<b-tabs
			v-if="specimen.category"
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center flex-grow-1"
			v-model="tabIndex"
			lazy
		>
			<b-tab :class="isParent">
				<ViewPostureStatusBySpecimenId
					v-if="$store.state.utilities.seasons.length > 0"
					isOnlySpecimen
					:specimenId="specimen.id"
					:categoryId="specimen.category"
				/>
			</b-tab>
			<b-tab :class="isMale">
				<!--  -->
				<ContainerSpecimenListVideos :id_specimen="specimen.id" @refresh="fetchAndFillSpecimenById" />
			</b-tab>
			<b-tab :class="isMale" lazy>
				<b-card class="p-2" no-body>
					<PreparacionGrid
						dashboard
						:status="1"
						:trigger="gridTrigger"
						:season="preparacionSeason"
						:filters="preparationFilters"
					/>
				</b-card>
			</b-tab>
		</b-tabs>
		<AddPostura
			@reloadPostura="$refs.posturas.$refs.pedingList.onChangeFilter()"
			@hidden="closeAddPostura"
			v-if="controllerAddPostura"
			:placa="specimen.plate"
			:madrillaController="specimen.category == 1 ? false : true"
		/>
		<b-modal
			@hidden="hideModalPedigree"
			modal-class="test-modal"
			id="tree-modal"
			hide-footer
			v-model="treeModalController"
			hide-header
		>
			<SpecimenFamilyTree
				@close="closeSpecimentTreeFamily"
				:specimen-id="specimen.id"
				@navigate="closeSpecimentTreeFamily"
				:loadingLine="loadingLine"
				@toggleLine="toggleLine(), fetchAndFillSpecimenById()"
			/>
		</b-modal>
		<div class="loading-line-breeder" v-show="loadingLine">
			<p class="mb-0 mr-1">Los cambios estarán disponibles en unos momentos.</p>
			<FeatherIcon icon="RefreshCcwIcon" size="18" class="mr-50 text-primary icon" />
		</div>
	</div>
</template>

<script>
import moment from "moment"
import { mapState, mapMutations, mapActions } from "vuex"
import GeneralInfo from "./GeneralInfo.vue"
import SpecimenFamilyTree from "@/views/amg/specimens/specimen-tree/SpecimenFamilyTree.vue"
import SpecimensReportsSonsVue from "./SpecimensReportsSons.vue"
import AddPostura from "../../encaste/posturas/modals/AddPostura.vue"
import VideoSpecimenList from "../../videolibrary/VideoSpecimenList.vue"
import ReportTournamentsComponent from "../../reports/results/tournaments/ReportTournamentsComponent.vue"
import ContainerSpecimenListVideos from "./ContainerSpecimenListVideos.vue"
import ViewPostureStatusBySpecimenId from "@/views/amg/encaste/posturas/ViewPostureStatusBySpecimenId.vue"
import PreparacionGrid from "@/views/amg/preparacion/components/GridDs.vue"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

export default {
	name: "DashboardMain",
	components: {
		GeneralInfo,
		AddPostura,
		SpecimensReportsSonsVue,
		VideoSpecimenList,
		ReportTournamentsComponent,
		SpecimenFamilyTree,
		ContainerSpecimenListVideos,
		ViewPostureStatusBySpecimenId,
		PreparacionGrid,
	},
	async created() {
		this.loadingData = true
		await this.getSeasons()
		await this.fetchAndFillSpecimenById()
		this.preparationFilters.specimen_id = this.$route.params.specimen_id
	},
	computed: {
		...mapState("header_book", ["isUpdatingFeature"]),
		...mapState("specimens", {
			specimen: (state) => state.specimen,
		}),
		isParent() {
			return [1, 2].includes(this.specimen.category) ? "" : "d-none"
		},
		isMale() {
			return [1, 3, 5].includes(this.specimen.category) ? "" : "d-none"
		},
		preparacionSeason() {
			if (!this.season) {
				let tomorrow = new Date()
				tomorrow.setDate(tomorrow.getDate() + 1)
				tomorrow = moment(tomorrow).format("YYYY-MM-DD")
				let lastDayOfYear = moment(tomorrow).endOf("year").format("YYYY-MM-DD")
				return { value: null, text: null, start: tomorrow, end: lastDayOfYear }
			}
			return this.seasonOpts.find((season) => season.value == this.season)
		},
	},
	watch: {
		"$route.params.specimen_id": {
			async handler(newValue, oldValue) {
				if (this.$route.params.specimen_id != undefined) {
					this.loadingData = true
					if (newValue != oldValue) {
						await this.fetchAndFillSpecimenById()
					}
					this.makeConcurrencyRequestContent()

					setTimeout(() => {
						this.loadingData = false
					}, 1000)
				} else {
					this.CLEAR_SPECIMEN()
				}
			},
		},
		isUpdatingFeature() {
			this.fetchAndFillSpecimenById()
			this.$store.commit("header_book/TOGGLE_IS_UPDATING_FEATURE", false)
		},
		"specimen.category"() {
			if (this.specimen.category == 3) {
				this.tabIndex = 2
			}
		},
		preparacionSeason(val) {
			this.gridTrigger++
		},
	},
	data() {
		return {
			specimenData: {
				id: null,
				alias: null,
				plate: null,
				dob: null,
				category: null,
			},
			registerParentsModalController: false,
			modalAddPostura: false,
			treeModalController: false,
			arrayMadrillas: [],
			controllerAddPostura: false,
			loading: true,
			tab: 0,
			tabIndex: 0,
			loadingData: false,
			gridTrigger: 0,
			seasonOpts: [],
			season: null,
			preparationFilters: {
				qualities: null,
				defects: null,
				averages: [0, 5],
				activeAverages: false,
				trainings: [0, 5],
				activeTrainings: false,
				specimen_id: null,
				dateType: null,
				dateStart: null,
				dateEnd: null,
			},
			loadingLine: false,
		}
	},
	destroyed() {
		this.CLEAR_SPECIMEN()
	},
	methods: {
		...mapMutations("createEditSpecimen", [
			"M_SET_EDIT_SPECIMEN",
			"M_SET_PHOTOS",
			"M_SET_PADRILLOS",
			"M_SET_MADRILLAS",
		]),
		...mapActions("createEditSpecimen", ["A_GET_CATEGORIES_SPECIMENS"]),

		...mapMutations("specimens", ["CLEAR_SPECIMEN"]),
		async getSeasons() {
			const { data } = await preparacionSettingService.getSeasons()
			this.seasonOpts = data.map((season) => ({
				id: season.id,
				value: season.season,
				text: season.season,
				start: season.start,
				end: season.end,
				active: season.active_season,
			}))
			const noActiveSeason = !data.some((season) => season.active_season != 0)
			if (noActiveSeason) {
				if (data[0]) {
					this.season = data[0].season
				} else {
					this.season = null
				}
				return
			}
			this.season = data.find((season) => season.active_season == 1).season
			// this.getCounters()
		},
		async fetchAndFillSpecimenById() {
			this.isPreloading()
			this.loading = true
			await this.$store.dispatch({
				type: "specimens/fetchSpecimenById",
				id: this.$route.params.specimen_id,
			})
			this.loading = false
			this.loadingData = false
			this.isPreloading(false)
		},
		async getSpecimenById() {
			try {
				const specimenId = this.$route.params.specimen_id
				const { data: response } = await axios.get(`/api/specimens/${specimenId}`)
				this.specimenData = {
					...response,
					secondary_padrillo_id: null,
					secondary_madrilla_id: null,
				}
				this.M_SET_PHOTOS(this.specimenData.photos)
			} catch (error) {
				// this.$router.push({ name: 'error-404' })
			}
		},
		async makeConcurrencyRequestContent() {
			this.isPreloading()
			this.loading = true
			await this.getSpecimenById()
			this.loading = false
			this.isPreloading(false)
			// this.getActiveMadrillasByActivePosturas();
		},
		getActiveMadrillasByActivePosturas() {
			axios.get("/api/postura/get-madrillas-being-used").then((response) => {
				this.arrayMadrillas = response.data
			})
		},
		closeModalRegisterSpecimenParents() {
			this.registerParentsModalController = false
		},
		closeAddPostura() {
			this.controllerAddPostura = false
		},
		closeSpecimentTreeFamily() {
			document.querySelector("html").removeAttribute("data-tree-open")
			this.treeModalController = false
		},
		openSpecimentTreeFamily() {
			document.querySelector("html").setAttribute("data-tree-open", "")
			this.treeModalController = true
		},
		async hideModalPedigree() {
			this.isPreloading()
			await this.A_GET_CATEGORIES_SPECIMENS({
				specimen_id: this.$route.params.specimen_id,
			})
			this.isPreloading(false)
		},
		toggleLine() {
			this.loadingLine = !this.loadingLine
		},
	},
}
</script>

<style lang="scss">
.test-modal .modal-dialog {
	max-width: 100%;
	margin: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100vh;
	display: flex;
	position: fixed;
	z-index: 100000;
}
#tree-modal {
	padding: 0;
	margin: 0;
	.modal-body {
		padding: 0;
		margin: 0;
	}
}
.x-tab-width {
	width: 100%;
	@media screen and (min-width: 600px) {
		width: auto;
	}
}
</style>
