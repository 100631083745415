<template>
	<b-tabs v-model="curBlock">
		<b-tab :disabled="false" v-for="(block, idx) in blocks" :key="block.id" @click="customHandle(idx)">
			<template #title
				>{{ block.name }}
				<b-badge class="ml-1" variant="danger" v-if="true">{{ block.counter }}</b-badge></template
			>
		</b-tab>
	</b-tabs>
</template>

<script>
import moment from "moment";

import settingsService from "@/services/preparacion/settings.service";

export default {
	props: {
		season: Object,
		trigger: Number,
	},
	data: () => ({
		curBlock: null,
		firstLoad: true,
		blocks: [],
	}),
	methods: {
		async getBlocks() {
			this.isLoading = true;
			let body = { season_id: this.season.id };
			if (this.$route.name == "detalle-ejemplar" && this.$route.params.specimen_id) {
				body.specimen_id = this.$route.params.specimen_id;
			}
			// const { data } = await settingsService.getBlocks(body);
			// this.blocks = data.map((block) => ({
			// 	name: block.name,
			// 	days: block.days_to_ids,
			// 	id: block.training_block_id,
			// 	counter: block.counter,
			// }));
			this.isLoading = true;
			// this.setFocus();
		},
		setFocus() {
			if (!this.firstLoad) {
				// only updates tab index once
				return;
			}
			let today = moment().day();
			// let today = 6;
			let match = this.blocks.findIndex((block) => {
				let days = block.days.map((day) => day.id);
				if (days.includes(today)) return true;
				return false;
			});
			if (match == -1) {
				console.log("block: ", this.blocks)
				let distances = this.blocks.map((block) => {
					let diffs = block.days.map((day) => Math.abs(day.id - today));
					return Math.min(...diffs);
				});
				let idx = distances.indexOf(Math.min(...distances));
				let closer = this.blocks[idx];
				this.$nextTick(() => {
					this.curBlock = idx;
				});
				this.$emit("loaded", closer.id);
				this.firstLoad = false;
				return;
			}
			this.$nextTick(() => {
				this.curBlock = match;
			});
			this.$emit("loaded", this.blocks[match].id);
			this.firstLoad = false;
			return;
		},
		customHandle(idx) {
			if (this.curBlock == idx) return;
			this.$emit("loaded", this.blocks[idx].id);
		},
	},
	created() {
		this.getBlocks();
	},
	watch: {
		trigger(val) {
			this.getBlocks();
		},
	},
};
</script>
