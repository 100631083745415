<template>
	<div>
		<div class="d-flex gap-x-2">
			<div class="MeasuresCard__head">Medidas</div>
			<b-button
				class="btn-icon"
				variant="flat-success"
				:disabled="isLoading"
				@click=";(addMeasure.info = addMeasureInfo), (addMeasure.show = !addMeasure.show)"
			>
				<feather-icon icon="Edit3Icon" />
			</b-button>
		</div>
		<div class="MeasuresCard__body">
			<b-overlay :show="isLoading">
				<b-row class="mb-1" v-for="m in measures" :key="m.name">
					<b-col cols="4">
						<b class="text-primary">{{ m.name }}</b>
					</b-col>
					<b-col class="d-flex align-items-center" cols="8">
						<span>{{ !!m.value ? m.value.toFixed(2) + " cm" : "---" }}</span>
						<b-dropdown variant="link" size="sm" toggle-class="p-0 ml-1" no-caret v-if="true">
							<template #button-content>
								<feather-icon icon="MoreVerticalIcon" />
							</template>
							<b-dropdown-item @click="editMeasure(m)" v-if="!isInvitado">Editar</b-dropdown-item>
							<b-dropdown-item :disabled="!m.value" @click="viewTracking(m)">Tracking</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>
				<h4 class="text-center" style="font-size: 1.1rem" v-if="measures.length == 0 && !isLoading">
					Aún no hay medidas registradas
				</h4>
			</b-overlay>
		</div>
		<AddMeasures
			:show="addMeasure.show"
			:info="addMeasure.info"
			@closing="addMeasure.show = false"
			@refresh="getMeasurements"
		/>
		<TrackingMeasure
			:show="trackingMeasure.show"
			:info="trackingMeasure.info"
			@closing="trackingMeasure.show = false"
		/>
	</div>
</template>

<script>
import specimensService from "@/services/specimens.service"

import AddMeasures from "./modals/AddMeasures.vue"
import TrackingMeasure from "./modals/TrackingMeasure.vue"

export default {
	components: { AddMeasures, TrackingMeasure },
	props: { specimen: Object },
	data: () => ({
		isLoading: false,
		measures: [],
		addMeasure: { show: false, info: {} },
		trackingMeasure: { show: false, info: {} },
	}),
	computed: {
		addMeasureInfo() {
			return { measures: this.measures, specimen: this.specimen }
		},
	},
	methods: {
		async getMeasurements() {
			this.isLoading = true
			this.measures = []
			const { data } = await specimensService.getMeasurements({ id: this.specimen.id })
			data.forEach((m) => {
				const capitalized = m.name.replace(/^./, m.name[0].toUpperCase())
				this.measures.push({
					name: capitalized,
					value: Number(m.value),
					id: m.id,
					trackingId: m.specimen_measure_id,
				})
			})
			this.isLoading = false
		},
		editMeasure(target) {
			this.addMeasure.info = { specimen: this.specimen, target, _a: "edit" }
			this.addMeasure.show = !this.addMeasure.show
		},
		viewTracking(target) {
			console.log(target, "/./.")
			this.trackingMeasure.info = { target }
			this.trackingMeasure.show = !this.trackingMeasure.show
		},
	},
	created() {
		this.getMeasurements()
	},
}
</script>

<style>
.MeasuresCard__head {
	padding: 0.5rem 1rem;
	background: var(--primary);
	color: var(--white);
	border-radius: 0.5rem 0.5rem 0 0;
	font-size: 1.2rem;
}

.MeasuresCard__body {
	padding: 1rem;
	background: #edecf7;
	border-radius: 0 0.5rem 0.5rem 0.5rem;
}
</style>
