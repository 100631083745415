import { render, staticRenderFns } from "./SpecimenChickenCintilloPata.vue?vue&type=template&id=91738118&scoped=true"
import script from "./SpecimenChickenCintilloPata.vue?vue&type=script&lang=js"
export * from "./SpecimenChickenCintilloPata.vue?vue&type=script&lang=js"
import style0 from "./SpecimenChickenCintilloPata.vue?vue&type=style&index=0&id=91738118&prod&lang=scss&scoped=true"
import style1 from "./SpecimenChickenCintilloPata.vue?vue&type=style&index=1&id=91738118&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91738118",
  null
  
)

export default component.exports