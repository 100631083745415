<template>
	<b-modal centered hide-footer :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center text-nowrap">
					<b-tr class="text-uppercase">
						<b-th>Medida</b-th>
						<b-th>Registrado por</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							{{ Number(row.value).toFixed(2) }}
						</b-td>
						<b-td>
							<span>{{ row.created.date | myGlobalDayShort }}</span>
							<br />
							<small>{{ row.created.user }}</small>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="2">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import specimensService from "@/services/specimens.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await specimensService.getMeasureTracking({ id: this.info.target.trackingId })
			this.rows = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Tracking de medida / ${this.info.target.name}`
				this.getRows()
			}
		},
	},
}
</script>
