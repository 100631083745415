<template>
	<div class="p-2">
		<div class="table-responsive mb-0">
			<b-table-simple
				sticky-header
				class="table mb-0"
				:style="{ minHeight: data.length < 3 ? 'auto' : data.length < 5 ? '50vh' : '70vh' }"
			>
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-1" v-if="item.visible" v-for="(item, key) in headerTable" :key="item.key">
							{{ item.name }}
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in data" :key="index">
						<b-td class="text-center">
							{{ index + 1 }}
						</b-td>

						<b-td class="text-center">
							{{ item.date | myGlobalDayShort }}
						</b-td>

						<b-td class="text-center">
							<SpecimenPlate
								:specimen="{
									id: item.specimen.id,
									plate: item.specimen.plate,
									alias: item.specimen.alias,
									thumb: item.specimen.thumb,
									image: item.specimen.image,
								}"
							/>
						</b-td>

						<b-td class="text-center">
							<div class="d-flex align-items-center justify-content-center body-cell p-1">
								<div
									class="result"
									:class="[
										item.result == 'TRIUNFO' ? 'result-1' : '',
										item.result == 'DERROTA' ? 'result-2' : '',
										item.result == 'EMPATE' ? 'result-3' : '',
										item.result == 'SIN JUGAR' ? 'result-4' : '',
									]"
								>
									<p class="mb-0">
										{{
											item.result == "TRIUNFO"
												? "V"
												: item.result == "EMPATE"
												? "E"
												: item.result == "DERROTA"
												? "D"
												: "S"
										}}
									</p>
								</div>
								<feather-icon
									@click="selectBattle(item)"
									class="cursor-pointer icon-eye"
									icon="EyeIcon"
									color="#28A745"
									:size="width < 600 ? '16' : '20'"
								/>
								<div
									v-b-tooltip.hover
									title="Gallo corrido."
									class="container-ran"
									v-if="item.corrido == 1"
								>
									<img
										src="https://res.cloudinary.com/mikunaalli/image/upload/v1654279737/amg/gallo_r2jro3.png"
										alt=""
									/>
								</div>
							</div>
						</b-td>

						<b-td class="text-center">
							<p class=" mb-0">
								{{
									`${
										item.puntaje == 0
											? item.result == "SIN JUGAR"
												? "SIN JUGAR"
												: item.puntaje
											: `+${item.puntaje}`
									}`
								}}
							</p>
						</b-td>

						<b-td class="text-center">
							<p  class="mb-0">
								{{ item.status }}
							</p>
						</b-td>

						<b-td class="text-center">
							<div
								v-b-tooltip.hover
								:title="item.tournament_name"
								class="d-flex align-items-center justify-content-center"
							>
								<feather-icon
									@click="linkTournament(item.tournament_id)"
									icon="ExternalLinkIcon"
									size="20"
									class="cursor-pointer text-primary icon-left"
								/>
							</div>
							<div class="d-flex align-items-center justify-content-center link-report-tournament">
								<p @click="linkReportsByTournament(item.tournament_id)" class="number-report mb-0">
									Ver reporte
								</p>
								<feather-icon
									@click="linkTournament(item.tournament_id)"
									icon="TrendingUpIcon"
									size="20"
									class="cursor-pointer text-success icon-left"
								/>
							</div>
						</b-td>

						<b-td class="text-center">
							<p class="mb-0">
								{{ item.coliseum_name }}
							</p>
						</b-td>

						<b-td class="text-center">
							<p class="mb-0">
								{{ item.city }}
							</p>
							<p class="mb-0">
								{{ item.tournament_stage }}
							</p>
						</b-td>

					</b-tr>
					<b-tr v-if="data.length == 0">
						<b-td :colspan=" category_id ? 10 : 11" class="text-center">
							<p class="mb-0 font-weight-bold">Sin cintillos encontrados</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>

		<b-modal
			v-if="itemBattle"
			body-class="p-0 m-0"
			hide-footer
			v-model="openModalDetailsBattle"
			:title="`Detalle de la fecha: ${itemBattle.date}`"
			centered
		>
			<ViewDetailsBattle :item="itemBattle" />
		</b-modal>
	</div>
</template>

<script>
import ViewDetailsBattle from "./ViewDetailsBattle.vue";
import { getDetailsTournamentsBySpecimen } from "@/services/reports.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	name: "ViewDetailsReportPostura",
	props: ["item", "idModalSelect", "posturaTable", "category_id"],
	components: {
		ViewDetailsBattle,
		SpecimenPlate,
	},
	data() {
		return {
			width: this.$store.state["app"].windowWidth,
			postura: null,
			sumaScoreTotal: 0,
			openModalDetailsBattle: false,
			itemBattle: null,
			dataSelect: [],
			dataSelectVideo: {
				url: null,
				type: null,
				text: null,
				text_url: null,
				open: false,
			},
			data: [],
			headerTable: [
				{
					key: "#",
					name: "#",
					visible: true,
				},
				{
					key: "fecha",
					name: "fecha",
					visible: true,
				},
				{
					key: "hijos",
					name: "hijos",
					visible: true,
				},
				{
					key: "acciones",
					name: "acciones",
					visible: true,
				},
				{
					key: "score",
					name: "score",
					visible: true,
				},
				{
					key: "estado",
					name: "estado",
					visible: true,
				},
				{
					key: "torneo",
					name: "torneo",
					visible: true,
				},
				{
					key: "coliseo",
					name: "coliseo",
					visible: true,
				},
				{
					key: "detalles",
					name: "detalles",
					visible: true,
				},
			],
		};
	},
	async mounted() {
		await this.init();
	},
	methods: {
		selectBattle(item) {
			this.openModalDetailsBattle = true;
			this.itemBattle = item;
			//   console.log("ASD:", this.itemBattle);
		},
		linkTournament(id) {
			window.open(`/torneos/detalle/${id}`, "_blank");
		},
		linkReportsByTournament(id) {
			window.open(`/reportes/torneos?torneo=${id}`, "_blank");
		},
		async init() {
			this.isPreloading();
			const { data, message, status } = await getDetailsTournamentsBySpecimen({
				madrilla_id: this.item.madrilla ? this.item.madrilla.id : null,
				padrillo_id: this.item.padrillo ? this.item.padrillo.id : null,
				result: this.idModalSelect,
			});
			this.data = data;
			this.isPreloading(false);
		},
	},
};
</script>

<style lang="scss" scoped>
.container-header {
	background: #fff;
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: 0.1s all ease-in-out;
	@media screen and (min-width: 700px) {
		padding: 1rem;
	}
	.page-btn {
		height: 32px;
		width: 32px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;
		border-radius: 32px;
		cursor: pointer;
		background: #f3f2f7;
	}
	.container-btns {
		margin: 0 0.4rem;
		width: auto;
		background: #f3f2f7;
		height: 32px;
		border-radius: 16px;
		display: flex;
		align-items: center;
		transition: 0.1s all ease-in-out;
		.active {
			background: #7367f0;
			color: #fff;
		}
	}
	.disabled {
		color: #d5d5d5;
	}
}
.dark-layout {
	.container-header {
		background: #283046;
		.page-btn {
			background: #242b3d;
		}
		.container-btns {
			background: #242b3d;
			.active {
				background: #7367f0;
				color: #fff;
			}
		}
	}
}
.content-table {
	min-width: 100%;
	background: #fff;

	border-radius: 10px;
	overflow-x: scroll;
	overflow-y: visible;
	@media screen and (min-width: 1000px) {
		overflow-x: visible;
		overflow-y: visible;
	}
	.container-table-reports {
		// min-width: 800px;
		// padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		text-align: center;
		@media screen and (min-width: 600px) {
			// padding: 1rem 2rem;
		}

		p {
			margin: 0;
			font-size: 12px;
			transition: 0.1s all ease-in-out;
			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}
		.icon {
			//color: red;
			margin: 0 0.2rem;
			cursor: pointer;
		}
	}

	.header-table {
		transition: 0.1s all ease-in-out;
		background: #f3f2f7;
		text-align: center;
		p {
			font-weight: 600;
		}
	}
	.body-table {
		border-bottom: 0.5px solid #e6e6e6;
		transition: 0.1s all ease-in-out;
		&:last-child {
			border-bottom: none;
		}
		.body-index {
			//   min-width: 30.88px;
		}
		.body-cell {
			//   min-width: 123.52px;
			p {
				word-wrap: break-word;
			}
			.badge {
				width: 80%;
				@media screen and (min-width: 600px) {
					width: 50% !important;
				}
			}
		}
	}
}
.dark-layout {
	.content-table {
		background: #283046;
		.header-table {
			background: #343d55;
		}
		.body-table {
			border-bottom-color: #3b4253;
		}
	}
}
.btn-agregar-organizer {
	width: 180px;
}
.circle {
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 4px;
}
.container-body-header {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
		justify-content: space-between;
	}
}
.font-other-name {
	font-size: 12px;
	font-weight: bold;
}
.header-table-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
	}
	img {
		height: 24px;
		width: 24px;
		object-fit: cover;
	}
}
.btn-buscar-placa {
	width: 220px !important;
	margin-left: 1rem;
}
.container-padrillo {
	display: flex;
	align-items: center;
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
		.alias {
		}
		.plate {
			background: #fff;
			border-radius: 8px;
			display: flex;
			align-items: center;
			transition: 0.2s all ease-in-out;
			padding: 0.05rem 0.2rem !important;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			.icon {
				color: #9c97da;
				font-weight: bold;
			}
			.text-plate {
				color: #9c97da;
				font-weight: bold;
			}
			&:hover {
				transform: scale(1.05);
			}
		}
	}
}
.number-report {
	font-weight: bold;
	transition: 0.2s all ease-in-out;
	cursor: pointer;
	&:hover {
		color: #9c97da;
	}
}
.icon-eye {
	margin-right: 0.5rem;
}
.table-1 {
	background: #ddfcdd;
}
.table-2 {
	background: #bbf4fe;
}
.table-3 {
	background: #fecccb;
}
.posturas-details-table {
	grid-template-columns: 1fr 2fr 4fr 2fr 2fr 3fr 2fr 3fr 4fr 4fr 4fr !important;
	width: 100% !important;
	min-width: 100% !important;
}
.result {
	height: 30px;
	width: 30px;
	background: #a5aab0;
	border-radius: 50%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
	transition: 0.2s all ease-in-out;
	p {
		transition: 0.2s all ease-in-out;
		font-size: 16px !important;
		font-weight: bold;
	}
}
.result-1 {
	background: #51b131;
}
.result-2 {
	background: #e74c3c;
}
.result-3 {
	background: #a5aab0;
}
.result-4 {
	background: #fff;
	p {
		color: #343d55;
	}
}
.table-postura-details {
	// margin: 2rem;
	padding: 1rem;
	width: 100% !important;
}
.none-events {
	pointer-events: none;
}
.container-ran {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	img {
		width: 40px;
		height: 40px;
		object-fit: cover;
	}
}
.table-response {
	grid-template-columns: 1fr 4fr 2fr 4fr !important;
}
.table-response2 {
	grid-template-columns: 1fr 2fr 2fr !important;
}
.table-response3 {
	grid-template-columns: 1fr 2fr 3fr 2fr 2fr 2fr 3fr 2fr 2fr !important;
}
.icon-left {
	margin-left: 0.5rem;
}
.link-report-tournament {
	margin-top: 0.5rem;
}
</style>
